import React, { useState } from 'react';
import { Switch, message } from 'antd';
import './OpportunityEmbed.less';

interface OpportunityEmbedProps {
  businessId: string;
}

const OpportunityEmbed: React.FC<OpportunityEmbedProps> = ({ businessId }) => {
  const [includeWhyJoin, setIncludeWhyJoin] = useState(false);
  
  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname.includes('staging')) return 'https://staging.beautista.com';
    if (hostname.includes('localhost')) return 'http://localhost:3000';
    return 'https://beautista.com';
  };

  const getEmbedCode = () => {
    const baseUrl = getBaseUrl();
    return `<div id="beautista-embed" data-business-id="${businessId}"${includeWhyJoin ? '' : ' data-is-compact'} data-base-url="${baseUrl}"></div>
<script src="${baseUrl}/embed.js"></script>`;
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(getEmbedCode())
      .then(() => {
        message.success('Code snippet copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  };

  return (
    <div className="opportunity-embed">
      <h1>Embed your opportunities on your own website.</h1>
      <p className="description">
        To embed your businesses' opportunities on your own website, copy the code snippet below and
        paste it into an iframe on the careers page of your website.
      </p>
      
      <div className="code-section">
      <button className="copy-button" onClick={handleCopyClick}>
          <span className="copy-icon" />
          Copy Code Snippet
        </button>
        <pre>{getEmbedCode()}</pre>        
      </div>

      <div className="toggle-section">
        <Switch
          checked={includeWhyJoin}
          onChange={setIncludeWhyJoin}
          className="toggle"
        />
        <div className="toggle-content">
          <h3>Include "Why Join Our Team"</h3>
          <p>
            For a more seamless experience for your potential employees, turn on this feature and users will be
            able to view opportunity listings and apply without having to leave your website.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OpportunityEmbed;
