import Http from "./Http";

export const retryPayment = (params: any) => {
  return new Promise((resolve, reject) => {
    console.log("retryPayment", params);
    Http.post("/pay/retry-payment", { ...params })
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => reject(error));
  });
};

export const acceptPromotionOffer = (promotionId: number) => {
  return new Promise((resolve, reject) => {
    Http.post("/pay/accept-promotion", { promotionId })
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const declinePromotionOffer = (promotionId: number) => {
  return new Promise((resolve, reject) => {
    Http.post("/pay/decline-promotion", { promotionId })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => console.error(error));
  });
};

export const getPayPrices = (type: string) => {
  return new Promise((resolve, reject) => {
    const newType = type === "business" ? "six_month" : "school";
    Http.get("/pay/prices/" + newType)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const getPayKey = () => {
  return new Promise((resolve, reject) => {
    Http.get("/pay/key")
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const getClientSec = (isCreditCard: boolean | null) => {
  return new Promise((resolve, reject) => {
    Http.get("/pay/client-sec", { params: { isCreditCard } })
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const getPaymentInfo = () => {
  return new Promise((resolve, reject) => {
    Http.get("/pay/paymentInfo")
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const putPaymentInfo = (params: any) => {
  return new Promise((resolve, reject) => {
    Http.put("/pay/paymentInfo", { ...params })
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const paySubscribe = (params: any) => {
  return new Promise((resolve, reject) => {
    Http.post("/pay/subscribe", { ...params })
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => reject(error));
  });
};

export const changePrice = (params: any) => {
  return new Promise((resolve, reject) => {
    Http.post("/pay/change-price", { ...params })
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const resumePlan = (params: any) => {
  return new Promise((resolve, reject) => {
    Http.post("/pay/resume-plan", { ...params })
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => reject(error));
  });
}

export const previewPrice = (params: any) => {
  return new Promise((resolve, reject) => {
    Http.post("/pay/change-preview", { ...params })
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const payContact = (params: any) => {
  return new Promise((resolve, reject) => {
    Http.post("/pay/contact", { ...params })
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const listPayOrder = () => {
  return new Promise((resolve, reject) => {
    Http.get("/pay/list-payorder")
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const listInvoice = (params: any) => {
  return new Promise((resolve, reject) => {
    Http.post("/pay/list-invoice", params)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const getInvoice = (id: string) => {
  return new Promise((resolve, reject) => {
    Http.get(`/pay/invoice/${id}`)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => console.error(error));
  });
};

export const applyPromotionCode = (params: any) => {
  return new Promise((resolve, reject) => {
    Http.post("/pay/coupon", params)
      .then((resp) => {
        if (resp.status === 200) {
          resolve(resp.data);
        } else {
          reject(resp);
        }
      })
      .catch((error) => reject(error));
  });
};
