import {
  ModuleStore,
  ModuleAction,
  KV,
  plusAction,
  MODULE_COMMON,
} from "module-reaction";
import { v4 as uuidv4 } from "uuid";
import { IOpt, emptyOption, getFilterNum } from "./model_homepage_canvases";
import { getCustomized } from "../api/Options";
import { searchOpportunity, getUsrOppAnchor } from "../api/Opportunity";
import {
  router_opportunity_detail,
} from "../utils/enum";
import { global_router } from "../routers";
import { general_location_address } from "./model_post_job_opp";

export const MODULE_HOMEPAGE_OPPORTUNITY = "MODULE_HOMEPAGE_OPPORTUNITY";
export interface OpportunityItem {
  id: number;
  media: string;
  videoCover?: string;
  link: string;
  is_video: boolean;
  title: string;
  timestamp: string;
  category: string;
  is_unread: boolean;
  biz_name: string;
  biz_ico: string;
  location: string;
}
export interface ModelHomepageOpportunity extends ModuleStore {
  filters: {
    keyword?: string;
    locationInfo: {
      uuid: string;
      areaUnit: string;
      location?: string;
      coordinates?: number[];
    };
    radius?: string;
    category?: string;
    paymentMode?: string;
    software?: string;
    brand?: string;
    jobtype?: string;
  };
  use_location: boolean; // if use_location, keyword will be ignored
  list: OpportunityItem[];
  page_idx: number;
  total: number;
  num_per_page: number;
}

export const module_homepage_opportunity: ModelHomepageOpportunity = {
  module: MODULE_HOMEPAGE_OPPORTUNITY,
  filters: {
    locationInfo: {
      uuid: uuidv4(),
      areaUnit: "ft",
      location: "",
    },
    radius: "radius-50",
  },
  use_location: true,
  list: [],
  page_idx: 1,
  num_per_page: 9, // for now, always be 9
  total: 0,
};

export const browse_opportunity_filter_options: {
  category: IOpt[];
  paymentMode: IOpt[];
  software: IOpt[];
  brand: IOpt[];
  jobtype: IOpt[];

  // other FE defined options
  radius: IOpt[];
} = {
  category: undefined as any,
  paymentMode: undefined as any,
  software: undefined as any,
  brand: undefined as any,
  jobtype: undefined as any,
  // FE defined options
  radius: [
    {
      key: "radius-10",
      name: "10 miles",
    },
    {
      key: "radius-25",
      name: "25 miles",
    },
    {
      key: "radius-30",
      name: "30 miles",
    },
    {
      key: "radius-50",
      name: "50 miles",
    },
    {
      key: "radius-100",
      name: "100 miles",
    },
    {
      key: "radius-250",
      name: "250 miles",
    },
  ],
};

export const FetchOpportunitiesAction: ModuleAction<
  KV,
  ModelHomepageOpportunity
> = {
  module: MODULE_HOMEPAGE_OPPORTUNITY,
  process: async (payload: KV, model: ModelHomepageOpportunity) => {
    const {
      page_idx,
      num_per_page,
      filters,
      use_location,
      isAppend,
    } = Object.assign({}, model, payload);
    // fetch options first is need
    if (!browse_opportunity_filter_options.category) {
      const options = await getCustomized(true, [
        "category",
        "brand",
        "software",
        "jobType",
        "paymentMode",
      ]);
      browse_opportunity_filter_options.category = [
        emptyOption,
        ...options.category["business"].map((_: any) => ({
          ..._,
          key: `${"category"}-${_.id}`,
        })),
      ];
      browse_opportunity_filter_options.brand = [emptyOption].concat(
        options.brand.map((_: any) => ({ ..._, key: `brand-${_.id}` }))
      );
      browse_opportunity_filter_options.software = [emptyOption].concat(
        options.software.map((_: any) => ({ ..._, key: `software-${_.id}` }))
      );
      browse_opportunity_filter_options.jobtype = [emptyOption].concat(
        options.jobType.map((_: any) => ({ ..._, key: `jobType-${_.id}` }))
      );
      browse_opportunity_filter_options.paymentMode = [emptyOption].concat(
        // remove paymentmode's don't show
        options.paymentMode
          .map((_: any) => ({ ..._, key: `paymentMode-${_.id}` }))
          .filter((_: any) => _.name !== "Don't show")
      );
    }
    // test
    // const list = [];
    // for (let i = 0; i < num_per_page; i++) {
    //   const is_video = Math.random() > 0.5;
    //   const test_video =
    //     "https://apd-2447399de278539dbd385c6d9a641ebb.v.smtcdns.com/video.dispatch.tc.qq.com/ALEtrkFQACoYFJ5C_7vQF-kmlJrHGC06cTO2wKzg7S7Q/uwMROfz2r5zIIaQXGdGnC2dfDmb_xYKxrIGz_bGUg2Lja6ru/s20246s8h2l.mp4?vkey=E465871A62AA92D64166CEB259A7091F08AB7A0D926989D2C2455EA118F89718170778C040B256B06FC3E9F4368B851941BBA46FFD11D0A305BEFBF3823F0496F0439276B4456052B53C106AD7A0E53CEB7C86136FA89A701BD7DF4276EA31F151CB2D6E613FAFF61411738CD4D09107AEF5FEFF1BD63C0BF8E3A61C52AF070A&sdtfrom=v1003&guid=d2ac5faaed2493b2d0ade88455cb42d6";
    //   const test_image =
    //     "http://t8.baidu.com/it/u=2857883419,1187496708&fm=79&app=86&f=JPEG?w=1280&h=763";
    //   const card: OpportunityItem = {
    //     media: is_video ? test_video : test_image,
    //     is_unread: Math.random() > 0.5,
    //     is_video,
    //     title: "Here is the opportunity title",
    //     timestamp: moment("2020-4-1")
    //       .add(Math.random() * 23, "day")
    //       .toString(),
    //     category: "Barber",
    //     biz_ico: "http://cdn130.picsart.com/268264658022211.png",
    //     biz_name: "Will ‘s Barber shop",
    //     location: "Cn  NO.888 Walt St.",
    //   };
    //   list.push(card);
    // }

    const res = await searchOpportunity({
      keyword: filters.keyword,
      category: getFilterNum<number>(filters.category),
      location: filters.locationInfo.location
        ? {
            coordinates: filters.locationInfo.coordinates || [0, 0],
            radius: (getFilterNum<number>(filters.radius) || 0) * 1609.344, // convert miles to meter
            locationInfo: filters.locationInfo,
          }
        : undefined,
      software: getFilterNum<number>(filters.software),
      jobtype: getFilterNum<number>(filters.jobtype),
      brand: getFilterNum<number>(filters.brand),
      paymentMode: getFilterNum<number>(filters.paymentMode),
      page: page_idx,
      size: num_per_page,
    });

    const list = isAppend ? model.list : [];
    if (res?.list?.length) {
      /**
       * item------
       * id: number
       * canvasId: number
       * canvasName: string
       * media: {
       *  thumbPhoto: MediaItem
       *  featuredPhoto: MediaItem
       *  companyPhoto: MediaItem
       *  featuredVideo: MediaItem
       * }
       * createdAt: Date
       * updatedAt: Date
       * location: {
       *  id: number
       *  uuid: string
       *  address: string
       * }
       * tagLine: string
       * jobTitle: string
       * jobType: string
       * jobCategory: string
       * paymentMode: string
       */
      res.list.forEach((item: any) => {
        const videoUrl =
          item.media?.featuredVideo?.url || item.media?.featuredVideo?.video;
        const card: OpportunityItem = {
          id: item.id,
          link: `${router_opportunity_detail.replace(":slug", item.slug)}`,
          media: videoUrl || item.media?.thumbPhoto?.image,
          videoCover:
            item.media?.featuredVideo?.image ||
            item.media?.featuredPhoto?.image,
          is_video: !!videoUrl,
          is_unread: false, // lack
          title: item.jobTitle,
          timestamp: item.updatedAt,
          category: item.jobCategory || "unknown",
          biz_ico: item.media?.companyPhoto?.image,
          biz_name: item.canvasName,
          location: item.validForAllState? general_location_address : item.location?.address || "",
        };
        list.push(card);
      });
    }

    return {
      page_idx,
      num_per_page,
      filters,
      use_location,
      list,
      total: res?.count || 0,
    };
  },
};

export const PreFillLocationAndGoHomeOpportunities: ModuleAction<
  any,
  ModelHomepageOpportunity
> = {
  module: MODULE_HOMEPAGE_OPPORTUNITY,
  process: async (payload: any, modle: ModelHomepageOpportunity) => {
    const location = await getUsrOppAnchor();

    const { locationInfo } = modle.filters;
    locationInfo.location = location.locationName;
    locationInfo.coordinates = location.coordinates;

    plusAction({
      module: MODULE_COMMON,
      process: async () => {
        window.location.href = `${process.env.REACT_APP_NEXT_URL}/opportunities`;
      },
    });

    return {
      filters: {
        ...modle.filters,
        locationInfo,
        radius: "radius-50",
      },
    };
  },
};
