import { Layout, Modal } from "antd";
import _ from "lodash";
import { KV, doFunction } from "module-reaction";
import { OpportunityApplicants } from "./dashboard_opportunity/opportunity_applicant";
import SchoolDashboardAddStudent from "./school_dashboard_index/school_dashboard_add_student";
import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import CRHeader from "../../components/component_cr_header";
import DashboardSidebar from "../../components/component_dashboard_sidebar";
import {
  router_add_canvas,
  router_add_student,
  router_bookmarks,
  router_bookmarks_all,
  router_notifications,
  router_dashboard_post_job_opp,
  router_membership,
  router_membership_billing_update,
  router_membership_invoice_list,
  router_membership_invoice_view,
  router_membership_plan_update,
  router_my_canvases,
  router_my_dashboard,
  router_opportunity_applicants,
  router_settings,
  router_message,
  router_upgrade_student_canvas,
  router_admin_user,
  TargetType,
  router_admin_school,
  router_admin_opportunity,
  router_admin_canvas,
  router_admin_sms,
  router_admin_featured,
  router_admin_data,
  router_admin_external_payment
} from "../../utils/enum";
// import { PostJobOpportunity } from "../opportunity/post_job_opp/post_job_opp";
import { Bookmarks } from "./bookmarks/bookmarks";
import { BookmarksAll } from "./bookmarks/bookmarks_all";
import NotificationList from "./notifications/list";
import { mapProp, doAction } from "module-reaction";
import {
  LoginWithTokenAction,
  modle_user,
  LogOutAction,
} from "../../models/model_user";
import { model_dashboad_index } from "../../models/model_dashboard_index";
import { module_notifications } from "../../models/model_notifications";
import "./my_account.less";
import BillingUpdate from "./membership/billing_update";
import { InvoiceList } from "./membership/invoice_list";
import { InvoiceView } from "./membership/invoice_view";
import { MembershipIndex } from "./membership/membership_index";
import { PlanUpdate } from "./membership/plan_update";
import "./my_account.less";
import { AddCanvas } from "./my_canvases/add_canvas";
import { MyCanvas } from "./my_canvases/my_canvas";
import { Message } from "./message/message";
import { module_chat } from "../../models/model_chat";
import { chatClient, initChatClient } from "../../utils/chatHelper";
import { Setting } from "./setting";
import { UpgradeStudentCanvas } from "./upgrade_student_canvas/upgrade_student_canvas";

//admin
import AdminUser from "./admin/user";
import { removeUserDashboardDot } from "../../api/Auth";
import AdminSchool from "./admin/school";
import AdminCanvas from "./admin/canvas";
import AdminOpportunity from "./admin/opportunity";
import DashboardIndex from "./dashboard_index";
import { PostJobOpportunity } from "../opportunity/post_job_opp/post_job_opp";
import GetStartedOverlay from "../onboarding/get_start_overlay";
import { checkOverlay } from "../../utils/utils";
import AdminSms from "./admin/sms";
import AdminFeatured from "./admin/featured";
import AdminData from "./admin/data";
import AdminExternalPayment from "./admin/external";
import RedirectComponent from "../../components/component_redirect";
import { getStaticAssetPath } from "../../utils/utils";
const { Content } = Layout;

interface MyAccountProps extends RouteComponentProps, KV {
  match: any;
}

@mapProp(modle_user)
@mapProp(module_chat)
@mapProp(module_notifications, "notificationCount")
@mapProp(model_dashboad_index)
export default class MyAccount extends React.Component<MyAccountProps, {}> {
  state = {
    getStartedOverlay: false
  }

  componentRouters: any[] = [
    {
      name: "Account Settings",
      path: router_settings,
      component: Setting,
      exact: true,
    },
    {
      name: "Membership",
      path: router_membership,
      component: () => <MembershipIndex {...this.props} />,
      exact: true,
    },
    {
      name: "Invoices",
      path: router_membership_invoice_list,
      component: () => <InvoiceList {...this.props} title="Payment History" />,
      exact: true,
      hideFromMenu: true,
    },
    {
      name: "Invoice Details",
      path: router_membership_invoice_view,
      component: () => <InvoiceView {...this.props} title="Invoice Details" />,
      exact: true,
      hideFromMenu: true,
    },
    {
      name: "Billing Update",
      path: router_membership_billing_update,
      component: () => (
        <BillingUpdate {...this.props} title="Billing Information" />
      ),
      exact: true,
      hideFromMenu: true,
    },
    {
      name: "Plan Update",
      path: router_membership_plan_update,
      component: () => (
        <PlanUpdate {...this.props} title="Change Canvas Plan" />
      ),
      exact: true,
      hideFromMenu: true,
    },
    {
      name: "Notifications",
      path: router_notifications,
      component: NotificationList,
      exact: true,
    },
    {
      name: "Message",
      path: router_message,
      component: () => <RedirectComponent url={`${process.env.REACT_APP_NEXT_URL}/message`} />,
      exact: true,
    },
    {
      name: "Bookmarks",
      path: router_bookmarks,
      component: Bookmarks,
      exact: true,
    },
    {
      name: "Bookmarks",
      path: router_bookmarks_all,
      component: BookmarksAll,
      hide: true,
    },
    {
      name: "My Portfolio",
      path: router_my_canvases,
      component: () => <RedirectComponent url={`${process.env.REACT_APP_NEXT_URL}/portfolio`} />,
      exact: true,
    },
    {
      name: "My Portfolio",
      path: router_add_canvas,
      component: AddCanvas,
      hide: true,
    },
    {
      name: "My Portfolio",
      path: router_upgrade_student_canvas,
      component: UpgradeStudentCanvas,
      hide: true,
    },
    {
      name: "My Dashboard",
      path: router_my_dashboard,
      component: DashboardIndex,
    },
    {
      name: "My Portfolio",
      path: router_add_student,
      component: SchoolDashboardAddStudent,
      hide: true,
    },
    {
      name: "My Dashboard",
      path: router_opportunity_applicants,
      component: () => (
        <OpportunityApplicants
          match={this.props.match}
          history={this.props.history}
        ></OpportunityApplicants>
      ),
      hide: true,
    },
    {
      name: "My Dashboard",
      path: router_dashboard_post_job_opp,
      component: PostJobOpportunity,
      hide: true,
    },
    {
      name: "Home",
      path: `/home`,
    }
  ];

  adminMenuItem = [
    // {
    //   name: "Send SMS",
    //   path: router_admin_sms,
    //   component: AdminSms,
    //   exact: true,
    // },
    {
      name: "Notifications",
      path: router_notifications,
      component: NotificationList,
      exact: true,
    },
    {
      name: "Message",
      path: router_message,
      component: Message,
      exact: true,
    },
    {
      name: "KPI Dashboard",
      path: router_admin_data,
      component: AdminData,
      exact: true,
    },
    {
      name: "External Payment",
      path: router_admin_external_payment,
      component: AdminExternalPayment,
      exact: true,
    },
    {
      name: "School Dashboard",
      path: router_admin_school,
      component: AdminSchool,
      exact: true,
    },
    {
      name: "Canvas Dashboard",
      path: router_admin_canvas,
      component: AdminCanvas,
      exact: true,
    },
    {
      name: "Opportunity Dashboard",
      path: router_admin_opportunity,
      component: AdminOpportunity,
      exact: true,
    },
    {
      name: "My Users",
      path: router_admin_user,
      component: AdminUser,
      exact: true,
    }
  ];

  componentDidMount() {
    // if (!this.props.curUser.profile) {
    // doAction(LoginWithTokenAction);
    // }
    // fresh userinfo each time enter this page
    // console.log("fresh user info")
    doAction(LoginWithTokenAction);
    doFunction(async () => {
      if (!chatClient) {
        await initChatClient(this.props.curUser);
      }
    });

    if (checkOverlay()) {
      this.setState({ getStartedOverlay: true });
    }
  }

  componentWillUnmount() {
    if (this.props.location.pathname === router_my_dashboard) {
      // remove user dashboard red-dot
      // console.log("fresh user info2")
      if (this.props.curUser?.role?.value === 'end_user') {
        removeUserDashboardDot().then(data => {
          doAction(LoginWithTokenAction)
        })
      }
    }
  }

  private jumpToLoginPage = () => {
    this.props.history.push({
      pathname: "/sign-in",
      search: `?from=${window.location.pathname}`,
    });
  };

  render() {
    const bgImage = getStaticAssetPath('images/dashboard-bg-2x.png');
    const target =
      this.props.curUser &&
      this.props.curUser.profile &&
      this.props.curUser.profile.target;
    const list =
      target === TargetType.ADMIN ? this.adminMenuItem : this.componentRouters;
    const menuList = list.slice(0, list.length);
    const removeList = [];
    const { getStartedOverlay } = this.state;

    // Remove submenu from side menu
    for (var i = 0; i < menuList.length; i++) {
      if (menuList[i].hideFromMenu) {
        removeList.push(i);
      }
    }
    for (var j = removeList.length - 1; j >= 0; j--) {
      menuList.splice(removeList[j], 1);
    }

    const pathname = window.location.pathname;
    const cur = _.find(list, (item) => {
      return pathname.indexOf(item.path) > -1;
    });
    let curTab = (cur && cur.name) || "";
    return (
      <Layout className="home-root">
        <CRHeader
          className="home-header"
          history={this.props.history}
          onLogin={() => this.jumpToLoginPage()}
          onLogout={() => {
            doAction(LogOutAction);
            doFunction(async () => {
              this.props.history.push("/");
            });
          }}
        />
        <Content className="dashboard-content-wrap" style={{ backgroundImage: `url(${bgImage}) !important` }}>
          <DashboardSidebar
            dashboardDot={this.props.curUser?.profile?.dashboardDot}
            notificationCount={this.props.notificationCount}
            sidebarList={menuList}
            curTab={curTab}
            unreadCount={this.props.unreadCount}
          />
          <div className="dashboard-content">
            <div className="dashboard-route-wrap">
              <Switch>
                {list.map((_: any, idx: number) => (
                  <Route
                    key={`${_.path}-${idx}`}
                    path={_.path}
                    component={_.component}
                    exact={_.exact || false}
                  />
                ))}
              </Switch>
            </div>
          </div>
        </Content>

        <Modal visible={getStartedOverlay}
          closable={true}
          onCancel={() => this.setState({ getStartedOverlay: false })}
          maskClosable={true}
          footer={null}
          centered={true}>
          <GetStartedOverlay type={'school'} close={() => this.setState({ getStartedOverlay: false })}></GetStartedOverlay>
        </Modal>

      </Layout>
    );
  }
}

