import React from "react";
import { RouteComponentProps } from "react-router";
import "./homepage_terms.less";

export class HomepagePrivacyPolicy extends React.Component<RouteComponentProps> {
  render() {
    return (
      <div className="homepage-terms-wrap">
        <div className="terms-banner padding-layout">
          <div className="homepage-title-wrap">
            <div className="title">Privacy Policy</div>
          </div>
        </div>
        <div className="terms-content">
          <div className="padding-layout">
            <h3>Privacy Policy for Beautiful Canvas, LLC </h3>
            <p>
              Your privacy is important to us. This Privacy Policy explains the
              types of personal information collected about you by Beautiful
              Canvas, LLC d/b/a BEAUTISTA, its parents, subsidiaries and/or
              affiliated companies (collectively, “BEAUTISTA,” “we,” “our,” or
              “us”), as well as how we use the information we collect; how we
              may share the information we collect; the security we employ to
              protect the information we collect; and how you may contact us and
              opt out of certain uses or sharing of your personal information in
              connection with our Website, mobile or desktop applications,
              and/or other services (collectively hereinafter, “Services”).
              Personal information is information that identifies, describes, or
              is reasonably capable of being associated with or linked to,
              directly or indirectly, an individual.
            </p>

            <p>
              Effective date: September 2, 2020
              <br />
              Last update: July 13, 2023
            </p>

            <h3>TABLE OF CONTENTS</h3>
            <p>
              This table of contents provides a summary of this Privacy Policy.
              It is for your convenience only, and intended only to help guide
              you. It should not be read as a complete presentation of all of
              the terms and conditions contained herein. We encourage you to
              review the entire Privacy Policy.
            </p>

            <p>
              <strong>Scope of this Privacy Policy</strong>. This Privacy Policy
              applies to our collection, use, disclosure, and security of
              personal information in connection with our Services.
            </p>

            <p>
              <strong>International Transfers of Data</strong>. We process data
              in the U.S. only. If you are located outside of the U.S., and you
              access or use our Website or Services, you do so at your own risk.
              You acknowledge that your personal information will be sent to the
              U.S., where data protection and other laws may provide a less
              comprehensive or protective standard of protection than those in
              your country.
            </p>

            <p>
              <strong>Children's Privacy</strong>. Our Services are not designed
              or intended for use by children under sixteen (16) years old.
            </p>

            <p>
              <strong>Modification</strong>. We reserve the right to modify this
              Privacy Policy at any time to reflect changes to our practices or
              the law.
            </p>

            <p>
              <strong>Information We Collect</strong>. We may collect certain
              information about you.
            </p>

            <p>
              <strong>Mobile (SMS) Disclosures</strong> How we may communicate
              with you and your right to opt-out.
            </p>

            <p>
              <strong>How We Use Your Information</strong>. How we may use the
              information we collect about you.
            </p>

            <p>
              <strong>How We May Share Your Information</strong>. How we may
              share the information we collect about you with others.
            </p>

            <p>
              <strong>How We Protect Your Information</strong>. We use
              reasonable physical, technical, and administrative safeguards
              designed to protect the security of personal information that we
              collect.
            </p>

            <p>
              <strong>Cookies, Web Beacons, and Google Analytics</strong>. We
              use cookies, web beacons, and Google Analytics.
            </p>

            <p>
              <strong>Communications with You</strong>. How we may communicate
              with you and your right to opt-out.
            </p>

            <p>
              <strong>Linked Sites</strong>. Our Services may be accessed
              through certain websites.
            </p>

            <p>
              <strong>Your California Privacy Rights</strong>. Depending on
              where you live, you may have certain rights with respect to your
              information.
            </p>

            <p>
              <strong>How to Contact Us</strong>. You may contact us regarding
              the collection, use, disclosure, or security of your personal
              information by one of the means provided below. In contacting us,
              we may ask you to provide additional information.
            </p>

            <h4>SCOPE OF THIS PRIVACY POLICY</h4>
            <p>
              This Privacy Policy only applies to our collection of personal
              information of individuals in the United States in connection with
              our Services, whether collected directly from you or shared with
              us by another person or organization. Please review this Privacy
              Policy carefully. By accessing and using our Services, and/or by
              otherwise submitting information to us – for instance, when you
              establish an account – you signal your consent to the terms of
              this Privacy Policy. If you do not agree with any part of this
              Policy, please do not use our Services. Please review our Terms of
              Use, posted <a href="/terms">here</a>. The Terms of Use describes
              the terms and conditions for use of our Website and platform in
              connection with our Services.
            </p>

            <h4>INTERNATIONAL TRANSFERS OF DATA</h4>
            <p>
              BEAUTISTA is located and operates in the United States for residents
              of the United States. Our Services, including our mobile/desktop
              application and Website, are governed by and operated in
              accordance with the laws of the United States, its territories,
              possessions, and protectorates (“U.S.”). We process data in the
              U.S. only. If you are located outside of the U.S., and you access
              or use our Services, you do so at your own risk. If you are
              outside of the U.S., it is necessary to transfer your personal
              information to and process in the U.S. where our central databases
              operate. By using our Website or Services, you (a) acknowledge
              that the data protection and other laws of the U.S. may provide a
              less comprehensive or protective standard of protection than those
              in your country, and (b) you consent to your personal information
              being collected, processed, and transferred as set forth in this
              Privacy Policy and under U.S. law.
            </p>

            <h4>CHILDREN'S PRIVACY</h4>
            <p>
              Our Services, including our platform, are not designed to attract
              children aged thirteen (13) and under. Further, you must be
              sixteen (16) or older to use our Services. We do not seek, or
              knowingly collect, personal information from children under 16
              unless such information is provided by their parents or legal
              guardians in connection with our Services. If we learn we have
              collected or received personal information from a child under 16
              without verification of parental consent, we will delete that
              information. If you believe we might have any information from or
              about a child under 16, please contact us via the contact methods
              below.
            </p>

            <h4>MODIFICATION</h4>
            <p>
              We reserve the right to modify this Privacy Policy at any time to
              reflect changes to our practices or the law. We will make the
              revised Privacy Policy accessible through access to our Services.
              Please check this Policy periodically for updates. The date at the
              top of the Privacy Policy also tells you when it was last updated.
              If we make material changes to the way we collect or use your
              information, you will be provided with appropriate notice,
              including by, but not limited to, posting the updated Privacy
              Policy on our platform, providing notice when you access our
              platform, and/or providing notice in accordance with applicable
              legal requirements. Any changes to this Privacy Policy will become
              effective when the revised Privacy Policy is posted. By continuing
              to use our platform and/or other Services following notice of any
              changes to this Privacy Policy, you acknowledge you have read and
              understand the updated Privacy Policy, and you understand that we
              will collect, use, and share information as stated therein.
            </p>

            <h4>INFORMATION WE COLLECT</h4>
            <p>
              Information You Give To Us. We collect personal information you
              choose to share with us when you use our Services. We collect
              information from you when establish an account or online portfolio
              with us. Such information can include username and password, and
              contact information, like your name, email address, residential
              address, telephone number, or any other identifier by which you
              may be contacted online or offline. We may collect information
              about you when populate your portfolio or profile with us. That
              information may include your location, job title, license number,
              specialties, or other information you choose to post. If you post
              material, including job opportunities, through our Services, we
              collect the information that you provide. We collected records and
              copies of your correspondence (including email addresses), if you
              contact us, services you have purchased, consuming histories, and
              your responses to surveys that we might ask you to complete for
              research or business purposes. We collect details of transactions
              you carry out through our Website and/or Services in fulfillment
              of your requests or inquiries. You may be required to provide
              financial information before subscribing to our Services, such as
              listing job postings, through our Website. We also collect your
              search queries on our Website.
            </p>

            <p>
              Other information we may collect other information from you that
              you post through our Services, including photos, videos,
              geolocation, gender, race, employer, employment history,
              employment information, years of beauty experience, educational
              information (including name of school, GPA, enrolled programs, and
              date of graduation/attendance records), license number and status,
              certifications, awards, affiliated memberships and associations,
              client metrics (including frequency of repeat customers, client
              retention, sales, average client bill, etc.) and business
              information (including number of employees, preferred brands,
              square footage, software used, number of chairs, etc.). We also
              may collect research and feedback information from you through
              online forms and social media platforms, by email, over the phone,
              by mail, or in surveys.
            </p>

            <p>
              We may collect information you provide to be published or
              displayed on public areas of our Website, or transmitted to other
              users of our Website (collectively, “User Contributions”). Your
              User Contributions are posted on and transmitted to others at your
              own risk, and subject to the Terms of Use of our Website and other
              Services.
            </p>

            <p>
              Other Information We Collect. We may collect personal information
              about you via automated means when you visit our Website,
              including information collected from cookies (see our Cookie Use
              below), including connection and usage information, IP addresses,
              browsing activity, forms or fields you complete or partially
              complete, search terms, whether you open an email from us, content
              you view and the duration. We collect the services you have
              purchased and your consuming history. We may aggregate data to
              help improve our platform, applications and/or Website experience
              for our users. We may also ask you for information when you enter
              a contest or promotion sponsored by us or our business partners or
              other third parties, and when you report a problem with our
              Website. If you apply for employment with us, we will collect your
              information for hiring-related purposes.
            </p>

            <p>
              Information We Collect From Third Parties. We may collect
              information from third parties whom you authorize to share
              information in connection with our Services. We may collect
              information from other Users of our services, who may post
              materials that refer to or depict you – for instance, a photo or
              comment. We may collect your information from our service
              providers to better render our Services for you and/or to help
              with our operations and for fraud prevention. Service providers
              may use such information for their own operational purposes in
              order to provide their services to us to enable us to operate and
              provide our Services.
            </p>

            <h4>MOBILE (SMS) DISCLOSURES</h4>
            <p>
              <strong>Consent to Receive Text Messages.</strong> By entering your
              mobile phone number on our Website or in connection with our
              Services, you agree to receive public service announcements,
              customer care, and account notification SMS messages from
              BEAUTISTA at the number provided. Message frequency may vary.
              Message and data rates may apply. Reply HELP for help. Reply STOP to
              opt-out. View our complete Privacy Policy and Terms of Use for more
              information.
            </p>

            <p>
              <strong>No Third-Party Sharing of Mobile Opt-In Data.</strong> Mobile
              information will not be shared with third parties or affiliates for
              marketing or promotional purposes. All the above categories exclude
              text messaging originator opt-in data and consent; this information
              will not be shared with or obtained from any third parties.
            </p>

            <h4>HOW WE USE YOUR INFORMATION</h4>
            <p>
              We use the personal information we collect about you in connection
              with our Services, including the operation of our platform and
              maintenance of your online portfolio/account, such as when acting
              as administrators. If you apply for employment with us, we will
              use your information for hiring-related purposes. If you request
              information or services from us, we will use your information to
              fulfill your request or any other purpose for which you provide
              it. We may use your information to allow you to establish an
              account/online portfolio, and for us to confirm your account with
              us, and to help enable you to use our Services. We may use your
              information to deliver materials or information to you, to
              participate in interactive features of our Services, or to give
              you notice of and/or resolve any problems that may arise in
              connection with our Services. We may use your information to
              respond to inquiries made by you, or for other customer service
              related reasons. We may use your information to provide you with
              information about our other Services, to provide you notices about
              your account and/or subscriptions., including expiration and
              renewal notices, or to better understand your needs. We may use
              your information to improve the quality and/or your personal
              experience on our Website, applications, or other aspects of our
              platform, and/or to analyze the success of our Services.
            </p>

            <p>
              We may use your information to enforce our rights, including for
              billing and collection, or to comply with applicable law; to
              detect or prevent fraud (financial or otherwise), intellectual
              property infringement, potential misuse of our Services, or breach
              of our Terms of Use. We may use your information to manage
              third-party risks or other risks to our business, or to protect
              the reputation of our businesses. We may anonymize, aggregate or
              combine information we collect online or elsewhere for the above
              purposes or other purposes, to improve our Services, to develop
              new services, and/or to analyze statistics on usage and trends.
              Please note that aggregated or de-identified data may not
              constitute personal information.
            </p>

            <h4>HOW WE MAY SHARE YOUR INFORMATION </h4>
            <p>
              We may share the information we collect about you for business
              operations and in order to perform our Services for you, such as
              your financial information to a third-party processor. We
              contractually require these third parties to keep personal
              information confidential and use it only for the purposes for
              which we disclose it to them. We may share information you post or
              otherwise provide as a User Contribution. We may share your
              information with your school when you identify your school in your
              Canvas Portfolio profile. We also may share your information to
              fulfill the purpose for which you provide it; for example, if you
              give us an email address to use the “email a salon” feature of our
              Website, we will transmit the contents of your email and your
              email address to the salons.
            </p>

            <p>
              We may share your information with service providers to better or
              Services and/or for operational purposes, promotion of
              administration, and fraud prevention. We may share information we
              collect, as appropriate, with law enforcement or otherwise to
              protect and enforce the legal and contractual rights, privacy, and
              safety of us or others; to protect against possible fraud or other
              illegal activity; to respond to requests from government and other
              authorities; and/or to comply with legal processes.
            </p>

            <p>
              In the event that BEAUTISTA or an affiliate is involved in a merger,
              acquisition, transfer of control, bankruptcy, reorganization or
              sale of assets, or diligence associated with such matters, we may
              sell or transfer the information described in this Privacy Policy
              as part of that transaction.
            </p>

            <h4>HOW WE PROTECT YOUR INFORMATION</h4>
            <p>
              We employ reasonable physical, technical, and administrative
              safeguards designed to protect the security of personal
              information that we collect in connection with our Website and our
              Services. These safeguards vary depending upon a variety of
              factors including the sensitivity of the information we collect
              and use.
            </p>

            <p>
              Despite all reasonable practices, no security method is
              infallible. We cannot guarantee the security of the networks,
              systems, servers, devices, and databases we operate, that are
              operated on our behalf, or which you employ to access our Website
              and our Services. Nor can we guarantee the security of third-party
              networks, including cellular networks and storage servers, used in
              connection with our Website and our Services. In addition,
              although we may limit access to certain pages or we may allow you
              to set certain privacy settings for such information by logging
              into your account profile, please be aware that no security
              measures are perfect or impenetrable. Additionally, we cannot
              control the actions of other users of the Website with whom you
              may choose to share your User Contributions. Therefore, we cannot
              and do not guarantee that your User Contributions will not be
              viewed by unauthorized persons.
            </p>

            <p>
              Your Passwords. You may be asked to generate a password when you
              create an account/online portfolio with us. You are responsible
              for maintaining the confidentiality of your password. We are not
              be liable for any loss that you may incur as a result of someone
              else using your password. You may not share your password. Please
              see our Terms of Use for requirements and prohibitions regarding
              your password.
            </p>

            <p>
              If you believe that the security of any account you might have
              with us has been compromised, please email us at{" "}
              <a href="mailto: Privacy@beautista.com">Privacy@beautista.com</a>.
            </p>

            <p>
              If you wish to delete your account, please email us at{" "}
              <a href="mailto: Privacy@beautista.com">Privacy@beautista.com</a>{" "}
              and your portfolio and associated account information will be
              deleted, including any and all personal data.
            </p>

            <h4>
              COOKIES, WEB BEACONS, GOOGLE ANALYTICS, AND DO NOT TRACK SIGNALS
            </h4>
            <p>
              We employ reasonable physical, technical, and administrative
              safeguards designed to protect the security of personal
              information that we collect in connection with our Website and our
              Services. These safeguards vary depending upon a variety of
              factors including the sensitivity of the information we collect
              and use.
            </p>

            <p>
              Despite all reasonable practices, no security method is
              infallible. We cannot guarantee the security of the networks,
              systems, servers, devices, and databases we operate, that are
              operated on our behalf, or which you employ to access our Website
              and our Services. Nor can we guarantee the security of third-party
              networks, including cellular networks and storage servers, used in
              connection with our Website and our Services. In addition,
              although we may limit access to certain pages or we may allow you
              to set certain privacy settings for such information by logging
              into your account profile, please be aware that no security
              measures are perfect or impenetrable. Additionally, we cannot
              control the actions of other users of the Website with whom you
              may choose to share your User Contributions. Therefore, we cannot
              and do not guarantee that your User Contributions will not be
              viewed by unauthorized persons.
            </p>

            <p>
              Your Passwords. You may be asked to generate a password when you
              create an account/online portfolio with us. You are responsible
              for maintaining the confidentiality of your password. We are not
              be liable for any loss that you may incur as a result of someone
              else using your password. You may not share your password. Please
              see our Terms of Use for requirements and prohibitions regarding
              your password.
            </p>

            <p>
              If you believe that the security of any account you might have
              with us has been compromised, please email us at{" "}
              <a href="mailto: Privacy@beautista.com">Privacy@beautista.com</a>.
            </p>

            <h4>
              COOKIES, WEB BEACONS, GOOGLE ANALYTICS, AND DO NOT TRACK SIGNALS
            </h4>
            <p>
              Our Website uses cookies, which are small data files sent to your
              computer on the basis of the information that we have collected
              about your visit. You may refuse to accept browser cookies by
              activating the appropriate setting on your browser. However, if
              you select this setting you may be unable to access certain parts
              of our Website. Unless you have adjusted your browser setting so
              that it will refuse cookies, our system will issue cookies when
              you direct your browser to our Website. Certain features of our
              Website may use locally-stored objects (or Flash cookies) to
              collect and store information about your preferences and
              navigation to, from, and on our Website. Flash cookies are not
              managed by the same browser settings as are used for browser
              cookies.
            </p>

            <p>
              Your web browser will have an option you may select that will
              block cookies. Below are links to information about popular web
              browsers and how to block cookies using them.
            </p>

            <p>
              <a
                href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&redirectslug=Enabling+and+disabling+cookies"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&redirectslug=Enabling+and+disabling+cookies
              </a>
              <br />
              <a
                href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies
              </a>
              <br />
              <a
                href="https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies
              </a>
              <br />
              <a
                href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.apple.com/en-gb/guide/safari/sfri11471/mac
              </a>
            </p>

            <p>
              Our Website and emails we send also may contain small electronic
              files known as web beacons (also referred to as clear gifs, pixel
              tags, and single-pixel gifs) that permit us, for example, to count
              users who have visited those pages or opened an email and for
              other related website statistics (for example, recording the
              popularity of certain website content and verifying system and
              server integrity).
            </p>

            <p>
              We also may use cookies and web beacons to collect information
              about your online activities over time and across third-party
              websites or other online services (behavioral tracking). The
              information we collect automatically may include personal
              information or we may maintain it or associate it with personal
              information we collect in other ways or receive from third
              parties. It helps us to improve our Website and to deliver a
              better and more personalized service, including by enabling us to
              estimate our audience size and usage patterns; store information
              about your preferences, allowing us to customize our Website
              according to your individual interests; speed up your searches;
              recognize you when you return to our Website; and/or improve the
              experiences of users of our Website.
            </p>

            <p>
              Google Analytics
              <br />
              Our Website employs Google Analytics, a web analytics tool
              provided by Google, Inc. that helps website owners understand how
              visitors engage with their websites. We can view a variety of
              reports about how visitors interact with our Website so we can
              improve it. Google Analytics collects information using cookies
              and IP addresses, and it reports website trends without
              identifying individual visitors. In the event a visitor to our
              Website submit contact information to Google, Google may then
              associate such information with the applicable IP address to
              enable us to see that visitor's specific interactions with our
              Website.
            </p>

            <p>
              You can opt-out of Google Analytics by installing the Google
              Analytics Opt-out Browser Add-On. For information about and to
              install Google Analytics Opt-out Browser Add-on, click here:
              <br />
              <a
                href="https://policies.google.com/technologies/partner-sites"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/technologies/partner-sites
              </a>{" "}
              or
              <br />
              <a
                href="https://support.google.com/analytics/answer/6004245"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.google.com/analytics/answer/6004245
              </a>
            </p>

            <p>
              Do Not Track Signals
              <br />
              Certain web browsers provide users with an option by which you may
              have your browser send a "Do Not Track" signal to websites that
              you are visiting, advising the recipient websites that you do not
              want to have your online activity tracked. However, the way
              browsers communicate such "Do Not Track" signals is not yet
              uniform and, accordingly, our Website does not take any action in
              response to such signals. In the event a final standard is
              developed and accepted, we may reassess how we should respond to
              such signals.
            </p>

            <p>
              Further, some content or applications, including advertisements,
              on the Website may be served by third-parties, including
              advertisers, ad networks and servers, content providers, and
              application providers. These third parties may use cookies alone
              or in conjunction with web beacons or other tracking technologies
              to collect information about you when you use our website. The
              information they collect may be associated with your personal
              information or they may collect information, including personal
              information, about your online activities over time and across
              different websites and other online services. They may use this
              information to provide you with interest-based (behavioral)
              advertising or other targeted content. We do not control these
              third parties' tracking technologies or how they may be used. If
              you have any questions about an advertisement or other targeted
              content, you should contact the responsible provider directly.
            </p>

            <h4>COMMUNICATIONS WITH YOU </h4>
            <p>
              Our Communications with You We may use your personal information
              to contact you via direct postal mail, sms messaging, and/or
              electronically to the extent such activity is permitted by law.
              This information may be used to help us and our commercial
              partners, co-sponsors and services providers deliver information
              to you, and for other purposes as are reasonably necessary to
              provide the services requested by you.
            </p>

            <p>
              You may opt-out of receiving future mailings or other messages
              from us by following the instructions in the e-mail or other
              message you receive from us or by following the instructions set
              forth in the Opting In and Opting Out of Electronic Communications
              immediately below.
            </p>

            <p>
              Opting In and Opting Out of Electronic Communications At certain
              points where personal information is collected on our Website or
              otherwise in connection with our Services, there may be a box to
              indicate that you wish to receive certain information or
              communications from Canvas regarding. You may opt out of receiving
              electronic communications, such as emails or text messages, from
              us at any time by changing your account settings. In all of our
              electronic communications you will be given the opportunity to opt
              out of receiving future communications.
            </p>

            <p>
              You may also opt-out or change your preferences by providing
              opt-out instructions – e.g. sending an email or postal mail as
              provided below:
              <br />
              • Postal Mail: P.O. Box 469, Florham Park, NJ 07932
              <br />• Email Privacy:{" "}
              <a href="mailto: Privacy@beautista.com">Privacy@beautista.com.</a>
            </p>

            <p>
              <u>Reply STOP to any message</u>
            </p>

            <p>
              Be sure to include the contact information we are using to
              communicate with you, such as your email address, and let us know
              the specific types of communications you no longer wish to
              receive.
            </p>

            <h4>LINKED WEBSITES</h4>
            <p>
              You may access our platform from a third-party website (i.e., a
              website owned or operated by another entity) (“Linked Sites”). Any
              such linking should not be construed as an endorsement by Canvas.
              Linked Sites are not controlled by Canvas; nor are they governed
              by this Privacy Policy. This Privacy Policy governs only personal
              information collected by Canvas in connection with our Services.
              When visiting third-party websites, including Linked Sites, we
              encourage users to review the privacy policy governing such site.
            </p>

            <h4>YOUR CALIFORNIA PRIVACY RIGHTS</h4>
            <p>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with Services, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Services, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.,
              backups, etc.).
            </p>
            <h3>CCPA Privacy Notice</h3>
            <p>The California Code of Regulations defines a "resident" as:</p>
            <p>
              (1) every individual who is in the State of California for other
              than a temporary or transitory purpose and
            </p>
            <p>
              (2) every individual who is domiciled in the State of California
              who is outside the State of California for a temporary or
              transitory purpose
            </p>
            <p>All other individuals are defined as "non-residents." </p>
            <p>
              If this definition of "resident" applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </p>
            <p>What categories of personal information do we collect?</p>
            <p>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </p>
            <p>Data you provide to us:</p>
            <p>
              <u>Registration</u>
            </p>
            <p>
              To create an account you need to provide data including your name,
              email address, mobile number, job title. If you register for a
              premium service, you will be asked for credit card and billing
              information.
            </p>
            <p>
              <u>Portfolio</u>
            </p>
            <p>
              You have choices about the information on your portfolio, such as
              pronouns, years of experience, professional category, work
              experience, school attended, graduation date, attendance
              percentage, GPA, work permit number, online booking link, Key
              Performance indicators, license number, photos, city, state,
              preferred brands, software, payment preference, awards and
              certifications. You don't have to provide additional information,
              however, information helps you to get more from BEAUTISTA,
              including helping recruiters and business opportunities find you.
              Please do not post or add personal information or data to your
              portfolio that you do not want publicly available.
            </p>
            <p>
              <u>Career Opportunity</u>
            </p>
            <p>
              You have choices about the information on your career opportunity,
              such as, company name, website URL, job type, location, photos,
              videos, why work with us, about us, preferred brands, software,
              and payment structure. Please do not post or add information or
              data to your career opportunity that you do not want publicly
              available.
            </p>
            <p>
              <u>Business Portfolio</u>
            </p>
            <p>
              You have choices about the information on your business portfolio
              such as, Company Name, Headline, photos, videos, primary contact
              number, business category, Location(s), number of employees,
              number of chairs, square footage, retail brands, software, backbar
              brands, specialties, payment preference, company benefits, and
              awards and achievements. Please do not post or add information or
              data to your business portfolio that you do not want publicly
              available.
            </p>
            <p>
              <u>Syncing</u>
            </p>
            <p>
              If you sync and or link your social channels to your portfolio or
              career listing, we will collect your social profile name and link.
            </p>
            <p>
              You don't have to post or upload personal data; though if you
              don't, it may limit your ability to grow and engage with your
              network on BEAUTISTA.
            </p>
            <p>
              We may also collect other personal information outside of these
              categories instances where you interact with us in person, online,
              or by phone or mail in the context of:
            </p>
            <p>
              We may collect personal information when you interact with us in
              person, online, or by phone or mail in the context of:
            </p>
            <p>Receiving help through our customer support channels;</p>
            <p>Participation in customer surveys or contests; and</p>
            <p>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </p>

            <h4>CONTACT US </h4>
            <p>
              For any questions or complaints about this Privacy Policy or our
              privacy practices, please contact us at:
            </p>

            <p>
              Postal Mail: P.O. Box 469, Florham Park, NJ 07932 Email Privacy:{" "}
              <a href="mailto: Privacy@beautista.com">Privacy@beautista.com</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
