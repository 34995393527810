import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Layout, Button, Divider, message } from "antd";
import _ from "lodash";
import queryString from "query-string";
import { mapProp, doAction } from "module-reaction";
import { LoginWithTokenAction, modle_user } from "../../models/model_user";
import { getCanvas } from "../../api/Canvas";
import CreateBusinessCanvasHeader from "../../components/component_create_header";
import IconSuccess from "../../assets/images/ic_success_check.svg";
import IconCopy from "../../assets/images/ic_copy_link.svg";
import IconFacebook from "../../assets/images/ic_facebook.svg";
import IconTwitter from "../../assets/images/ic_twitter.svg";
import { CanvasType, router_post_job_opp } from "../../utils/enum";
import { copyToClipboard } from "../../utils/utils";
import "./publish_success.less";
import { getTodoOpportunity } from "../../api/Todo";
const { Content } = Layout;

const successData: any = {
  [CanvasType.BUSINESS]: {
    title: "Business",
    intro:
      "You can share the portfolio link on social media now, or start posting job opportunities to attract talents!",
  },
  [CanvasType.SCHOOL]: {
    title: "School",
    intro:
      "You can share the portfolio link on social media now, or start posting job opportunities to attract talents!",
  },
  [CanvasType.STUDENT]: {
    title: "Student",
    intro: "You can share the portfolio link on social media now",
  },
  [CanvasType.PROFESSIONAL]: {
    title: "Professional",
    intro: "You can share the portfolio link on social media now",
  },
};

@mapProp(modle_user)
export default class PublishSuccess extends React.Component<RouteComponentProps, {}> {
  state = {
    job: {} as any,
    
  };

  componentDidMount() {
    const { id } = queryString.parse(window.location.search);
    this.initCanvas(id);
    doAction(LoginWithTokenAction);
  }

  private initCanvas = async (id: any) => {
    if (id) {
      const res = await getCanvas(id);
      if (res && res.job) {
        this.setState({ job: res.job });
        console.log(res);
        if (res.type === CanvasType.STUDENT || res.type === CanvasType.PROFESSIONAL) {
          const todos = await getTodoOpportunity();
          if (todos) {
            console.log(todos);
            if (todos.length > 0) {
              if (todos[0].opportunityId) {
                window.location.href = `${process.env.REACT_APP_NEXT_URL}/home`;
              }
            }
          }
        }
      }
    }
  };

  getLink = () => {
    const { id } = queryString.parse(window.location.search);
    return `${window.location.origin}/canvas/${id}`;
  };

  copyLink = () => {
    copyToClipboard(this.getLink());
  };

  private getHeaderType = (type: string) => {
    switch (type) {
      case "business":
        return CanvasType.BUSINESS;
      case "student":
        return CanvasType.STUDENT;
      case "professional":
        return CanvasType.PROFESSIONAL;
      default:
        return CanvasType.SCHOOL;
    }
  };

  private getFirstButton = (type: string) => {
    switch (type) {
      case "school":
        return (
          <Button
            className="btn-primary"
            onClick={() => this.props.history.push("/my-dashboard")}
          >
            View school dashboard
          </Button>
        );
      case "business":
      case "student":
      case "professional":
      default:
        return (
          <Button
            className="btn-primary"
            onClick={() => this.props.history.push("/my-canvases")}
          >
            View my Portfolio
          </Button>
        );
    }
  };

  private getSecondButton = (type: string) => {
    switch (type) {
      case "business":
        return (
          <Button
            className="btn-secondary"
            onClick={() => {
              if (this.state.job.max <= this.state.job.now) {
                message.error(
                  "Your current membership and plan does not cover this opportunity post. Please check your membership setting or contact support at hello@beautista.com"
                );
                return;
              }
              this.props.history.push({
                pathname: router_post_job_opp,
                search: `?id=${queryString.parse(window.location.search).id}`,
              });
            }}
          >
            Post Job Opportunity
          </Button>
        );
      case "school":
        return (
          <Button
            className="btn-secondary"
            onClick={() => this.props.history.push("/my-canvases")}
          >
            View my portfolio
          </Button>
        );
      case "student":
      case "professional":
      default:
        return (
          <Button
            className="btn-secondary"
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_NEXT_URL}/opportunities`;
            }}
          >
            See opportunities
          </Button>
        );
    }
  };

  render() {
    const type = _.get(this.props.match, "params.type") as string;
    return (
      <Layout>
        <CreateBusinessCanvasHeader
          type={this.getHeaderType(type)}
          step={
            this.getHeaderType(type) === CanvasType.SCHOOL ||
            this.getHeaderType(type) === CanvasType.BUSINESS
              ? 3
              : 2
          }
        />
        <Content>
          <div className="publish-success-wrap">
            <img className="success-icon" src={IconSuccess} alt="" />
            <div className="common-title">
              Your {successData[type].title} portfolio was created successfully
            </div>
            <div className="intro">{successData[type].intro}</div>
            <div className="copy-link">
              <span>{this.getLink()}</span>
              <div onClick={this.copyLink}>
                <img src={IconCopy} alt="" />
                <span>Copy link</span>
              </div>
            </div>
            <div className="divider">
              <Divider>or</Divider>
            </div>
            <div className="share">
              <span>Share to social media</span>
              <div className="social-icons">
                <img
                  src={IconFacebook}
                  alt=""
                  onClick={() =>
                    window.open(
                      `https://www.facebook.com/sharer.php?u=${this.getLink()}`,
                      "_blank"
                    )
                  }
                />
                <img
                  src={IconTwitter}
                  alt=""
                  onClick={() =>
                    window.open(
                      `http://twitter.com/share?url=${this.getLink()}`,
                      "_blank"
                    )
                  }
                />
              </div>
            </div>
            <div className="btn-wrap">
              {this.getFirstButton(type)}
              {this.getSecondButton(type)}
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}
