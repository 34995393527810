import { TextField, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button, Col, Row } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import React from "react";
import { sendContactEmail } from "../../api/Homepage";
import IconEmail from "../../assets/images/ic_email.svg";
import IconFB from "../../assets/images/ic_fb_black.svg";
import IconCheck from "../../assets/images/ic_check.svg";
import IconInstagram from "../../assets/images/ic_instagram.svg";
import "./homepage_contact.less";

class HomepageContact extends React.Component<FormComponentProps, any> {
  constructor(props: FormComponentProps) {
    super(props);

    this.state = {
      submitted: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  public render() {
    return (
      <div className="homepage-contact-wrap">
        <div className="contact-banner padding-layout">
          <div className="homepage-title-wrap">
            <div className="title">Contact Us</div>
          </div>
        </div>
        <div className="contact-content">
          <div className="padding-layout">
            {!this.state.submitted ? this.form() : this.postSubmit()}
            {this.socialContacts()}
          </div>
        </div>
      </div>
    );
  }

  private socialContacts() {
    return (
      <section className="social-contacts-section">
        <h1 className="common-title">Contact information</h1>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <a href="https://www.instagram.com/beautista.connect">
              <img src={IconInstagram} alt="" />
            </a>
          </Grid>
          <Grid item xs={12} sm={4}>
            <a href="https://www.facebook.com/beautista.connect">
              <img src={IconFB} alt="" />
            </a>
          </Grid>
          <Grid item xs={12} sm={4}>
            <a href="mailto:hello@beautista.com">
              <img src={IconEmail} alt="" />
              hello@beautista.com
            </a>
          </Grid>
        </Grid>
      </section>
    );
  }

  private form() {
    const { getFieldDecorator } = this.props.form;
    const options = [
      { title: "Advertiser" },
      { title: "Business Owner" },
      { title: "Beauty Professional" },
      { title: "Beauty Student" },
      { title: "Cosmetology School" },
      { title: "Beauty Org." },
      { title: "Student" },
    ];

    return (
      <>
        <h1 className="common-title">Send us a message</h1>
        <Form className="validate-form" onSubmit={this.onSubmit}>
          <Row gutter={24}>
            <Col>
              <Form.Item>
                {getFieldDecorator("name", {
                  rules: [
                    { required: true, message: "Please tell us your name" },
                  ],
                  validateTrigger: "onBlur",
                })(
                  <TextField
                    label="Your name"
                    variant="filled"
                    className="material-input"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>
              <Form.Item>
                {getFieldDecorator("type", {
                  rules: [{ required: false }],
                })(
                  <Autocomplete
                    className="tag-input program-input"
                    options={options}
                    freeSolo
                    getOptionLabel={(option) => {
                      return option.title;
                    }}
                    renderInput={(params: any) => {
                      const props: any = Object.assign({}, params);
                      delete props.value;
                      return (
                        <TextField
                          {...props}
                          label="Your occupation"
                          variant="filled"
                          className="material-input"
                        />
                      );
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>
              <Form.Item>
                {getFieldDecorator("email", {
                  rules: [
                    { required: true, message: "Please tell us your email" },
                  ],
                  validateTrigger: "onBlur",
                })(
                  <TextField
                    label="Your email"
                    variant="filled"
                    className="material-input"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>
              <Form.Item>
                {getFieldDecorator("phone", {
                  rules: [{ required: false }],
                  validateTrigger: "onBlur",
                })(
                  <TextField
                    label="Your phone number"
                    variant="filled"
                    className="material-input"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>
              <Form.Item>
                {getFieldDecorator("message", {
                  rules: [
                    { required: true, message: "Please enter your message" },
                  ],
                  validateTrigger: "onBlur",
                })(
                  <TextField
                    multiline={true}
                    label="Your message"
                    variant="filled"
                    className="material-input"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>
              <Button htmlType="submit" className="btn-primary">
                Send
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  private postSubmit() {
    return (
      <section className="thank-you-section">
        <img src={IconCheck} alt="" />

        <h1 className="common-title">Your message is submitted</h1>

        <div>
          Thank you for contactting us. We will get
          <br />
          back to you soon.
        </div>
      </section>
    );
  }

  private onSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const { name, email, phone, message, occupation } = values;

        const body: string = `
          Name: ${name}\n<br />
          Email: ${email}\n<br />
          ${phone ? `Phone: ${phone}\n<br />` : ""}
          ${occupation ? `Occupation: ${occupation}\n<br />` : ""}
          \n
          <p>${message}</p>
        `;

        await sendContactEmail(email, body);

        this.setState({
          submitted: true,
        });
      }
    });
  }
}

export default Form.create()(HomepageContact);
