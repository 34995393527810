import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import { Provider as ModuleReactionProvider, reaction, enableDevtools, regStore, MODULE_COMMON } from "module-reaction";
import { Amplify } from "aws-amplify";
import RouterGuard from "./components/component_route_guard";
import ScrollToTop from "./components/component_scroll_to_top";
import { AWS_REGION, AWS_BUCKET } from "./utils/constants";
import "./index.less";
import "./index.styl";
import { global_router } from "./routers";
import { localStorageGet, localStorageSet } from "./utils/utils";
import { clearUser } from "./utils/authStorage";
import { router_sign_in, router_get_started } from "./utils/enum";
import { Provider } from 'react-redux';
import store from './utils/store';
import ReloginModal from "./components/component_relogin";
import TagManager from 'react-gtm-module';

TagManager.initialize({
  gtmId: 'GTM-NM7PJRB',
  dataLayer: {
    user_id: localStorageGet('user_id')
  }
});

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: AWS_REGION, // REQUIRED - Amazon Cognito Region
  },
  // AWS_REGION should match user location
  Storage: {
    AWSS3: {
      bucket: AWS_BUCKET, //REQUIRED -  Amazon S3 bucket
      region: AWS_REGION,
    },
  },
});

const GTMPageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    try {
      const user_id = localStorageGet('user_id');
      const page_location = location.pathname + location.search;
      const referrerUrl = document.referrer ? new URL(document.referrer) : null;
      const page_referrer = referrerUrl ? referrerUrl.pathname + referrerUrl.search : localStorageGet('lastPageReferrer') || '';
      
      localStorageSet('lastPageReferrer', page_location);

      TagManager.dataLayer({
        dataLayer: {
          event: 'page_view',
          user_id,
          page_location,
          page_referrer
        }
      });
    } catch (e) {
      console.log('Did not update GTM User Id', e);
    }
  }, [location]);

  return null;
};

export const App: React.FC = (props, context) => (
  <Router
    ref={(e) => {
      if (!global_router.history) {
        global_router.history = (e as any).history;
        // check invite code
        if (window.location.search.includes("action=invite")) {
          const query = new URL(window.location.href);
          const invitationCode = query.searchParams.get("code");
          const isReg = query.searchParams.get("isRegistered");
          localStorageSet("invitationCode", invitationCode);
          //clear user so skip login with old token
          clearUser();
          //redirect to login
          if (isReg === "true") {
            const type = query.searchParams.get("type");
            const slug = query.searchParams.get("slug");
            window.location.href = `${window.location.origin
              }${router_sign_in}?isInvite=1${type ? `&type=${type}` : ""}${slug ? `&slug=${slug}` : ""
              }`;
          } else {
            window.location.href = `${window.location.origin}${router_get_started}`;
          }
        }

        // GrowSurf integration
        const win = window as any;
        win.grsfEnabled = false;
        if (win.grsfEnabled) {
          const urlParams = new URLSearchParams(window.location.search);
          const grsfParam = urlParams.get('grsf');
          win.grsfParam = grsfParam;
        }
      }
    }}
  >
    {/* <Prompt message={(location) => {
      const logined = getModuleProp(MODULE_HOME, 'logined')
      if (!logined && router_need_auth(location.pathname)) {
        // show login modal
        doAction(ShowHideLoginAction, true)
        return false
      }
      return true
    }} /> */}
    <ScrollToTop>
      <GTMPageViewTracker />
      <Switch>
        {<RouterGuard />}
        {/* {appRouters.map((_) => (
        <Route  key={_.path} {..._}/>
      ))} */}
      </Switch>
    </ScrollToTop>
    <ReloginModal />
  </Router>
);

regStore({ module: MODULE_COMMON })
reaction.defaultMaxProcessSeconds = 16

if (process.env.NODE_ENV === "development") {
  enableDevtools();
}
ReactDOM.render(
  <Provider store={store}>
    <ModuleReactionProvider>
      <App />
    </ModuleReactionProvider>
  </Provider>,
  document.getElementById("app")
);
