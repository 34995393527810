import React, { useState, useEffect, FormEvent } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Modal, Button, Divider, Spin } from "antd";
import noop from "lodash/noop";
import { getClientSec } from "../api/Pay";

const errorColor = "#fa755a";

interface BillingDetail {
  name?: string;
  email?: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
}

interface ModalProps {
  visible?: boolean;
  onClose?: () => void;
  afterPay?: (paymentMethodId: string) => void;
  billingDetail?: BillingDetail;
  stripeKey?: string;
  clientSec?: string;
  isCreditCard?: boolean;
}

function AchCcForm({
  afterPay = noop,
  onClose = noop,
  clientSec = "",
  billingDetail = {},
  isCreditCard = false,
}: {
  afterPay: (paymentMethodId: string) => void;
  onClose: () => void;
  clientSec: string;
  billingDetail: BillingDetail;
  isCreditCard: boolean;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    name = "",
    email = "",
    address = "",
    city = "",
    state = "",
    country = "",
    zip = "",
  } = billingDetail;

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (!stripe || !elements) return;
    setLoading(true);
    setErrorMsg("");

    const result = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: window.location.href,
        payment_method_data: {
          billing_details: {
            address: {
              line1: address,
              city,
              state,
              country,
              postal_code: zip,
            },
            name,
            email,
          },
        },
      },
    });

    if (result.error) {
      setErrorMsg(result.error.message || "Something went wrong");
      setLoading(false);
      return;
    }

    const paymentMethodId = result.setupIntent?.payment_method ?? "";
    afterPay(paymentMethodId as string);
    setLoading(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {errorMsg && <div style={{ marginTop: 20, color: errorColor }}>{errorMsg}</div>}
      <Divider />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginLeft: 10 }}
          loading={loading}
          disabled={!stripe || !elements || loading}
        >
          Save
        </Button>
      </div>
    </form>
  );
}

export default function StripeModal({
  visible = false,
  onClose = noop,
  afterPay = noop,
  billingDetail = {},
  stripeKey = "",
  clientSec = "",
  isCreditCard = null,
}: ModalProps) {
  const [internalClientSecret, setInternalClientSecret] = useState(clientSec);
  const [fetchingSec, setFetchingSec] = useState(false);

  const stripePromise = stripeKey ? loadStripe(stripeKey) : null;

  const appearance = {
    theme: "stripe" as const,
    variables: {
      colorPrimary: "#0c141c",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
    },
  };

  // Automatically fetch a new client_secret for new users
  // if none was provided and the modal is visible
  useEffect(() => {
    async function fetchClientSecret() {
      setFetchingSec(true);
      try {
        console.log(isCreditCard)
        const res: any = await getClientSec(isCreditCard);
        setInternalClientSecret(res.secret);
      } catch (err) {
        console.error("Error fetching client secret:", err);
      } finally {
        setFetchingSec(false);
      }
    }

    if (!internalClientSecret && stripeKey && visible) {
      fetchClientSecret();
    }
  }, [internalClientSecret, stripeKey, visible]);

  const isReady = stripePromise && internalClientSecret;
  const showSpinner = !isReady || fetchingSec;

  return (
    <Modal
      footer={null}
      title={isCreditCard === null ? "Add Credit Card or Bank Account" : isCreditCard ? "Add Credit Card" : "Add Bank Account"}
      visible={visible}
      onCancel={onClose}
      centered
    >
      {!stripeKey && (
        <div style={{ textAlign: "center", padding: 40 }}>
          There is an issue. Please refresh page and try again.Please contact support if this issue persists and you need to add a credit card or bank account.
        </div>
      )}

      {stripeKey && showSpinner && (
        <div style={{ textAlign: "center", padding: 40, height: 200 }}>
          <Spin />
          <div style={{ marginTop: 20 }}>Loading payment info...</div>
        </div>
      )}

      {stripeKey && isReady && !showSpinner && (
        <Elements stripe={stripePromise!} options={{ clientSecret: internalClientSecret, appearance }}>
          <AchCcForm
            clientSec={internalClientSecret}
            afterPay={afterPay}
            onClose={onClose}
            billingDetail={billingDetail}
            isCreditCard={isCreditCard}
          />
        </Elements>
      )}
    </Modal>
  );
}
