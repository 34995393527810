import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Button,
  Input,
  Avatar,
  Menu,
  Icon,
  Dropdown,
  Modal,
  message,
} from "antd";
import { TextField, MenuItem } from "@material-ui/core";
import _ from "lodash";
import {
  ListTable,
  IHeadItem,
  IBodyItem,
} from "../../../components/component_list_table";
import "./user.less";
import IconMessage from "../../../assets/images/ic_message_green.svg";
import IconMessageBlack from "../../../assets/images/ic_message_black.svg";
import IconPrint from "../../../assets/images/ic_link.svg";
import IconGroupChat from "../../../assets/images/ic_group_msg.svg";
import IconBlastChat from "../../../assets/images/ic_blast_msg.svg";
import IconSuccess from "../../../assets/images/ic_check.svg";
import IconDelete from "../../../assets/images/ic_delete.svg";
import ic_export from "../../../assets/images/ic_export.svg";

import { sendMsgEmail } from "../../../api/Common";
import { userList, getAccessAccount, delUser } from "../../../api/admin";

import { mapProp, doAction } from "module-reaction";
import { modle_user, LoginWithTokenAction } from "../../../models/model_user";
import {
  module_home,
  setUserLoginInfoAction,
} from "../../../models/model_home";
import {
  createChat,
  chatClient,
  createChannel,
  createBlastChat,
} from "../../../utils/chatHelper";
import { router_message } from "../../../utils/enum";
import ChannelNameModal from "../../../components/component_channel_name_modal";
import CommonLoadingModal from "../../../components/component_common_loading_modal";
import { getUserInfo } from "../../../api/Auth";
import { setToken } from "../../../utils/authStorage";

const typeList: any[] = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Student",
    value: "student",
  },
  {
    label: "Professional",
    value: "professional",
  },
  {
    label: "Business",
    value: "business",
  },
  {
    label: "School",
    value: "school",
  },
];

const msgTypeList: any = {
  msg: "msg_to_student",
  notify: "notify_update_canvas",
};

const AdminUser: React.FC<Props> = (props: Props) => {
  const { curUser, history } = props;
  const tHeadRender: IHeadItem[] = [
    { name: "User name", isSort: true, key: "user_name" },
    { name: "Email", key: "email" },
    { name: "Phone", key: "phone" },
    { name: "Created on", key: "createdOn" },
    { name: "Canvas type" },
    { name: "Actions" },
  ];

  const tBodyRender: IBodyItem[] = [
    {
      type: "avatar",
      component: (row: any) => (
        // <div className="avatar-name-inner">
        <>
          <Avatar
            icon="user"
            src={row && row.avatar}
            className="avatar"
            style={{ marginRight: "8px" }}
          />
          {row.userName}
          </>
        // </div>
      ),
      className: "avatar-name",
    },
    { name: "Email", key: "email" },
    { name: "Phone", key: "phone" },
    { name: "Created on", key: "createdOn", type: "date" },
    { name: "Canvas type", key: "canvasType", className: "capitalize" },
    {
      type: "dropdown-actions",
      dropdownList: [
        {
          icon: IconPrint,
          name: "Access account",
          onClick: (item: any, index: number) => changeAuthority(item),
        },
        {
          icon: IconMessageBlack,
          name: "Message",
          onClick: (item: any, index: number) => {
            startChat(item.id);
          },
        },
        {
          icon: IconDelete,
          name: "Delete account",
          onClick: (item: any, index: number) => {
            setShowDeleteConfirm(item)
          },
        },
      ],
    },
  ];

  const size = 8;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [dataList, setDatalist] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectList, setSelectList] = useState([] as any);
  const [orderBy, setOrderBy] = useState("");

  const [search, setSearch] = useState({
    canvas_type: "all",
    name: "",
  });

  const [showChannelModal, setShowChannelModal] = useState(false);
  const [visibleMsgModal, setVisibleMsgModal] = useState(false);
  const [visibleSuccessModal, setVisibleSuccessModal] = useState(false);
  const [msgType] = useState("msg");
  const [msgList, setMsgList] = useState([]);
  const [msgContent, setMsgContent] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<any>(undefined);

  const deleteAccount = (id: number) => {
    delUser(id).then((res: any) => {
      res.success && message.info('Delete user success!')
      list(page)
    })
  }

  const list = (page = 1) => {
    let _data = {
      canvas_type: search.canvas_type === "all" ? null : search.canvas_type,
      name: search.name || null,
      size,
      page: page - 1,
      sort_by: orderBy || null,
    };
    setLoading(true);
    userList(_data).then((data) => {
      setDatalist(data.rows);
      setTotal(data.total);
      setLoading(false);
    });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(1);
    list(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    list(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy]);

  //link normal account - access account
  const changeAuthority = (item: any) => {
    console.log();
    let _data = {
      id: item.id,
    };
    getAccessAccount(_data).then(async (data: any) => {
      const res = await getUserInfo();
      if (res && res.user) {
        const user = res.user
        // switch to user account
        sessionStorage.setItem('canvas:local:adminpath', props.location.pathname)
        doAction(LoginWithTokenAction);
        doAction(setUserLoginInfoAction, { res: data, history: props.history });
      }
    });
  };

  //message start
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <span
          className="msg-menu-item"
          onClick={() => setShowChannelModal(true)}
        >
          <img alt="" src={IconGroupChat} />
          Group message
        </span>
      </Menu.Item>
      <Menu.Item key="1">
        <span className="msg-menu-item" onClick={() => createBlastMsg()}>
          <img alt="" src={IconBlastChat} />
          Blast message
        </span>
      </Menu.Item>
    </Menu>
  );
  const createBlastMsg = async () => {
    if (_.isEmpty(selectList) || !curUser || !curUser.id) return;
    setShowLoading(true);
    const memberList = [...selectList];
    await createBlastChat(memberList, curUser.id);
    setShowLoading(false);
    history.push("/message");
  };

  const createGroupChat = async (channelName: string) => {
    if (
      _.isEmpty(selectList) ||
      !curUser ||
      !curUser.id ||
      !channelName
      // !school.id
    )
      return;
    const memberList = [...selectList, curUser.id];
    setShowLoading(true);
    const channelId = await createChannel({
      members: memberList,
      channelName: channelName,
      // canvasId: school.id,
    });
    setShowLoading(false);
    history.push(router_message, { channelId: channelId });
  };

  // const sendMessage = (userId?: any) => {
  //   setMsgList(userId ? [userId] : selectList);
  //   setVisibleMsgModal(true);
  // };

  const sendMsg = () => {
    // if (!school.id) return;
    if (!msgContent) {
      message.info("please input your message");
      return;
    }
    const params = {
      msg: msgContent,
      template: msgTypeList[msgType],
      users: msgList,
      extra: {
        // canvas_id: school.id,
        // address: string,
      },
    };
    sendMsgEmail(params).then(() => {
      onMsgClose();
      setVisibleSuccessModal(true);
    });
  };

  const startChat = async (userId: any) => {
    if (!userId || !curUser || !curUser.id || !chatClient) return;
    setShowLoading(true);
    const channelId = await createChat([curUser.id, userId]);
    setShowLoading(false);
    history.push(router_message, { channelId: channelId });
  };

  const onMsgClose = () => {
    setVisibleMsgModal(false);
    setMsgContent("");
    setMsgList([]);
  };

  const msgText: any = {
    msg: {
      title: "Send Message",
      intro1: "You are sending your student(s) a message via email. ",
      intro2: (
        <>
          Clicking on <strong>Send My Message</strong> will initiate a email
          that goes out to all selected students.
        </>
      ),
    },
    notify: {
      title: "Gentle reminder to update your canvas",
      intro1: "You are sending this notification to <username> via email. ",
      intro2: (
        <>
          Clicking on <strong>Send My Message</strong> will initiate an email to
          this user.
        </>
      ),
    },
  };

  const getMsgIntro = () => {
    if (msgType === "notify") {
      const currentUser = _.find(dataList, { id: msgList[0] });
      const username =
        _.get(currentUser, "firstName") +
          " " +
          _.get(currentUser, "lastName") || "";
      return msgText[msgType].intro1.replace("<username>", username);
    } else {
      return msgText[msgType].intro1;
    }
  };

  const handleExport = () => {
    window.open(process.env.REACT_APP_BASE_URL + `/admin/users/export-to-csv/all?canvas_type=${search.canvas_type || 'all'}`)
  }
  //message end
  return (
    <div className="admin-user-container">
      <div className="title">
        My Users
        <span className="title-btn"></span>
        <Button className="item filter-btn" onClick={handleExport}>
          <img src={ic_export} alt="filter" />
              Export csv
        </Button>
      </div>
      <div className="list-wrapper">
        <div className="search-item">
          <TextField
            label="Canvas Type"
            variant="filled"
            select
            className="material-input input"
            value={search.canvas_type}
            onChange={(e) =>
              setSearch({
                ...search,
                canvas_type: e.target.value,
              })
            }
          >
            {typeList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <Input
            placeholder="Search by Name, Email or Phone"
            prefix={<Icon type="search" />}
            value={search.name}
            onChange={(e) =>
              setSearch({
                ...search,
                name: e.target.value,
              })
            }
          />
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            overlayClassName="chat-pop"
            disabled={!selectList.length}
          >
            <Button
              className="btn-secondary"
              disabled={!selectList.length}
              onClick={() => {}}
            >
              <img src={IconMessage} alt="" />
              Message
              <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
        <div className="list-item">
          <ListTable
            tHeadRender={tHeadRender}
            tBodyRender={tBodyRender}
            loading={loading}
            hasSelect={true}
            idKey="id"
            dataList={dataList}
            selectList={selectList}
            selectOnChange={setSelectList}
            total={total}
            size={8}
            page={page}
            pageOnChange={handleChangePage}
            orderBy={orderBy}
            orderByOnChange={(value: string) => setOrderBy(value)}
          />
        </div>
      </div>
      <Modal
        footer={null}
        closable={false}
        onCancel={onMsgClose}
        visible={visibleMsgModal}
        centered={true}
        width="80%"
      >
        <div className="send-msg-modal">
          <div className="common-title">{msgText[msgType].title}</div>
          <div className="modal-text">{getMsgIntro()}</div>
          <TextField
            label="Message goes here"
            placeholder="Message goes here"
            multiline
            rows={4}
            variant="filled"
            className="material-input"
            value={msgContent}
            onChange={(event) => setMsgContent(event.target.value)}
          />
          <div className="modal-text">{msgText[msgType].intro2}</div>
          <div className="btn-wrap">
            <Button type="primary" onClick={sendMsg}>
              Send Message
            </Button>
            <Button onClick={onMsgClose}>Cancel</Button>
          </div>
        </div>
      </Modal>
      <Modal
        footer={null}
        onCancel={() => setVisibleSuccessModal(false)}
        visible={visibleSuccessModal}
        centered={true}
        width={400}
      >
        <div className="comfirm-modal">
          <div className="common-title">Message sent!</div>
          <div className="modal-text">
            Woohoo! Your message was sent successfully.
          </div>
          <img src={IconSuccess} alt="success" />
          <div className="btn-wrap">
            <Button
              type="primary"
              onClick={() => setVisibleSuccessModal(false)}
            >
              Back to My Students
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        footer={null}
        onCancel={() => setShowDeleteConfirm(null)}
        visible={!!showDeleteConfirm}
        centered={true}
        width={400}
      >
        <div className="comfirm-modal">
          <div className="common-title">Delete account</div>
          <div className="modal-text">
            Are you sure to delete this account: {showDeleteConfirm?.userName || ''} ?
          </div>
          <div className="btn-wrap">
            <Button
              type="primary"
              onClick={() => {
                deleteAccount(showDeleteConfirm?.id)
                setShowDeleteConfirm(null)
              }}
            >
              Yes
            </Button>
            <Button
              className="btn-secondary"
              onClick={() => setShowDeleteConfirm(null)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      {showChannelModal && (
        <ChannelNameModal
          visible
          count={selectList.length + 1}
          onClose={() => setShowChannelModal(false)}
          onConfirm={(value: string) => {
            setShowChannelModal(false);
            createGroupChat(value);
          }}
        />
      )}
      <CommonLoadingModal visible={showLoading} />
    </div>
  );
};

interface Props extends RouteComponentProps {
  history: any;
  curUser: any;
}

export default mapProp(modle_user)(mapProp(module_home)(AdminUser));
