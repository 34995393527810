import { ModuleStore, ModuleAction } from "module-reaction";
import _ from "lodash";
import { emailValidator, numberValidator } from "../utils/validator";
import { ISelectOptionItem } from "../components/component_validate_form";
import { getPayPrices, getPaymentInfo, applyPromotionCode } from "../api/Pay";
import { getCustomized } from "../api/Options";
import { countryOptions } from "../utils/constants";
import { CouponType } from "../utils/enum";
import { STRIPE_PRICE_ID_EXCLUSIONS } from "../utils/constants";
export const MODULE_BUSINESS_PAYMENT = "module_busines_payment";

export interface IBillingInfo {
  name: string;
  email: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

export const billingValue: IBillingInfo = {
  name: "",
  email: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zip: "",
};

export interface IContactInfo {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export const contactInfoValue: IContactInfo = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
};

export const billingFormElement = (isMobile: boolean, stateOptions: any[]) => [
  {
    name: "name",
    label: "Name",
    colSpan: isMobile ? 24 : 12,
    required: true,
  },
  {
    name: "email",
    label: "Email",
    colSpan: isMobile ? 24 : 12,
    required: true,
    rule: [emailValidator],
  },
  {
    name: "address",
    label: "Address",
    colSpan: isMobile ? 24 : 12,
    required: true,
  },
  {
    name: "city",
    label: "City",
    colSpan: isMobile ? 24 : 12,
    required: true,
  },
  {
    type: "select",
    name: "state",
    label: "State",
    colSpan: isMobile ? 12 : 6,
    required: true,
    options: stateOptions,
  },
  {
    name: "zip",
    label: "Zip code",
    colSpan: isMobile ? 12 : 6,
    required: true,
    rule: [numberValidator],
  },
  {
    type: "select",
    name: "country",
    label: "Country",
    colSpan: isMobile ? 24 : 12,
    required: true,
    options: countryOptions,
  },
];

export const contactFormElement = (isMobile: boolean) => [
  {
    name: "firstName",
    label: "First name",
    colSpan: isMobile ? 24 : 12,
    required: true,
  },
  {
    name: "lastName",
    label: "Last name",
    colSpan: isMobile ? 24 : 12,
    required: true,
  },
  {
    name: "phoneNumber",
    label: "Phone number",
    colSpan: isMobile ? 24 : 12,
    required: true,
  },
  {
    name: "email",
    label: "Email address",
    colSpan: isMobile ? 24 : 12,
    required: true,
    rule: [emailValidator],
  },
];

export interface ModuleBusinessPayment extends ModuleStore {
  stripeId: string;
  priceOptions: any[];
  billingFormElement: any;
  billingValue: IBillingInfo;
  contactFormElement: any;
  contactInfoValue: IContactInfo;
  stateOptions: ISelectOptionItem[];
  promotionCode: string;
  promotionResult: any;
  agreedToTerms: boolean;
}

export const module_busines_payment: ModuleBusinessPayment = {
  module: MODULE_BUSINESS_PAYMENT,
  stripeId: "",
  priceOptions: [],
  billingFormElement,
  billingValue,
  contactFormElement,
  contactInfoValue,
  stateOptions: [],
  promotionCode: "",
  promotionResult: {
    type: -1,
    value: ""
  },
  agreedToTerms: false,
};

export const setPromotionAction: ModuleAction<any, ModuleBusinessPayment> = {
  module: MODULE_BUSINESS_PAYMENT,
  process: async (parameter: object) => {
    return { ...parameter };
  },
};

export const applyPromotionCodeAction: ModuleAction<
  any,
  ModuleBusinessPayment
> = {
  module: MODULE_BUSINESS_PAYMENT,
  process: async (params: any, module: ModuleBusinessPayment) => {
    const { isCreditCard } = params
    const coupon = module.promotionCode;
    if (coupon.length) {
      const res: any = await applyPromotionCode({ coupon, isCreditCard }).catch(
        (error) => null
      );
      if (res) {
        const { amount_off, percent_off } = res;
        if (amount_off || percent_off) {
          const type = amount_off
            ? CouponType.AMOUNT_OFF
            : CouponType.PERCENT_OFF;
          return {
            promotionResult: {
              type,
              value: amount_off || percent_off,
            },
          };
        }
      } else {
        return {
          promotionResult: {
            type: CouponType.INVALID,
            value: "",
          },
        };
      }
    }
    return {};
  },
};

export const GetPayInfoAction: ModuleAction<any, ModuleBusinessPayment> = {
  module: MODULE_BUSINESS_PAYMENT,
  process: async (type) => {
    const promiseArr = [
      getPayPrices(type),
      getPaymentInfo(),
      getCustomized(true, ["state"]),
    ];
    const resArr = (await Promise.all(promiseArr)) || [];
    if (resArr.length) {
      let priceOptions: any = resArr[0] || [];
      priceOptions = priceOptions.filter(p => !STRIPE_PRICE_ID_EXCLUSIONS.includes(p.price_id))
      const paymentInfo: any = resArr[1] || {};
      const stateOptions = _.get(resArr[2], "state") || [];
      priceOptions.push({ job_count: 26 });
      let stripeId = false,
        isCreditCard = false,
        billingData = _.cloneDeep(billingValue);
      if (paymentInfo.stripeId) {
        stripeId = paymentInfo.stripeId;
        const { billing_details } = paymentInfo;
        isCreditCard = paymentInfo.type === 'card';
        billingData = {
          name: _.get(billing_details, "name"),
          email: _.get(billing_details, "email"),
          address: _.get(billing_details, "address.line1"),
          city: _.get(billing_details, "address.city"),
          state: _.get(billing_details, "address.state"),
          country: _.get(billing_details, "address.country"),
          zip: _.get(billing_details, "address.postal_code"),
        };
      }
      return {
        isExistingPaymentMethodCard: isCreditCard,
        existingPaymentMethod: paymentInfo?.payment_method,
        stripeId,
        priceOptions,
        stateOptions,
        billingValue: billingData,
      };
    }
    return {};
  },
};
