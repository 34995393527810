
const domain = window.location.hostname === 'localhost'
  ? 'localhost'
  : '.beautista.com';

export const buildPromoStorageKey = (hostname: string) => {
  if (hostname === 'localhost') {
    return 'canvas:promo:local';
  } 
  if (hostname.includes('staging')) {
    return 'canvas:promo:staging';
  }
  return 'canvas:promo';
};

const setSharedCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;

  document.cookie = `${name}=${value}; ${expires}; path=/; domain=${domain}; Secure; SameSite=None`;
};

export const setPromoCode = (promoCode: string): void => {
  setSharedCookie(buildPromoStorageKey(window.location.hostname), promoCode, 1);
};

export const clearPromoCode = (): void => {
  document.cookie = `${buildPromoStorageKey(window.location.hostname)}=; 
    expires=Thu, 01 Jan 1970 00:00:00 UTC; 
    path=/; 
    domain=${domain}; 
    Secure; 
    SameSite=None`;
};

export const getPromoCode = (): string | null => {
  const promoCookie = document.cookie
    .split('; ')
    .find((row) =>
      row.startsWith(`${buildPromoStorageKey(window.location.hostname)}=`)
    );
  
  if (promoCookie) {
    const code = promoCookie.split('=')[1];
    return code;
  }

  return null;
};
