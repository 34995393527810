import React from "react";
import { Button, Modal } from "antd";
import EducationBackground from "./component_education_background";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

interface EditEducationModalProps {
  visible: boolean;
  onClose: () => void;
  onSave: (edu: any) => void;
  data: any;
  programList: any[];
  isEdit?: boolean;
  onSearchSchool: (keywords: string) => void;
  schoolOptions: any[];
}

export const EditEducationModal: React.FC<EditEducationModalProps> = ({
  visible,
  onClose,
  onSave,
  data,
  programList,
  isEdit = false,
  onSearchSchool,
  schoolOptions
}) => {
  const [schoolName, setSchoolName] = React.useState(data.schoolName || "");
  const [tempEdu, setTempEdu] = React.useState<any>({ ...data });
  const [errorMsg, setErrorMsg] = React.useState("");

  React.useEffect(() => {
    setSchoolName(data.schoolName || "");
    setTempEdu({ ...data });
    setErrorMsg("");
  }, [data]);

  const handleEduFieldChange = (value: any, key: string) => {
    setTempEdu((prev: any) => ({ ...prev, [key]: value }));
  };

  const handleSave = () => {
    if (!schoolName || !tempEdu.graduationDate) {
      setErrorMsg("School Name and Graduation Date are required.");
      return;
    }
    // Merge final
    const newEdu = {
      ...tempEdu,
      schoolName
    };
    onSave(newEdu);
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={onClose}
      closable={false}
      centered
    >
      <div className="edit-education-modal" style={{ padding: 20 }}>
        <h3>{isEdit ? "Edit Education" : "Add Education"}</h3>

        {errorMsg && (
          <div style={{ color: "red", marginBottom: 8 }}>{errorMsg}</div>
        )}

        <Autocomplete
          freeSolo
          className="material-input"
          options={schoolOptions}
          value={schoolName}
          getOptionLabel={(option: any) => option.name || option}
          onChange={(event: object, value: any) => {
            if (typeof value === "string") {
              setSchoolName(value);
            } else if (value && value.name) {
              setSchoolName(value.name);
            }
          }}
          onInputChange={(event: object, value: string) => {
            setSchoolName(value);
            onSearchSchool(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="School Name"
              variant="filled"
              className="material-input"
            />
          )}
        />

        <EducationBackground
          data={tempEdu}
          number={0}
          onEdit={handleEduFieldChange}
          programList={programList}
        />

        <div style={{ textAlign: "right", marginTop: 20 }}>
          <Button onClick={onClose} style={{ marginRight: 10 }}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSave}>
            {isEdit ? "Save" : "Add"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
