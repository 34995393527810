import Joi from "@hapi/joi";

export const checkBookingLink = (link: string) => {
  const isUrl = (url: string) => {
    const urlPattern = /^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})([\/\w .-]*[\?&=%]*)*\/?$/;
    return urlPattern.test(url);
  };
  
  const isPhoneNumber = (phoneNumber: string) => {
    const phonePattern = /^\+?[0-9()\-\s]+$/;
    return phonePattern.test(phoneNumber);
  };
  
  const isEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  
  const isInstagramHandle = (handle: string) => {
    const instagramPattern = /^@[a-zA-Z0-9_]+$/;
    return instagramPattern.test(handle);
  };

  const addLinkProtocol = (url: string) => {
    if (url && !/^https?:\/\//i.test(url)) {
      return 'http://' + url;
    }
    return url;
  };
  
  const formatLink = (link: string) => {
    console.log('formatLink ',link)
    if (isUrl(link)) {
      return addLinkProtocol(link);
    } else if (isPhoneNumber(link)) {
      return 'tel:' + link.replace(/[^\d+]/g, '');
    } else if (isEmail(link)) {
      return 'mailto:' + link;
    } else if (isInstagramHandle(link)) {
      return 'https://www.instagram.com/' + link;
    } else {
      return null;
    }
  };

  return formatLink(link);
}

const emailSchema = Joi.object({
  email: Joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: false },
  }),
});
const passwordSchema = Joi.object({
  password: Joi.string().regex(/^(?=.*[a-z_])(?=.*[A-Z_])(?=.*[0-9_])(?=.*[!-\/:-@[-`{-~_]).{8,}$/),
});

const numberSchema = Joi.object({
  number: Joi.string().pattern(new RegExp("^\\d+(\\.{0,1}\\d+){0,1}$")),
});

const groupNameSchema = Joi.object({
  groupName: Joi.string().pattern(new RegExp("^[a-z0-9A-Z-_]+$")),
});

export const isEmailValid = (email: string) => {
  const result = emailSchema.validate({ email: email });
  return result.error ? false : true;
};

export const emailValidator = {
  validator: (rule: any, value: string, callback: any) => {
    if (value && !isEmailValid(value)) {
      callback("Please input your email address correctly");
    } else {
      callback();
    }
  },
};

export const isPasswordValid = (password: string) => {
  const result = passwordSchema.validate({ password: password });
  return result.error ? false : true;
};

export const isNumberValid = (number: string) => {
  const result = numberSchema.validate({ number: number });
  return result.error ? false : true;
};

export const numberValidator = {
  validator: (rule: any, value: string, callback: any) => {
    if (value && !isNumberValid(value)) {
      callback("Please input number only");
    } else {
      callback();
    }
  },
};

export const isGroupNameValid = (name: string) => {
  const result = groupNameSchema.validate({ groupName: name });
  return result.error ? false : true;
};
