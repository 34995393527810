import React, { useState, useEffect, useRef } from "react";
import { TextField, MenuItem, CircularProgress } from "@material-ui/core";
import { Button, Breadcrumb, Input, Row, Col, Avatar, Modal, message, Select, Spin } from "antd";
import { Link } from "react-router-dom";
import queryString from "query-string";
import _ from "lodash";
import IconMsg from "../../../assets/images/ic_green_msg.svg";
import IconDisabledMsg from "../../../assets/images/ic_msg_disabled.svg";
import IconNoStudent from "../../../assets/images/ic_no_student.svg";
import IconSuccess from "../../../assets/images/ic_check.svg";
import IconMessageBlack from "../../../assets/images/ic_message_black.svg";
import IconLink from "../../../assets/images/ic_link.svg";
import IconView from "../../../assets/images/ic_view.svg";
import {
  router_opportunity_detail,
  router_my_dashboard,
  router_message,
} from "../../../utils/enum";
import { StatusDropdown } from "../../../components/component_status_dropdown";
import { ListTable, IHeadItem, IBodyItem } from "../../../components/component_list_table";
import { getOppDtls, opportunityApplicants, changeApplicantStatus } from "../../../api/Opportunity";
import { sendMsgEmail } from "../../../api/Common";
import { copyToClipboard } from "../../../utils/utils";
import "./opportunity_applicant.less";
import { chatClient, createBlastChat, createChat } from "../../../utils/chatHelper";
import { global_router } from "../../../routers";
import { getModuleProp } from "module-reaction";
import { MODULE_USER } from "../../../models/model_user";
import { CandidateList } from "../../../utils/constants";
const { Search } = Input;

interface OpportunityApplicantsProps {
  match: any;
  history: any;
}

export const OpportunityApplicants: React.FC<OpportunityApplicantsProps> = (props) => {
  const { history } = props;
  const [filter, setFilter] = useState({
    status: "All",
    keyword: "",
  });
  const [competition, setCompetition] = useState(false);
  const [applicantsList, setApplicantsList] = useState<any>({});
  const [statistics, setStatistics] = useState<any>({});
  const [curPage, setCurPage] = useState(1);
  const [selectedList, setSelectedList] = useState<any[]>([]);
  const [messageDisabled, setMessageDisabled] = useState(true);
  const [visibleMsgModal, setVisibleMsgModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msgContent, setMsgContent] = useState("");
  const [userIdList, setUserIdList] = useState<any[]>([]);
  const [visibleSuccessModal, setVisibleSuccessModal] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [oppSlug, setOppSlug] = useState("");
  const [tableData, setTableData] = useState<any[]>([]);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [pageSize, setPageSize] = useState(25);
  const opportunityId = Number(queryString.parse(window.location.search).id);
  const isMobile = window.innerWidth <= 1024;
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (opportunityId) {
      getApplicantsList();
    }
  }, [opportunityId, orderBy, pageSize]);

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
          loadMoreItems();
        }
      }
    };

    if (listRef.current) {
      listRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading]);

  const getApplicantsList = async (filter: any = null, page = 1, append?: boolean) => {
    setLoading(true);
    const oppDtl = await getOppDtls(opportunityId);
    if (oppDtl) {
      setJobTitle(oppDtl.jobTitle.name);
      setOppSlug(oppDtl.slug);
      setCompetition(oppDtl.competition);
    }
    const params = {
      id: opportunityId,
      keyword: "",
      page,
      size: pageSize,
      orderBy: orderBy,
    };
    if (filter) {
      const { status, keyword } = filter;
      Object.assign(params, { keyword });
      if (status !== "All") {
        Object.assign(params, { status });
      }
    }
    const res = await opportunityApplicants(params);
    if (res) {
      const { applicants, statistics } = res;
      if (applicants.list) {
        setStatistics(statistics);
        setApplicantsList(applicants);
        const list = getTableData(applicants);
        setTableData(append ? tableData.concat(list) : list);
        setCurPage(page);
      }
    }
    setLoading(false);
  };

  const filterList = (filterInfo: any) => {
    const newFilter = { ...filter, ...filterInfo };
    setFilter(newFilter);
    getApplicantsList(newFilter);
  };

  const loadMoreItems = () => {
    getApplicantsList(filter, curPage + 1, true);
  };

  const tHeadRender: IHeadItem[] = isMobile
    ? [{ name: "Applicant name", col: 2 }]
    : [
        { name: "Applicant name", col: 2 },
        { name: "Phone No." },
        { name: "Location", isSort: true, key: "location" },
        { name: "Date Applied", isSort: true, key: "createdAt" },
        { name: "Status", isSort: true, key: "status" },
        { name: "Actions" },
      ];

  const statusDropdownBtnList = () => {
    const scoreColors = [
      "#FF0000",
      "#E60000",
      "#CC3300",
      "#FF3300",
      "#FF6347",
      "#FFD700",
      "#9ACD32",
      "#32CD32",
      "#228B22",
      "#008000",
      "#006400",
    ];

    const compList = Array.from({ length: 11 }, (v, k) => ({
      option: `Score ${k}`,
      key: `score${k}`,
      btnColor: "#FFFFFF",
      btnBgColor: scoreColors[k],
      statusBgColor: scoreColors[k],
      statusBorderColor: `rgba(0, 0, 0, 0.25)`,
    }));
    const list = competition ? compList : CandidateList;
    return list;
  };

  const tBodyRender: IBodyItem[] = [
    {
      component: (row: any) => (
        <>
          <Avatar
            icon="user"
            src={row && row.avatar}
            className="avatar"
            style={{ marginRight: "8px" }}
          />
          {`${row.firstName} ${row.lastName}`}
        </>
      ),
      className: "avatar-name",
      type: "avatar",
      col: 2,
    },
    { key: "phoneNumber" },
    { key: "location" },
    { key: "applyDate", type: "date" },
    {
      component: (row: any) => (
        <StatusDropdown
          otherInfo={{ opportunityId, applyId: row.applyId }}
          defaultOption={row.status}
          dropdownMenu={statusDropdownBtnList()}
          output={(item: any) => handleChangeStatus(item)}
        ></StatusDropdown>
      ),
    },
    {
      type: "dropdown-actions",
      dropdownList: [
        {
          icon: IconMessageBlack,
          name: "Message",
          onClick: (item: any, index: number) => {
            setUserIdList([item.userId]);
            sendMessage([item.userId]);
          },
        },
        // {
        //   icon: IconView,
        //   name: "View",
        //   onClick: (item: any, index: number) => {
        //     history.push(`/canvas/${item.canvasId}`);
        //   },
        // },
        {
          icon: IconView,
          name: "View",
          onClick: (item: any, index: number) => {
            window.open(`/canvas/${item.canvasId}`, '_blank');
          },
        },
        {
          icon: IconLink,
          name: "Copy link",
          onClick: (item: any, index: number) => {
            const link = window.location.origin + `/canvas/${item.canvasId}`;
            setTimeout(() => {
              copyToClipboard(link);
            }, 500);
          },
        },
      ],
    },
  ];

  const handleSelect = (list: number[]) => {
    setSelectedList(list);
    setMessageDisabled(list.length === 0);
  };

  const handleChangeStatus = async (item: any) => {
    const { key, otherInfo } = item;
    const param = {
      ...otherInfo,
      status: key,
    };
    const res = await changeApplicantStatus(param);
    if (res && res.success) {
      getApplicantsList(filter);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    getApplicantsList(filter, newPage);
  };

  const getTableData = (applicants: any) => {
    let list: any[] = [];
    if (applicants && applicants.list.length) {
      list = applicants.list.map((item: any) => {
        return {
          ...item,
          id: item.applyId,
        };
      });
    }
    return list;
  };

  const onMsgClose = () => {
    setVisibleMsgModal(false);
    setMsgContent("");
  };

  const sendMessage = async (users?: any[]) => {
    users = users || getUserIdList();

    if (!chatClient) {
      setUserIdList(users);
      setVisibleMsgModal(true);
      return;
    }
    const curUser = getModuleProp(MODULE_USER, "curUser");
    if (curUser?.id && users.length) {
      const members = [curUser.id, ...users];
      const channelId = await (users.length > 1 ? createBlastChat(users, curUser.id) : createChat(members));
      global_router.history.push(router_message, { channelId: channelId });
    }
  };

  const getUserIdList = () => {
    const list = selectedList.map((applyId: number) => {
      return _.find(tableData, { applyId }).userId;
    });
    return list;
  };

  const sendMsg = () => {
    if (!msgContent) {
      message.info("please input your message");
      return;
    }
    const params = {
      msg: msgContent,
      template: "msg_to_applicants",
      users: userIdList,
      extra: {
        opportunity_id: opportunityId,
      },
    };
    sendMsgEmail(params).then(() => {
      onMsgClose();
      setVisibleSuccessModal(true);
    });
  };

  return (
    <div className="opportunity-applicants-root">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`${router_my_dashboard}?tabType=posted_opportunity`}>
            <span className="bread-crumb-item">Posted {competition ? 'competition' : 'opportunity'}</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span className="bread-crumb-item">{competition ? 'Contestants' : 'Applicants'}</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="title-wrap">
        <div className="common-title bold-title">
          {competition ? 'Contestants' : 'Candidates'} {jobTitle ? " - " + jobTitle : ""}
        </div>
        <Button className="btn-secondary">
          <Link
            to={`${router_opportunity_detail.replace(
              ":slug",
              oppSlug || opportunityId.toString()
            )}`}
          >
            View {competition ? 'contest' : 'opportunity'} details
          </Link>
        </Button>
      </div>
      <div className="card-wrap">
        <div className="card-info">
          <div className="card-title">No. Applicants</div>
          <div className="card-content">{statistics.applyCount}</div>
        </div>
        <div className="card-info">
          <div className="card-title">Offer Total</div>
          <div className="card-content">{statistics.offerCount}</div>
        </div>
        <div className="card-info">
          <div className="card-title">Interview</div>
          <div className="card-content">{statistics.interviewCount}</div>
        </div>
        <div className="card-info">
          <div className="card-title">Opportunity Views</div>
          <div className="card-content">{statistics.viewCount}</div>
        </div>
      </div>
      <div className="fitler-wrap">
        <div className="wrap-title">{competition ? 'Contestants' : 'Candidates'} list</div>
        <Row className="filter-container" gutter={24}>
          <Col span={4}>
            <TextField
              label="Status"
              variant="filled"
              select
              className="material-input input"
              value={filter.status || ""}
              onChange={(e) => {
                filterList({ status: e.target.value });
              }}
            >
              <MenuItem key="All" value="All">
                ALL
              </MenuItem>
              {CandidateList.map((list) => (
                <MenuItem key={list.key} value={list.key}>
                  {list.option}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col span={4}>
            <TextField
              label="Page Size"
              variant="filled"
              select
              onChange={(e) => setPageSize(Number(e.target.value))}
              className="material-input input"
              value={pageSize}
            >
              {[25, 50, 100, 200, 400].map((size) => (
                <MenuItem key={size} value={size}>
                  {`${size}`}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col span={12}>
            <Search
              className="keyword-input"
              placeholder="Search Applicant by name..."
              onSearch={(value) => {
                filterList({ keyword: value });
              }}
            />
          </Col>
          <Col span={4}>
            <Button
              className="btn-secondary"
              disabled={messageDisabled}
              onClick={() => {
                sendMessage();
              }}
            >
              {messageDisabled ? (
                <img src={IconDisabledMsg} alt="" />
              ) : (
                <img src={IconMsg} alt="" />
              )}
              Message
            </Button>
          </Col>
        </Row>
      </div>
      <div className="applicant-list-wrap" ref={listRef}>
        {loading ? (
          <div className="spinner-wrap">
            <Spin size="large" />
          </div>
        ) : applicantsList.list && applicantsList.list.length ? (
          <ListTable
            tHeadRender={tHeadRender}
            tBodyRender={tBodyRender}
            hasSelect={true}
            dataList={tableData}
            selectList={selectedList}
            selectOnChange={handleSelect}
            loading={loading}
            total={applicantsList.count}
            size={pageSize}
            page={curPage}
            pageOnChange={handleChangePage}
            orderBy={"name"}
            orderByOnChange={(value: string) => setOrderBy(value)}
            isMobile={isMobile}
          />
        ) : (
          <div className="table-empty-wrap">
            <img src={IconNoStudent} alt="" />
            <div className="common-title">There are no applicants yet</div>
          </div>
        )}
      </div>
      <Modal
        footer={null}
        closable={false}
        onCancel={onMsgClose}
        visible={visibleMsgModal}
        centered={true}
        width="80%"
        className="opportunity-applicants-message-modal"
      >
        <div className="send-msg-modal">
          <div className="common-title">Send Message</div>
          <div className="modal-text">You are sending a message via email. </div>
          <TextField
            label="Message goes here"
            placeholder="Message goes here"
            multiline
            rows={4}
            variant="filled"
            className="material-input"
            value={msgContent}
            onChange={(event) => setMsgContent(event.target.value)}
          />
          <div className="btn-wrap">
            <Button type="primary" onClick={sendMsg}>
              Send Message
            </Button>
            <Button onClick={onMsgClose}>Cancel</Button>
          </div>
        </div>
      </Modal>
      <Modal
        footer={null}
        onCancel={() => setVisibleSuccessModal(false)}
        visible={visibleSuccessModal}
        centered={true}
        width={400}
      >
        <div className="comfirm-modal">
          <div className="common-title">Message sent!</div>
          <div className="modal-text">Woohoo! Your message was sent successfully.</div>
          <img src={IconSuccess} alt="success" />
          <div className="btn-wrap">
            <Button type="primary" onClick={() => setVisibleSuccessModal(false)}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
