import { message } from "antd";
import { getModuleProp, KV } from "module-reaction";
import find from "lodash/find";
import get from "lodash/get";
import { countryOptions } from "./constants";
import { MODULE_USER } from "../models/model_user";
import { router_get_started, router_professional_canvas_create, router_school_payment, router_student_canvas_create, SourceName, TargetType } from "./enum";
import queryString from "query-string";
import { global_router } from "../routers";

const LOCAL_STORAGE_ACCESS = "canvas-recruit-ls";
export const API_CODE_SUCCESS = 200;

export const parseYoutubeUrl = (url: string) => {
  try {
    const youtubePattern = /^(?:https?:\/\/)?(?:www\.)?youtu\.be\/([\w-]{11})/;
    const shortsPattern = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([\w-]{11})/;
    const watchPattern = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([\w-]{11})/;

    let videoId = "";
    url = url.trim();

    const match = url.match(youtubePattern) || url.match(shortsPattern) || url.match(watchPattern);
    if (match) {
      videoId = match[1];
    }

    if (!videoId) {
      return { videoUrl: url, image: "" };
    }

    const videoUrl = "https://www.youtube.com/watch?v=" + videoId;
    const image = `https://img.youtube.com/vi/${videoId}/0.jpg`;

    return { videoUrl, image };
  } catch (error) {
    console.error(error);
    return { videoUrl: url, image: "" };
  }
};

// allow key contans '.' to specific deeper structure
export function getValueOfKV(key: string, obj: KV) {
  if (key.includes(".")) {
    const keyChains = key.split(".").map((_) => _.trim());
    let res = obj,
      k;
    while (keyChains.length) {
      k = keyChains.shift();
      if (res[k!]) {
        res = res[k!];
      }
    }

    return res === obj ? undefined : res;
  } else {
    return obj[key];
  }
}

export function setValueOfKV(key: string, v: any, obj: KV) {
  if (key.includes(".")) {
    const keyChains = key.split(".").map((_) => _.trim());
    let res = obj,
      k;
    while (keyChains.length) {
      k = keyChains.shift();
      if (keyChains.length) {
        res[k!] = res[k!] || {};
        res = res[k!];
      } else {
        res[k!] = v;
      }
    }
  } else {
    obj[key] = v;
  }
}
export function localStorageGet(key: string) {
  const str = localStorage.getItem(LOCAL_STORAGE_ACCESS);
  if (str) {
    const store = JSON.parse(str);
    return getValueOfKV(key, store);
  }
  return null;
}
export function localStorageSet(key: string, v: any) {
  const str = localStorage.getItem(LOCAL_STORAGE_ACCESS);
  const store = str ? JSON.parse(str) : {};
  setValueOfKV(key, v, store);
  localStorage.setItem(LOCAL_STORAGE_ACCESS, JSON.stringify(store));
}

export function sessionStorageSet(key: string, v: any) {
  const str = sessionStorage.getItem(LOCAL_STORAGE_ACCESS);
  const store = str ? JSON.parse(str) : {};
  setValueOfKV(key, v, store);
  sessionStorage.setItem(LOCAL_STORAGE_ACCESS, JSON.stringify(store));
}

export function sessionStorageGet(key: string) {
  const str = sessionStorage.getItem(LOCAL_STORAGE_ACCESS);
  if (str) {
    const store = JSON.parse(str);
    return getValueOfKV(key, store);
  }
  return null;
}

function getType(obj: any) {
  var type = Object.prototype.toString
    .call(obj)!
    .match(/^\[object (.*)\]$/)![1]
    .toLowerCase();
  if (obj === null) return "null"; // PhantomJS has type "DOMWindow" for null
  if (obj === undefined) return "undefined"; // PhantomJS has type "DOMWindow" for undefined
  return type;
}

export function deepClone(val: any): any {
  var type = getType(val);
  switch (type) {
    case "array":
      return (val as any[]).map((_) => deepClone(_));
    case "map":
      return new Map(val);
    case "set":
      return new Set(val);
    case "object":
      var res: KV = {};
      for (var k in val) {
        res[k] = deepClone(val[k]);
      }
      return res;
    default:
      return val;
  }
}
export function maxCommonDivisor(m: number, n: number) {
  var u = +m,
    v = +n,
    t = v;
  while (v !== 0) {
    t = u % v;
    u = v;
    v = t;
  }
  return u;
}

const LOCAL_TOKEN_KEY = "cvsTkn";
const RES_TOKEN_KEY = "token";
let memToken: string;
function setToken(token: string) {
  localStorageSet(LOCAL_TOKEN_KEY, token);
  memToken = token;
}

function getToken(): string {
  return memToken || localStorageGet(LOCAL_TOKEN_KEY) || "";
}

export class Net {
  public static async get(
    url: string,
    query?: { [k: string]: any },
    use_header = true
  ) {
    return await this.toFetch(this.encodeQuery(url, query), {
      method: "GET",
      cache: "no-cache",
      ...(use_header
        ? {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
        : {}),
    });
  }

  public static async post(
    url: string,
    data: any,
    type: "post" | "put" | "form" | "multipart" = "post",
    use_header = true
  ) {
    let body: string | FormData;
    const headers: KV = {
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    };

    if (type === "form" || type === "multipart") {
      body = new FormData();
      for (const k in data) {
        body.append(k, data[k]);
      }
      // Content-Type will add by browser automaticly
    } else {
      body = JSON.stringify(data);
      headers["Content-Type"] = "application/json";
    }

    return await this.toFetch(url, {
      method: type === "put" ? "PUT" : "POST",
      mode: "cors",
      ...(use_header ? { headers } : {}),
      body,
    });
  }

  private static async toFetch(url: string, req: RequestInit) {
    try {
      const res = await fetch(url, req);
      let data = await res.json();
      data = { ...data, code: res.status };
      // if error, data.status will be >=400, if success, data has no 'status'
      if (data.code && data.code >= 400 && data.message) {
        // if (data.status === 401) {
        //   window.location.pathname = "/sign-in";
        // }
        message.error(data.message);
      }
      // record token here
      if (data[RES_TOKEN_KEY]) {
        setToken(data[RES_TOKEN_KEY]);
      }
      return data;
    } catch (error: any) {
      message.error(error?.message);
      return { error: error?.message };
    }
  }

  /**
   *
   * @param path router path
   * @param params datas of KV
   * @param usePost post type, 'no' means use 'get' method, 'json' means use post by json format, 'form' means use post by form format
   */
  public static async req(
    path: string,
    params: KV = {},
    method: "get" | "put" | "post" | "form" | "multipart" = "get"
  ) {
    const url = `${process.env.REACT_APP_BASE_URL}${path.startsWith("/") ? path : "/" + path
      }`;
    const res =
      method !== "get"
        ? await this.post(url, params, method)
        : await this.get(url, params);
    return res;
  }

  // call this when logout
  public static clearToken() {
    setToken("");
  }

  public static encodeQuery(url: string, query?: { [k: string]: any }): string {
    const preUrl = url;
    if (!query) {
      return preUrl;
    }

    let queryStr = "";
    const queryArr = [];
    for (const k in query) {
      const v = Array.isArray(query[k]) ? JSON.stringify(query[k]) : query[k];
      queryArr.push(`${k}=${encodeURIComponent(v)}`);
    }

    queryStr = queryArr.join("&");
    if (!preUrl.endsWith("?")) {
      queryStr = "?" + queryStr;
    }
    return preUrl + queryStr;
  }
}

interface IEvtNode {
  cb: (data?: any) => void;
  ctx?: any;
}
export class Events {
  private static _map = new Map<string, IEvtNode[]>();
  public static send(event: string, data?: any) {
    const nodes = this._map.get(event);
    if (nodes && nodes.length) {
      nodes.forEach((nd) => {
        nd.ctx ? nd.cb.call(nd.ctx, data) : nd.cb(data);
      });
    }
  }

  public static on(event: string, cb: (data?: any) => void, ctx?: any) {
    let nodes = this._map.get(event);
    if (!nodes) {
      nodes = [];
      this._map.set(event, nodes);
    }
    if (nodes.find((nd) => nd.cb === cb && nd.ctx === ctx)) {
      console.error(
        "already has a same cb which listened to the event:" + event
      );
      return;
    }
    nodes.push({
      cb,
      ctx,
    });
  }
  public static off(event: string, cb: (data?: any) => void, ctx?: any) {
    const nodes = this._map.get(event);
    if (nodes && nodes.length) {
      const idx = nodes.findIndex((nd) => nd.cb === cb && nd.ctx === ctx);
      if (idx > -1) {
        nodes.splice(idx, 1);
      }
    }
  }
}

let flPicker: {
  ele?: HTMLInputElement;
  cb?: (file: any, thumb?: string) => void;
} = {
  ele: undefined,
  cb: undefined,
};

export function upload_file(ext: string) {
  return new Promise((resolve, reject) => {
    if (!flPicker.ele) {
      flPicker.ele = document.createElement("input");
      flPicker.ele.type = "file";
      flPicker.ele.accept = ext.startsWith(".") ? ext : `.${ext}`;
    }
    flPicker.ele.dispatchEvent(new MouseEvent("click"));
    flPicker.ele.addEventListener("change", _onFileChosen);
    flPicker.cb = resolve;

    flPicker.ele.addEventListener("cancel", (_) => reject("canceled"));
  });
}
function _onFileChosen(e: any) {
  e.preventDefault();
  const file = e.target.files[0];
  const thumb = URL.createObjectURL(file); // valiable for images

  flPicker.ele!.value = "";

  flPicker.cb!(file, thumb);
}
export class GoogleUtil {
  static _googleAutoCompleteService: any;
  static get googleAutoCompleteService() {
    const google = (window as any).google;
    if (!this._googleAutoCompleteService) {
      this._googleAutoCompleteService = google
        ? new google.maps.places.AutocompleteService()
        : undefined;
    }
    return this._googleAutoCompleteService;
  }
  static _googlePlaceService: any;
  static get googlePlaceService() {
    const google = (window as any).google;
    if (!this._googlePlaceService) {
      this._googlePlaceService = new google.maps.places.PlacesService(
        new google.maps.Map(document.createElement("div"))
      );
    }
    return this._googlePlaceService;
  }

  static async onGoogleAddressSelected(address: any) {
    if (!address.place_id) return;
    return await new Promise((resolve) => {
      this.googlePlaceService.getDetails(
        { placeId: address.place_id },
        (place: any, status: any) => {
          if (status === "OK") {
            let country = "";
            let countryShort = "";
            let state = "";
            let stateShort = "";
            let city = "";
            let coordinates = [] as number[];
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            if (lat && lng) {
              coordinates = [lng, lat];
            }
            console.log(place)
            place.address_components.forEach((item: any) => {
              if (item.types.indexOf("country") > -1) {
                country = item.long_name;
                countryShort = item.short_name;
              } else if (
                //This is what we want to search by state
                item.types.indexOf("administrative_area_level_1") > -1
              ) {
                state = item.long_name;
                stateShort = item.short_name;
              } else if (item.types.indexOf("locality") > -1) {
                city = item.long_name;
              }
            });
            resolve({
              country: country,
              countryShort: countryShort,
              state: state,
              stateShort: stateShort,
              city: city,
              coordinates: coordinates,
              location: address.description,
            });
          } else {
            resolve(undefined);
          }
        }
      );
    });
  }
}

export const copyToClipboard = (
  content = "",
  msg = "The link has been copied to your clipboard"
) => {
  if (navigator?.clipboard) {
    navigator.clipboard.writeText(content)
  } else {
    let transfer = document.createElement("input");
    document.body.appendChild(transfer);
    transfer.value = content;
    transfer.focus();
    transfer.select();
    if (document.execCommand("copy")) {
      document.execCommand("copy");
    }
    transfer.blur();
    document.body.removeChild(transfer);
  }
  message.success(msg);
};

export const previewAddress = (stateOptions: any[], address: any) => {
  const state = get(find(stateOptions, { id: +address.state }), "name") || "";
  const country =
    get(find(countryOptions, { value: address.country }), "name") || "";
  return `${address.line1 || ""} ${address.city || ""} ${state} ${address.postal_code || ""
    } ${country}`;
};

export class Timer {
  delay: number;
  timer: any;
  isStop: boolean;
  constructor(delay: number) {
    this.delay = delay;
    this.isStop = false;
  }

  tick(cb: Function) {
    if (this.isStop) {
      clearTimeout(this.timer);
    } else {
      cb();
      setTimeout(() => {
        this.tick(cb);
      }, this.delay);
    }
  }

  start(cb: Function) {
    setTimeout(() => {
      this.tick(cb);
    }, this.delay);
  }
  stop() {
    this.isStop = true;
  }
}
export const Carousel_Delay_Time = 3000;

export function isAdminEditing(curUser?: any) {
  curUser = curUser || getModuleProp(MODULE_USER, 'curUser')
  if (curUser?.id) {
    return !!sessionStorage.getItem("canvas:local:userInfo") &&
      curUser?.profile?.target !== "admin"
  }
  return false
}

export const htmlDecode = (desc: string) => {
  // const e = document.createElement("div");
  // e.innerHTML = desc;
  // return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  return `<p>${desc || ''}</p>\n`
};

export const isHtmlTag = (desc: string) => {
  return /<\/?[a-z][\s\S]*>/i.test(desc);
};

export const elementIntoView = (id: string, delay: number, block?: any) => {
  setTimeout(() => {
    const element = document.getElementById(id);
    if (element)
      element.scrollIntoView({
        behavior: "smooth",
        block: block || "start",
      });
  }, delay || 1000);
};

export const customLocationCountMap = new Map([
  [14177, 465], // Sephora
]);

export const signUpPathFromTarget = (target: any): string => {
  console.log(target)
  let path = "/";
  const source = new URLSearchParams(window.location.search).get('source');
  switch (target) {
    case TargetType.STUDENT:
      path = `${router_student_canvas_create}?overlay=true&source=${source}`;
      break;
    case TargetType.PERSONAL:
      path = `${router_professional_canvas_create}?overlay=true&source=${source}`;
      break;
    case TargetType.SCHOOL:
      path = `school-info?overlay=true&source=${source}`;
      break;
    case TargetType.CAREER:
    case TargetType.BUSINESS:
    default:
      path = `company-info?isPostOpportunity=true&source=${source}`;
  }
  return path;
}

export const checkOverlay = () => {
  const info = queryString.parse(window.location.search);
  return !!info?.overlay;
}

export const getJobTitlesFromTarget = (jobTitles: any, target: any) => {
  let includeList = ['owner', 'co-owner', 'manager', 'director', 'front desk', 'other'];
  switch (target) {
    case TargetType.PERSONAL:
      includeList = ['assistant', 'barber', 'behind the chair owner', 'owner', 'educator', 'hairstylist', 'other', 'brand ambassador', 'owner', 'recruitment', 'social media', 'sales'];
      break;
    case TargetType.STUDENT:
      includeList = ['owner', 'co-owner', 'manager', 'director', 'front desk', 'other'];
      break;
    case TargetType.SCHOOL:
      includeList = ['owner', 'educator', 'admissions', 'enrollment', 'recruitment', 'placement', 'financial advisor', 'operations', 'other'];
      break;
    case TargetType.CAREER:
    case TargetType.BUSINESS:
    default:
      includeList = ['owner', 'co-owner', 'manager', 'director', 'front desk', 'other'];
      break;
  }
  return jobTitles.filter((title: any) => includeList.includes(title?.name?.toLowerCase()));
}

export const lorealIds = new Map([
  [19937, true], // Loreal
]);

export const getExpireDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 3650);
  return date;
};

export const reorder = (list: any, startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const staticAssetCdn = "https://d7mre4t3d00rw.cloudfront.net/assets/";
export const getStaticAssetPath = (path: string): string => {
  return `${staticAssetCdn}${path}`;
}

export const getStartedViaSource = (source: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  const alternateSource = urlParams.get('source') ? urlParams.get('source') : source;
  const rep = urlParams.get('rep') ? urlParams.get('rep') : null;

  global_router.history.push(`${router_get_started}?source=${alternateSource}&rep=${rep}`);
};

const legacyPath = "https://canvasrecruit-s3.s3-us-west-2.amazonaws.com/"
const legacyPathAlt = "https://canvasrecruit-s3.s3.amazonaws.com/"
const optimizedCdnPath = "https://asset.canvasme.com/";
export const getOptimizedPath = (path: string, width: number): string => {
  let newPath = `${path.replace(legacyPath, optimizedCdnPath)}?format=auto`;
  newPath = newPath.replace(legacyPathAlt, optimizedCdnPath)
  if (!newPath.includes(optimizedCdnPath)) {
    newPath = `${optimizedCdnPath}${newPath}`;
  }
  if (width) {
    newPath = `${newPath}&width=${width}`;
  }
  return newPath;
}

export const isOauthSignIn = () => {
  const currentSearch = window.location.search
  const hasOAuthParams = currentSearch?.includes('redirect_uri') && currentSearch?.includes('state')
  return hasOAuthParams
}

export const getStartedOauthParams = () => {
  const currentSearch = window.location.search
  if (isOauthSignIn()) {
    return currentSearch
  }
  return ''
}
