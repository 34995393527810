import React, { useState, useEffect } from "react";
import { Modal, Button, Checkbox } from "antd";
import { TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import CRTextArea from "./component_cr_textarea";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import MomentUtils from "@date-io/moment";
import Close from "../assets/images/ic_close.svg";
import IconCalendar from "../assets/images/ic_calendar.svg";
import { getCustomized } from "../api/Options";
import "./component_edit_employment_modal.less";
import moment from "moment";
import { EditorComponent } from "./component_editor";
import { doAction } from "module-reaction";
import { UpdatePageInfoAction } from "../models/model_student_canvas";

interface Props {
  className?: string;
  visible?: boolean;
  onClose: Function;
  onAdd: Function;
  data?: any;
}

const EditEmploymentModal: React.FC<Props> = (props) => {
  const { className, visible, onClose, onAdd, data } = props;
  const [position, setPosition] = useState(data.position || []);
  const [category, setCategory] = useState(data.jobCategory || []);
  const [company, setCompany] = useState(data.company || "");
  const [start, setStart] = useState(data.startDate || moment().format("MM/DD/YY"));
  const [end, setEnd] = useState(data.endDate || moment().format("MM/DD/YY"));
  const [desc, setDesc] = useState(data.description || "");
  const [curWork, setCurWork] = useState(data.isCurrentWork || false);
  const [nonBeauty, setNonBeauty] = useState(false);
  const [posOptions, setPosOptions] = useState<any[]>([]);
  const [cateOptions, setCateOptions] = useState<any[]>([]);

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = async () => {
    const res = await getCustomized(true, ["category", "jobTitle"]);
    if (res) {
      if (res.category && res.category.business) {
        let newCates = [...res.category.business];
        newCates = newCates.filter((c: any) => c.name !== "Other - non beauty industry role");
        newCates = newCates.map((c: any) => c.name === "Other - beauty industry role" ? { ...c, name: "other" } : c);
        setCateOptions(newCates);
      }
      if (res.jobTitle) {
        setPosOptions(res.jobTitle);
      }
    }
  };

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      className={className}
      wrapClassName="edit-employment-modal-wrap"
      centered={true}
    >
      <div className="edit-employment-modal">
        <div className="title-sec">
          <span className="title">Add employment information</span>
          <img className="close" src={Close} alt="" onClick={() => onClose()} />
        </div>
        <Autocomplete
          multiple
          className="tag-input"
          options={posOptions}
          value={position}
          freeSolo
          getOptionLabel={(option) => typeof option === "string" ? option : option.name}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={typeof option === "string" ? option : option.name} {...getTagProps({ index })} />
            ))
          }
          onChange={(event: object, value: any) => {
            const newItems = _.map(value, (item) => {
              if (typeof item === "string") {
                if (value.find((_: any) => _.name === item)) {
                  return undefined;
                }
                return posOptions.find((_: any) => _.name.toLowerCase() === item.toLowerCase()) || { name: item };
              }
              return item;
            }).filter(Boolean);
            setPosition(_.uniqBy(newItems, "name"));
          }}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Job Title"
              variant="filled"
              className="material-input auto-input"
            />
          )}
        />
        <Autocomplete
          className="tag-input"
          options={["Beauty Industry", "Non Beauty Industry"]}
          freeSolo={false}
          value={nonBeauty ? "Non Beauty Industry" : "Beauty Industry"}
          getOptionLabel={(option) => option}
          onChange={(event, newValue) => {
            setNonBeauty(newValue === "Non Beauty Industry");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Industry"
              variant="filled"
              className="material-input auto-input"
            />
          )}
        />
        {!nonBeauty && (
          <Autocomplete
            multiple
            className="tag-input"
            options={cateOptions}
            value={category}
            freeSolo
            getOptionLabel={(option) => typeof option === "string" ? option : option.name}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={typeof option === "string" ? option : option.name} {...getTagProps({ index })} />
              ))
            }
            onChange={(event: object, value: any) => {
              const newItems = _.map(value, (item) => {
                if (typeof item === "string") {
                  if (value.find((_: any) => _.name === item)) {
                    return undefined;
                  }
                  return cateOptions.find((_: any) => _.name.toLowerCase() === item.toLowerCase()) || { name: item };
                }
                return item;
              }).filter(Boolean);
              setCategory(_.uniqBy(newItems, "name"));
            }}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company Type"
                variant="filled"
                className="material-input auto-input"
              />
            )}
          />
        )}
        <TextField
          key="company"
          label="Company Name"
          value={company}
          variant="filled"
          className="material-input input"
          onChange={(e) => { setCompany(e.target.value); }}
        />
        <div className="check-sec">
          <Checkbox className="check-box" checked={curWork} onChange={(e) => { setCurWork(e.target.checked); }} />
          <span>I am currently working in this role</span>
        </div>
        <div className="date-sec">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              disableToolbar
              autoOk
              variant="inline"
              openTo="year"
              format="MM/DD/YY"
              label="Start"
              className="material-date-picker date-input"
              views={["year", "month", "date"]}
              value={start}
              onChange={(value) => {
                setStart(value?.format("MM/DD/YY"));
              }}
            />
          </MuiPickersUtilsProvider>
          <img alt="" src={IconCalendar} />
        </div>
        {!curWork && (
          <div className="date-sec">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                disableToolbar
                autoOk
                variant="inline"
                openTo="year"
                format="MM/DD/YY"
                label="End"
                className="material-date-picker date-input"
                views={["year", "month", "date"]}
                value={end}
                onChange={(value) => {
                  setEnd(value?.format("MM/DD/YY"));
                }}
              />
              <img alt="" src={IconCalendar} />
            </MuiPickersUtilsProvider>
          </div>
        )}
        {/* <CRTextArea
          className="input"
          label="Description"
          value={desc}
          onInput={(value: string) => {
            setDesc(value);
          }}
        /> */}
        <div className="description-sec" style={{ textAlign: "center", backgroundColor: "#ffffff", padding: "10px 0", marginTop: "10px" }}>
          <span className="description-title" style={{ fontWeight: "bold", fontSize: "1.2em" }}>Description</span>
          <EditorComponent
            placeholder="Description"
            content={desc}
            hideTools={[
              "image",
              "colorPicker",
              "fontFamily",
              "fontSize",
            ]}
            getEditorConent={(aboutMe: any) =>
              setDesc(aboutMe)
            }
          />
        </div>
        <Button
          className="btn-primary btn-add"
          onClick={() => {
            console.log("position", position);
            const jobTitle = position.map((p: any) => p.name).join(", ");
            onAdd({
              uuid: data.uuid || "",
              position: position,
              title: jobTitle,
              jobCategory: nonBeauty ? [] : category,
              isCurrentWork: curWork,
              description: desc,
              company: company,
              startDate: start,
              endDate: end,
            });
          }}
        >
          Add
        </Button>
      </div>
    </Modal>
  );
};
export default EditEmploymentModal;
