import React from "react";
import { RouteComponentProps, Switch, Route } from "react-router-dom";
import { Layout } from "antd";
import classnames from "classnames";
import CRHeader from "../../components/component_cr_header";
import CRFooter from "../../components/component_cr_footer";
import { mapProp, doAction, doFunction, KV } from "module-reaction";
import { User } from "../../models/model_user";
import {
  router_homepage_index,
  router_homepage_canvas,
  router_homepage_opportunity,
  router_homepage_about,
  router_homepage_about_1,
  router_homepage_terms,
  router_homepage_privacy_policy,
  router_homepage_canvas_rights_data,
  router_homepage_contact,
  router_homepage_land_saloncentric,
  router_student_pro_landing,
  router_land_your_dream_career,
  router_stop_interview_no_shows,
  router_homepage_land_wella,
  router_innovate_landing,
  router_screps_landing,
  router_opportunities,

} from "../../utils/enum";
import {
  module_home,
  LoginWithTokenAction,
  LogOutAction,
} from "../../models/model_home";
import { modle_user, MODULE_USER } from "../../models/model_user";
import "./homepage.less";
import { HomepageCanvases } from "./homepage_canvases";
import { HomepageOpportunity } from "./homepage_opportunity";
import { HomepageAbout } from "./homepage_about";
import { HomepageTerms } from "./homepage_terms";
import HomepageContact from "./homepage_contact";
import { HomepagePrivacyPolicy } from "./homepage_privacy_policy";
import HomepageCanvasRightsData from "./homepage_canvas_rights_data";
import { Home } from "./home/home";
import StudentProLanding from "./studentpro/studentpro";
import SalonCentricHome from "./saloncentric/sc";
import NewSalonCentric from "./studentpro/saloncentric";
import ScNoShowLanding from "./studentpro/sc_no_show_landing";
import WellaLanding from "./studentpro/wella_landing";
import RedirectComponent from "../../components/component_redirect";
import { NewStudentProLanding } from "./home/new_student_pro_landing";

const { Content } = Layout;

interface HomeProp extends RouteComponentProps, KV {
  user: User;
  logined: boolean;
  resetPwdSuccess: boolean;
  showLogin: boolean;
}

@mapProp(module_home)
export default class HomePageView extends React.Component<HomeProp, {}> {
  componentRouters: RouterCfg[] = [
    {
      path: router_homepage_index,
      component: Home,
      exact: true,
    },
    // {
    //   path: router_homepage_land_paul,
    //   component: PaulComponent,
    //   exact: true,
    // },
    {
      path: router_homepage_canvas,
      component: HomepageCanvases,
      exact: true,
    },
    {
      path: router_homepage_opportunity,
      component: () => {
        window.location.href = process.env.REACT_APP_NEXT_URL + '/opportunities';
        return null;
      },
      exact: true,
    },
    {
      path: router_homepage_about,
      component: HomepageAbout,
      exact: true,
    },
    {
      path: router_homepage_about_1,
      component: HomepageAbout,
      exact: true,
    },
    {
      path: router_homepage_contact,
      component: HomepageContact,
      exact: true,
    },
    {
      path: router_homepage_terms,
      component: HomepageTerms,
      exact: true,
    },
    {
      path: router_homepage_privacy_policy,
      component: HomepagePrivacyPolicy,
      exact: true,
    },
    {
      path: router_homepage_canvas_rights_data,
      component: HomepageCanvasRightsData,
      exact: true,
    },
    {
      path: router_homepage_canvas_rights_data,
      component: HomepageCanvasRightsData,
      exact: true,
    },
    {
      path: router_student_pro_landing,
      component: () => {
        window.location.href = process.env.REACT_APP_NEXT_URL + '/studentpro';
        return null;
      },
      exact: true
    },
    {
      path: router_land_your_dream_career,
      component: NewSalonCentric,
      exact: true
    },
    {
      path: router_stop_interview_no_shows,
      component: ScNoShowLanding,
      exact: true
    },
    {
      path: router_homepage_land_saloncentric,
      component: SalonCentricHome,
      exact: true
    },
    {
      path: router_homepage_land_wella,
      component: WellaLanding,
      exact: true
    },
    {
      path: router_innovate_landing,
      component: () => <RedirectComponent url="https://sites.canvasme.com/innovate" />,
      exact: true
    },
    {
      path: router_screps_landing,
      component: () => <RedirectComponent url="https://sites.canvasme.com/screps" />,
      exact: true
    },
    {
      path: router_opportunities,
      component: () => <RedirectComponent url={`${process.env.REACT_APP_NEXT_URL}/opportunities`} />,
      exact: true
    },
    {
      path: "/",
      component: Home,
    },
  ];

  state = {
    showForget: false,
    showChange: false,
    showMenu: false,
    transparentMenu: true,
  };

  componentDidMount() {
    doAction(LoginWithTokenAction);
    window.addEventListener("scroll", () => {
      const scrolled =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      const trans =
        scrolled < 5 &&
        (this.props.location.pathname === "/" ||
          this.props.location.pathname === "" ||
          this.props.location.pathname === "/paulmitchell" ||
          this.isSalonCentric());
      this.setState({ ...this.state, transparentMenu: trans });
    });
  }

  private updateField = (params: any) => {
    this.setState({ ...this.state, ...params });
  };

  private jumpToLoginPage = () => {
    this.props.history.push({
      pathname: "/sign-in",
      search: `?from=${window.location.pathname}`,
    });
  };

  private isSalonCentric(): boolean {
    return this.props.location.pathname === "/saloncentric";
  }

  render() {
    const isSalonCentric = this.isSalonCentric();
    return (
      <Layout className="home-root">
        <CRHeader
          className={classnames("home-header", {
            isHome: this.state.transparentMenu,
          })}
          history={this.props.history}
          pathname={this.props.location.pathname}
          onLogin={() => this.jumpToLoginPage()}
          // onLogin={() => doAction(ShowHideLoginAction, true)}
          onLogout={() => {
            doAction(LogOutAction);
            doAction(MODULE_USER, modle_user);
            doFunction(async () => {
              this.props.history.push("/");
            });
          }}
        />
        <Content className="home-content">
          <Switch>
            {this.componentRouters.map((_: RouterCfg, idx: number) => (
              <Route key={`${_.path}-${idx}`} {..._} />
            ))}
          </Switch>
        </Content>
        <CRFooter history={this.props.history} isHome={true} />
      </Layout>
    );
  }
}
