import React, { useState, useEffect } from "react";
import { doAction } from "module-reaction";
import { Button, Input, Row, Col, message, Spin } from "antd";
import _ from "lodash";
import IconAdd from "../../../assets/images/ic_add_green.svg";
import { TextField, MenuItem } from "@material-ui/core";
import classnames from "classnames";
import { PostedOppList } from "../../../components/component_posted_opp_list";
import FieldRequireModal from "../../../components/component_field_require_modal";
import {
  router_dashboard_post_job_opp,
  router_opportunity_detail,
  router_opportunity_applicants,
  router_membership_plan_update,
  CanvasType,
} from "./../../../utils/enum";
import {
  module_post_job_opp,
  MODULE_POST_JOB_OPP,
} from "../../../models/model_post_job_opp";
import { getCanvas } from "../../../api/Canvas";
import {
  getMyOppList,
  updateOppStatus,
  getOppDtls,
  createOpportunity,
} from "../../../api/Opportunity";
import "./my_account_posted_opportunities.less";
import { getExpireDate } from "../../../utils/utils";
import OpportunityEmbed from "./OpportunityEmbed";
const { Search } = Input;

interface MyAccountPostedOppProps {
  canvasInfo: any;
  history: any;
}
export const MyAccountPostedOpp: React.FC<MyAccountPostedOppProps> = (
  props
) => {
  const { canvasInfo, history } = props;
  const [opportunityList, setOpportunityList] = useState([]);
  const [opportunityChanged, setOpportunityChanged] = useState("");
  const [canvasJob, setCanvasJob] = useState<any>(null);
  const [locations, setLocations] = useState<any[]>([]);
  const [emptyFields, setEmptyFields] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const emptyLocation = { uuid: '', address: 'All' }
  const [filter, setFilter] = useState({
    canvas_id: 0,
    location: emptyLocation,
    keyword: "",
    status: "all",
  });

  const statusList = [
    { name: "All", type: "all" },
    { name: "Active", type: "public" },
    { name: "Inactive", type: "private" },
  ];

  useEffect(() => {
    if (canvasInfo) {
      doAction(MODULE_POST_JOB_OPP, module_post_job_opp);
      getCanvasDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasInfo, opportunityChanged]);

  const getOpportunityList = async (
    canvas_id: number = 0,
    locationUUID: string = "",
    keyword: string = "",
    status: string = "all"
  ) => {
    setLoading(true);
    const parameter = {
      canvas_id,
      keyword,
      status,
    };
    if (locationUUID) {
      Object.assign(parameter, { locationUUID });
    }
    const res = await getMyOppList(parameter);
    if (res) {
      setOpportunityList(res);
    }
    setLoading(false);
  };

  const getCanvasDetails = async () => {
    const { id } = canvasInfo;
    if (id) {
      setLoading(true);
      const res = await getCanvas(id);
      if (res) {
        setLocations([ emptyLocation, ...res.detail.locations]);
        setCanvasJob(res.job);
        updateFilter("canvas_id", id);
      } else {
        setLoading(false);
      }
    }
  };

  const updateFilter = (key: string, value: any) => {
    const newFilter = { ...filter, [key]: value };
    setFilter(newFilter);
    const { canvas_id, location, keyword, status } = newFilter;
    getOpportunityList(canvas_id, location.uuid, keyword, status);
  };

  const updateOpportunityStatus = async (info: any) => {
    const res = await updateOppStatus(info);
    if (res && res.success) {
      const { id, status } = info;
      const list = opportunityList;
      Object.assign(_.find(list, { id }), { status });
      setOpportunityList([...list]);
      getCanvasDetails();
      if (res.success) {
        setOpportunityChanged(`${id}_${status}`);
      }
    }
  };

  const navigateToApplicants = (id: number) => {
    history.push({
      pathname: `${router_opportunity_applicants}`,
      search: `?canvasId=${canvasInfo.id}&id=${id}`,
    });
  };

  const editOppItem = (info: any) => {
    const { canvasId, id } = info;
    history.push({
      pathname: router_dashboard_post_job_opp,
      search: `?id=${canvasId}&opportunityId=${id}&isInMyAccount=1`,
    });
  };

  const duplicate = async (info: any) => {
    const { id } = info;
    const oppDtls = await getOppDtls(id);
    if (oppDtls) {
      delete oppDtls.id;
      delete oppDtls.status;
      delete oppDtls.poster;
      const date = getExpireDate();
      oppDtls.expire = date;
      const res = await createOpportunity(oppDtls);
      if (res) {
        const { canvasId, id: newId } = res;
        const { canvas_id, location, keyword, status } = filter;
        await getOpportunityList(canvas_id, location.uuid, keyword, status);
        setTimeout(() => {
          history.push({
            pathname: router_dashboard_post_job_opp,
            search: `?id=${canvasId}&opportunityId=${newId}&isInMyAccount=1`,
          });
        }, 1000);
      }
    }
  };

  const refresh = () => {
    getCanvasDetails()
    getOpportunityList()
  }

  const viewDetails = (info: any) => {
    // const { id } = info;
    history.push({
      pathname: router_opportunity_detail.replace(":slug", info.slug),
      // search: `?canvasId=${id}`,
    });
  };

  const postOpportunity = () => {
    if (!canvasInfo.id) {
      message.info("Please select a canvas");
      return;
    }
    if (canvasJob.max <= canvasJob.now) {
      message.error(
        "Your current membership and plan does not cover this opportunity post. Please check your membership setting or contact support at hello@beautista.com"
      );
      return;
    }
    history.push({
      pathname: router_dashboard_post_job_opp,
      search: `?id=${canvasInfo.id}&isInMyAccount=1`,
    });
  };

  const buyMoreOpportunities = () => {
    if (!canvasInfo.id) {
      message.info("Please select a canvas");
      return;
    }
    history.push({
      pathname: `${router_membership_plan_update
        .replace(":type", canvasInfo.type)
        .replace(":id", canvasInfo.id)}`,
    });
  };

  return (
    <div className="my-account-opportunity">
      <div className="title-wrap">
        <div className="common-title bold-title">Posted Opportunities</div>
      </div>
      <div className="btn-wrap">
        <div className="btn-left-sec">
          <Button
            className="btn-secondary"
            onClick={() => {
              postOpportunity();
            }}
          >
            <img src={IconAdd} alt="" />
            Post Opportunity
          </Button>
          <div className="vertical-line"></div>
        </div>

        <div className="btn-right-sec">
          {/* <Button
            className="btn-secondary"
            onClick={() => {
              buyMoreOpportunities();
            }}
          >
            Buy more opportunities
          </Button> */}
          {canvasJob ? (
            <div className="btn-tips">
              {canvasJob.now} of {canvasJob.max} used
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Row className="filter-wrap" gutter={16}>
        <Col
          span={6}
          hidden={canvasInfo && canvasInfo.type === CanvasType.SCHOOL}
        >
          <TextField
            label="location"
            variant="filled"
            select
            className="material-input input"
            value={filter.location}
            onChange={(e) => updateFilter("location", e.target.value)}
          >
            {locations.map((item: any, index: number) => (
              <MenuItem key={index} value={item}>
                {item.address}
              </MenuItem>
            ))}
          </TextField>
        </Col>
        <Col span={18}>
          <Search
            className="keyword-input"
            placeholder="Search job title"
            onSearch={(value) => updateFilter("keyword", value)}
          />
        </Col>
      </Row>
      <div className="status-wrap">
        {statusList.map((item: any, index: number) => (
          <span
            className={classnames("tab-btn", {
              active: filter.status === item.type,
            })}
            onClick={() => updateFilter("status", item.type)}
            key={index}
          >
            {item.name}
          </span>
        ))}
      </div>
      {loading ? (
        <Spin className="loading" />
      ) : (
        <div className="opp-list-wrap">
          {opportunityList.length ? (
            <PostedOppList
              dataList={opportunityList}
              edit={(info: any) => editOppItem(info)}
              viewDetail={(info: any) => viewDetails(info)}
              duplicate={(info: any) => duplicate(info)}
              updateStatus={(info: any) => updateOpportunityStatus(info)}
              navigateToApplicants={(id: number) => navigateToApplicants(id)}
              refresh={refresh}
            ></PostedOppList>
          ) : (
            <div className="table-empty-wrap">
              <div className="common-title">There’s no opportunity added</div>
            </div>
          )}
        </div>
      )}
      <div className="posted-opp-btn">
        <Button
          className="btn-secondary"
          onClick={() => {
            postOpportunity();
          }}
        >
          <img src={IconAdd} alt="" />
          Post Opportunity
        </Button>
      </div>

      <OpportunityEmbed businessId={canvasInfo.id} />

      <FieldRequireModal
        onClose={() => setEmptyFields([])}
        visible={!_.isEmpty(emptyFields)}
        data={emptyFields}
      />
    </div>
  );
};
