import { Spin } from 'antd';
import React from 'react';

export class Home extends React.Component {
  
  componentDidMount() {
      window.location.href = process.env.REACT_APP_NEXT_URL;
  }

  render() {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }
}
