import Http from "./Http";

export const getTodoOpportunity = () => {
  return Http.get("/todo/")
    .then(resp => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    })
    .catch(err => console.error(err.message));
};
