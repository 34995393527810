import React from "react";
import { Button, Modal, notification } from "antd";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { Layout } from "antd";
import classnames from "classnames";
import _ from "lodash";
import { mapProp, doAction, doFunction, getModuleProp } from "module-reaction";
import moment from "moment";
import queryString from "query-string";
import OpportunityApplyModal from "../opportunity_apply_modal/opportunity_apply_modal";
import CRFooter from "../../../components/component_cr_footer";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import IconLocation from "../../../assets/images/ic_locate_stroke.svg";
import IconSendCanvas from "../../../assets/images/ic_send_canvas.svg";
import IconFeatureEmpty from "../../../assets/images/ic_feature_photo_empty.svg";
import IconPlayWhite from "../../../assets/images/ic_play_white.svg";
import IconBookmark from "../../../assets/images/ic_bookmark.svg";
import IconCanvasName from "../../../assets/images/ic_home_location.svg";
import Step1 from "../../../assets/images/apply/1_create.png";
import Step2 from "../../../assets/images/apply/2_build.png";
import Step3 from "../../../assets/images/apply/3_click_view.png";
import Step4 from "../../../assets/images/apply/4_browse.png";
import Step5 from "../../../assets/images/apply/5_apply.png";
import { User, LogOutAction } from "../../../models/model_user";
import CRHeader from "../../../components/component_cr_header";
import VideoModal from "../../../components/component_video_modal";
import FieldRequireModal from "../../../components/component_field_require_modal";
import {
  router_post_job_opp,
  router_dashboard_post_job_opp,
  router_sign_in,
  router_opportunity_detail,
  router_canvas_detail,
  router_get_started,
  router_my_canvases,
} from "./../../../utils/enum";
import {
  module_opportunity_detail,
  setModalParamAction,
  OpportunityApplyAction,
  setApplyResultParamAction,
  setOppStatusAction,
  getSimilarDataAction,
} from "../../../models/model_opportunity_detail";
import {
  module_post_job_opp,
  getOppDtlsAction,
  postOpportunityAction,
  addBookmarkAction,
  MODULE_POST_JOB_OPP,
  UpdateBaseInfoAction,
  general_location_address,
} from "../../../models/model_post_job_opp";
import { getUserInfo } from "../../../api/Auth";
import { getToken } from "../../../utils/authStorage";
import "./opportunity_detail.less";
import { addOppView } from "../../../api/Opportunity";
import { getOptimizedPath, getStartedViaSource, lorealIds } from "../../../utils/utils";
import { getMyCanvases } from "../../../api/Canvas";
import { redirectToGetStarted } from "../../../utils/redirect";
import TagManager from "react-gtm-module";

interface Props extends RouteComponentProps {
  match: any;
  mediaInfo: any;
  opportunityInfo: any;
  canvasDetails: any;
  additionalInfo: any;
  website: string;
  tagLine: string;
  about: string;
  opportunityId: any;
  modalParameters: any;
  applyResultParam: any;
  poster: number;
  similarData: any[];
  bookmarkTag: boolean;
  emptyFields: string[];
}

@mapProp(module_post_job_opp)
@mapProp(module_opportunity_detail)
export default class OpportunityDetail extends React.Component<Props, {}> {
  state = {
    isMobile: window.innerWidth < 1024,
    isDetail: true,
    isPreview: false,
    fromDashboard: false,
    showApplyRedirect: false,
    user: {} as User,
    curPlayUrl: "",
    token: getToken(),
    showSigninModal: false,
    showNeedValidApplyCanvasModal: false,
  };
  componentDidMount() {
    this.initPageData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.mediaInfo !== prevProps.mediaInfo) {
      this.checkAutoplay();
    }
  }

  componentWillUnmount() {
    // clear data
    if (!this.state.isPreview) {
      doAction(MODULE_POST_JOB_OPP, module_post_job_opp);
    }
  }

  private async checkValidApplyCanvas () {
    const canvases = await getMyCanvases({status: "all"})
    console.log(canvases)
    for(const canvas of canvases.list){
      if(['student', 'professional'].includes(canvas.type)) {
        return true
      }
    }
    return false
  }

  private checkAutoplay() {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const autoplay = searchParams.get('autoplay') === 'true';

    if (autoplay) {
      console.log('Autoplay is enabled.');
      this.updateState({
        curPlayUrl:
          this.props.mediaInfo.featuredVideo.url ||
          this.props.mediaInfo.featuredVideo.video ||
          "",
      });
    } else {
      console.log('Autoplay is not enabled.');
    }
  }

  private updateAutoPlay() {
    console.log('updateAutoPlay')
    const { location, history } = this.props;
    const searchParams = new URLSearchParams(location.search);
    console.log(this.state.curPlayUrl)
    if(this.state.curPlayUrl !== "") {
      if (searchParams.get('autoplay') !== 'true') {
        searchParams.set('autoplay', 'true');
        window.history.replaceState(null, '', `${location.pathname}?${searchParams.toString()}`);
      }
    } else {
      searchParams.delete('autoplay');
      window.history.replaceState(null, '', `${location.pathname}?${searchParams.toString()}`);
    }
  }

  private onVideoClickHandler = () => {
    this.setState({
      ...this.state,
      curPlayUrl: this.props.mediaInfo.featuredVideo.url || this.props.mediaInfo.featuredVideo.video || "",
    }, () => {
      this.updateAutoPlay();
    });
  };

  private onCloseHandler = () => {
    this.setState({ curPlayUrl: "" }, () => {
      this.updateAutoPlay();
    });
  };
  

  private initPageData = (slug: string = this.props.match.params.slug) => {
    window.scrollTo(0, 0);
    const id = queryString.parse(this.props.history.location.pathname);
    const is_preview = "0";
    const from_dashboard = "0";
    const isPreview = Number(is_preview) > 0;
    const isDetail = !isPreview;
    const fromDashboard = Number(from_dashboard) > 0;
    this.updateState({ isDetail, isPreview, fromDashboard });
    if (slug || id) {
      if (this.state.token) {
        this.getUserInfo();
      }
      doAction(getOppDtlsAction, slug || id)
      doAction(getSimilarDataAction);
    }
    if (isDetail) {
      doFunction(async () => {
        if (isDetail && id && !slug) {
          const oppSlug = getModuleProp(MODULE_POST_JOB_OPP, "slug");
          //change url to slug
          window.history.replaceState(
            {},
            "",
            `${window.location.href.split("?")[0]}/${oppSlug}`
          );
        }
        const oppId = getModuleProp(MODULE_POST_JOB_OPP, "opportunityId");
        addOppView(Number(oppId));
      });
    }
  };

  private updateState = (params: any) => {
    const newState = { ...this.state, ...params };
    this.setState(newState);
  };

  private getUserInfo = async () => {
    if (getToken()) {
      const res = await getUserInfo();
      if (res && res.user) {
        this.setState({ ...this.state, user: res.user });
      }
    }
  };

  private renderDetails = () => {
    const detailsMap: any[] = [
      {
        title: "Opportunity Type",
        key: "jobType",
      },
      {
        title: "Opportunity Category",
        key: "jobCategory",
      },
      {
        title: "Location",
        key: "selectLocation",
      },
      {
        title: "Company",
        key: "introduction",
      },
      {
        title: "Website",
        key: "website",
      },
      // {
      //   title: " Created On",
      //   key: "createdAt",
      // },
      {
        title: "Updated On",
        key: "updatedAt",
      },
    ];
    const getValue = (key: string) => {
      const { opportunityInfo, canvasDetails, website } = this.props;
      switch (key) {
        case "jobType":
        case "jobCategory":
          return opportunityInfo && opportunityInfo[key]
            ? opportunityInfo[key].name
            : "";
        case "selectLocation":
          return opportunityInfo && opportunityInfo[key]
            ? (opportunityInfo.validForAllState ? general_location_address : opportunityInfo[key].address)
            : "";
        case "introduction":
          return canvasDetails && canvasDetails[key]
            ? canvasDetails[key].name
            : "";
        case "website":
          return website || "";
        case "createdAt":
          return opportunityInfo && opportunityInfo[key]
            ? moment(opportunityInfo[key]).format("MMM DD YYYY")
            : "";
        case "updatedAt":
          return opportunityInfo && opportunityInfo[key]
            ? moment(opportunityInfo[key]).format("MMM DD YYYY")
            : "";
        default:
          return "";
      }
    };
    return (
      <div className="details-table">
        {detailsMap.map((item: any, index: number) => {
          const { key, title } = item;
          return (
            <div className="table-row" key={index}>
              <div className="table-header-cell">{title.toUpperCase()}</div>
              <div className="table-cell">{getValue(key)}</div>
            </div>
          );
        })}
      </div>
    );
  };

  private renderAdditionalInfo = () => {
    const detailsMap: any[] = [
      {
        title: "Company Tagline",
        key: "tagLine",
      },
      {
        title: "Payment Structure",
        key: "paymentStructure",
      },
      {
        title: "Preferred Brands",
        key: "retailBrands",
      },
      {
        title: "Company Software Used",
        key: "softwares",
      },
    ];
    const getValue = (key: string) => {
      const { additionalInfo, tagLine } = this.props;
      switch (key) {
        case "softwares":
        case "retailBrands":
          return additionalInfo && additionalInfo[key].length
            ? additionalInfo[key].map((obj: any) => obj.name).join(", ")
            : "";
        case "tagLine":
          return tagLine || "";
        case "paymentStructure":
          return additionalInfo && additionalInfo[key]
            ? additionalInfo[key].name
            : "";
        default:
          return "";
      }
    };
    return (
      <div className="details-table">
        {detailsMap.map((item: any, index: number) => {
          const { key, title } = item;
          const value = getValue(key);
          return (
            <div
              className="table-row"
              key={index}
              hidden={!value || value === "Don't show"}
            >
              <div className="table-header-cell">{title.toUpperCase()}</div>
              <div className="table-cell">{value}</div>
            </div>
          );
        })}
      </div>
    );
  };

  private publish = () => {
    doAction(postOpportunityAction, {
      history: this.props.history,
      isPublish: true,
    });
  };

  private sendMyCanvasEmail = (msg: string) => {
    doAction(OpportunityApplyAction, {
      id: this.props.opportunityId,
      msg,
    });
    // show for Mizani Educator role
    const isBefore2H2024 = (dateTime) => {
      const startOf2H2024 = new Date('2024-07-01T00:00:00Z'); // Start of 2H 2024
      return dateTime < startOf2H2024;
    };
    
    let serverDateTime = null;
    fetch('https://worldtimeapi.org/api/ip')
      .then(response => response.json())
      .then(data => {
        serverDateTime = new Date(data.datetime);
      })
      .catch(error => {
        console.error('Error fetching server time, using local time:', error);
        serverDateTime = new Date();
      }).finally(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'apply_job',
            user_id: this.state.user.id,
            page_location: window.location.pathname,
            page_referrer: document.referrer
          }
        });
          // Mizani Educator role ids are 256 and 556
        if ((this.props.opportunityId === 256 || this.props.opportunityId === 556) && isBefore2H2024(serverDateTime)) {
          notification.warning({
            message: "Notice",
            description: "Thank you so much for applying for the Mizani Educator role. We are thrilled to see your interest. Although we are not currently hiring, we will be soon, and your portfolio will be on file. Stay tuned for more information as our hiring date nears.",
          });
        }
      })
    
    if (this.props.opportunityInfo?.applyUrl) {
      doFunction(async () => {
        this.setState({ showApplyRedirect: true })
      })
    }
  };

  private closeApplyModal = () => {
    doAction(setModalParamAction, {
      visible: false,
      title: "",
      desc: "",
      tips: "",
      applyBtnText: "",
    });
  };

  private clickApplyResultBtn = () => {
    doAction(setApplyResultParamAction, { resultVisible: false });
    this.props.history.push("/my-dashboard");
  };

  private sendMyCanvas = () => {
    const companyName =
      this.props.canvasDetails &&
      this.props.canvasDetails.introduction &&
      this.props.canvasDetails.introduction.name;
    doAction(setModalParamAction, {
      visible: true,
      title: `Apply Now ${companyName ? `to ${companyName}` : ""}`,
      desc: `Send ${companyName ? companyName : ""
        } a message to demonstrate how you are the right fit for the opportunity.`,
      tips: `Clicking on <span style="font-weight: 800;">Apply Now</span> will automatically send the employer the
      link to your Canvas along with the above message.`,
      applyBtnText: "Apply Now",
      onApply: (msg: string) => this.sendMyCanvasEmail(msg),
      onClose: this.closeApplyModal,
    });
  };

  private setAsPrivate = (status: string) => {
    const newStatus = status === "private" ? "public" : "private";
    doAction(setOppStatusAction, {
      id: this.props.opportunityId,
      status: newStatus,
      opportunityInfo: this.props.opportunityInfo,
    });
  };

  private getDateInterval = (createdAt: any) => {
    const date = moment(createdAt);
    const now = moment();
    const duration = moment.duration(now.diff(date));
    return duration.days();
  };

  private navigateToDtl = (slug: string) => {
    console.log('navigateToDtl: '+slug)
    this.props.history.push({
      pathname: router_opportunity_detail.replace(":slug", slug),
    });
    this.initPageData(slug);
  };

  private renderSimiliarCard = (item: any, index: number) => {
    const {
      slug,
      media,
      canvasName,
      jobTitle,
      jobType,
      updatedAt,
      location,
      validForAllState
    } = item;
    return (
      <div
        className="similiar-card"
        key={index}
        onClick={() => {console.log("onClick slug: "+slug);this.navigateToDtl(slug)}}
      >
        <div className="image">
          <img
            src={
              (media && media.thumbPhoto && getOptimizedPath(media.thumbPhoto.image, 640)) ||
              IconFeatureEmpty
            }
            style={{
              objectFit: media.thumbPhoto.keepSize ? 'contain' : 'cover',
              ...(media.thumbPhoto.reposition? {
                objectPosition: `${media.thumbPhoto.reposition.left} ${media.thumbPhoto.reposition.top}`,
                objectFit: 'none'
                // ,width: `${media.thumbPhoto.reposition.width}`,height: `${media.thumbPhoto.reposition.height}`
              } : {})
            }}
            alt=""
          />
        </div>
        <div className="card-info-wrap">
          <div className="info-status">
            <div className="left-sec">
              <div className="type">{jobType}</div>
              <div className="date-status">New</div>
            </div>
            <div className="right-sec">
              Updated {this.getDateInterval(updatedAt)} days ago
            </div>
          </div>
          <div className="job-title">{jobTitle}</div>
          <div className="location-info" hidden={!location.address}>
            <LocationOnRoundedIcon className="card-icon" />
            <div className="location"> {validForAllState ? general_location_address : location.address}</div>
          </div>
          <div className="canvas-info" hidden={!canvasName}>
            <img src={IconCanvasName} alt="" className="card-icon" />
            {canvasName}
          </div>
        </div>
      </div>
    );
  };

  private jumpToLoginPage = () => {
    this.props.history.push({
      pathname: router_sign_in,
      search: `?from=${window.location.pathname}`,
    });
  };

  render() {
    const { isDetail, curPlayUrl, user, fromDashboard, isPreview } = this.state;
    const {
      about,
      mediaInfo,
      opportunityInfo,
      canvasDetails,
      history,
      opportunityId,
      bookmarkTag,
      modalParameters: {
        visible,
        title,
        desc,
        tips,
        applyBtnText,
        cancelText,
        onApply,
        onClose,
      },
      applyResultParam,
      poster,
      similarData,
      emptyFields,
    } = this.props;
    const isAdmin = isDetail && poster === Number(user.id);
    const isLoreal = !!lorealIds.has(this.props.canvasDetails?.id);
    const searchParams = new URLSearchParams(this.props.location.search);
    const isEmbedded = searchParams.get('isEmbedded') === 'true';
    return (
      <div
        className={classnames("opportunity-detail-root", {
          isDetail,
        }, { "cm-loreal": isLoreal })}
      >
        {isDetail && !isEmbedded && (
          <Layout className="home-root">
            <CRHeader
              className="home-header"
              history={history}
              onLogin={() => this.jumpToLoginPage()}
              onLogout={() => {
                doAction(LogOutAction);
                doFunction(async () => {
                  this.props.history.push("/");
                });
              }}
            />
          </Layout>
        )}
        <div className={classnames("content-wrap", { isDetail: isDetail })}>
          <div
            className="job-type mobile-type"
            hidden={!opportunityInfo.jobType.name}
          >
            {opportunityInfo.jobType.name || ""}
          </div>
          { isEmbedded && <div className="msg-sec"><button type="button" className="ant-btn msg-btn btn-secondary" onClick={() => history.goBack()}><span>Back</span></button></div> }
          <h1 className="opportunity-name">
            {opportunityInfo.jobTitle.name || ""}
          </h1>
          <div className="opportunity-subtitle">
            <div
              className="job-type pc-type"
              hidden={!opportunityInfo.jobType.name}
            >
              {opportunityInfo.jobType.name || ""}
            </div>
            <div
              className="location"
              hidden={!opportunityInfo.selectLocation.address}
            >
              <img alt="" src={IconLocation} />
              {opportunityInfo.validForAllState ? general_location_address : opportunityInfo.selectLocation.address || ""}
            </div>
            {!isEmbedded && <div
              className="canvas-name"
              hidden={!canvasDetails}
              onClick={(e) =>
                history.push(
                  router_canvas_detail.replace(
                    ":id",
                    canvasDetails.slug || canvasDetails.id
                  )
                )
              }
            >
              <div className="canvas-image-wrap">
                {canvasDetails &&
                  canvasDetails.media &&
                  canvasDetails.media.logo ? (
                    // <div
                    //   className="canvas-img"
                    //   style={{
                    //     backgroundImage: `url(${canvasDetails.media.logo.image})`,
                    //   }}
                    // />
                    <img alt="" className="canvas-img" src={canvasDetails.media.logo.image} style={{
                      // objectFit: canvasDetails.media.logo.keepSize? 'contain' : 'cover',
                      ...(canvasDetails.media.logo.reposition? {
                        objectPosition: `${canvasDetails.media.logo.reposition.left} ${canvasDetails.media.logo.reposition.top}`,
                        objectFit: 'none'
                        // ,width: `${canvasDetails.media.logo.reposition.width}`,height: `${canvasDetails.media.logo.reposition.height}`
                      } : {})
                    }} />
                  ) : (
                    <div className="canvas-empty">
                      <img alt="" src={IconFeatureEmpty} />
                    </div>
                  )}
              </div>
              {canvasDetails ? canvasDetails.introduction.name : ""}
            </div> }
          </div>
          <div className="msg-sec">
            <Button
              className="msg-btn btn-primary"
              onClick={async () => {
                if (getToken()) {
                  if (await this.checkValidApplyCanvas()) {
                    this.sendMyCanvas();
                  } else {
                    this.setState({ showNeedValidApplyCanvasModal: true })
                  }
                } else {
                  this.setState({ showSigninModal: true })
                }
              }}
            >
              <img alt="" src={IconSendCanvas} />
              Apply with my Portfolio
            </Button>
            <Button
              className="msg-btn btn-secondary"
              hidden={opportunityId === -1}
              onClick={() => {
                if (getToken()) {
                  doAction(addBookmarkAction);
                } else {
                  history.push({
                    pathname: router_sign_in,
                    search: `?from=${encodeURI(
                      window.location.pathname +
                      window.location.search.replace(/&/g, "￥")
                    )}`,
                  });
                }
              }}
            >
              <img alt="" src={IconBookmark} />
              {bookmarkTag ? "Remove Bookmark" : "Bookmark"}
            </Button>
            <Button
              className="msg-btn btn-secondary"
              hidden={
                !(mediaInfo.featuredVideo && (mediaInfo.featuredVideo.video || mediaInfo.featuredVideo.url))
              }
              onClick={() => {
                this.onVideoClickHandler()
              }}
            >
              <img alt="" src={IconBookmark} />
              Watch our video
            </Button>
          </div>
          <div className="featured-img-sec">
            {mediaInfo.featuredPhoto && mediaInfo.featuredPhoto.image ? (
              // <div
              //   className="featured-img"
              //   style={{
              //     backgroundImage: `url(${mediaInfo.featuredPhoto.image})`,
              //   }}
              // />
              <img alt="" className="featured-img" src={getOptimizedPath(mediaInfo.featuredPhoto.image, 1920)} style={{
                objectFit: mediaInfo.featuredPhoto.keepSize? 'contain' : 'cover',
                ...(mediaInfo.featuredPhoto.reposition? {
                  objectPosition: `${mediaInfo.featuredPhoto.reposition.left} ${mediaInfo.featuredPhoto.reposition.top}`,
                  objectFit: 'none'
                  // ,width: `${mediaInfo.featuredPhoto.reposition.width}`,height: `${mediaInfo.featuredPhoto.reposition.height}`
                } : {})
              }} />
            ) : (
                <div className="featured-empty">
                  <img alt="" src={IconFeatureEmpty} />
                Featured Image
                </div>
              )}
            <div className="btn-wrap" hidden={!isAdmin && isDetail}>
              {isDetail ? (
                isAdmin && (
                  <>
                    <Button
                      className="msg-btn btn-secondary"
                      onClick={() => {
                        history.push({
                          pathname: router_post_job_opp,
                          search: `?id=${canvasDetails.id
                            }&opportunityId=${opportunityId}&is_edit=${isPreview ? 1 : 0
                            }`,
                        });
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      className="msg-btn btn-primary"
                      onClick={() => this.setAsPrivate(opportunityInfo.status)}
                    >
                      {opportunityInfo && opportunityInfo.status === "private"
                        ? "Set as Public"
                        : "Set as Private"}
                    </Button>
                  </>
                )
              ) : (
                  <>
                    <Button
                      className="msg-btn btn-secondary"
                      onClick={() => {
                        history.push({
                          pathname: fromDashboard
                            ? router_dashboard_post_job_opp
                            : router_post_job_opp,
                          search: `?id=${canvasDetails.id}${opportunityId > -1
                            ? "&opportunityId=" + opportunityId
                            : ""
                            }&is_edit=${isPreview ? 1 : 0}&isInMyAccount=${fromDashboard ? "1" : "0"
                            }`,
                        });
                      }}
                    >
                      Back
                  </Button>
                    <Button
                      className="msg-btn btn-primary"
                      onClick={() => this.publish()}
                    >
                      Publish
                  </Button>
                  </>
                )}
            </div>
          </div>
          <div className="opportunity-info-wrap">
            <div className="left-wrap">
              <h1 className="info-title">Opportunity description</h1>
              <div className="job-desc">
                <div
                  dangerouslySetInnerHTML={{ __html: opportunityInfo.editor }}
                />
              </div>
            </div>
            <div className="right-wrap">
              <h1 className="info-title">Details</h1>
              {this.renderDetails()}
              <div
                className="feature-vedio-wrap"
                hidden={
                  !(mediaInfo.featuredVideo && (mediaInfo.featuredVideo.video || mediaInfo.featuredVideo.url))
                }
              >
                <div className="info-title">Featured Video</div>
                <div className="main-video">
                  {mediaInfo.featuredVideo && (mediaInfo.featuredVideo.video || mediaInfo.featuredVideo.url) ? (
                    <div
                      className="featured-video-cover"
                    // style={{
                    //   backgroundImage: `url(${mediaInfo.featuredVideo.image})`,
                    // }}
                    >
                      {mediaInfo.featuredVideo.image ||
                        (mediaInfo.featuredPhoto &&
                          mediaInfo.featuredPhoto.image) ? (
                          <img
                            alt=""
                            src={
                              mediaInfo.featuredVideo.image ||
                              mediaInfo.featuredPhoto.image
                            }
                            className="inner-img"
                          />
                        ) : (
                          <span className="video-empty-image">
                            {canvasDetails &&
                              canvasDetails.introduction &&
                              !_.isEmpty(canvasDetails.introduction.name) &&
                              canvasDetails.introduction.name[0]}
                          </span>
                        )}
                      {(mediaInfo.featuredVideo.video ||
                        mediaInfo.featuredVideo.url) && (
                          <img
                            alt=""
                            className="play"
                            src={IconPlayWhite}
                            onClick={() => {
                              this.onVideoClickHandler()
                            }}
                          />
                        )}
                    </div>
                  ) : (
                      <div className="empty-video">
                        <img alt="" src={IconFeatureEmpty} />
                      Featured Video
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          {!getToken() && 
            <div className="how-to-apply">
              <h1>How to apply</h1>
              <div className="images">
                <div className="image">
                  <img alt="Step 1" src={Step1} />
                  <p>Create your free<br/>BEAUTISTA portfolio</p>
                </div>
                <div className="image">
                  <img alt="Step 2" src={Step2} />
                  <p>Build your<br/>portfolio</p>
                </div>
                <div className="image">
                  <img alt="Step 3" src={Step3} />
                  <p>Click View<br/>Opportunities</p>
                </div>
                <div className="image">
                  <img alt="Step 4" src={Step4} />
                  <p>Browse for<br/>this opportunity</p>
                </div>
                <div className="image">
                  <img alt="Step 5" src={Step5} />
                  <p>Simply click<br/>Apply with my Portfolio</p>
                </div>
              </div>
              <Button className="btn-primary" onClick={() => redirectToGetStarted()}>Create Your Free Account</Button>
            </div>
          }
          <div className="company-info-wrap">
            <div className="left-wrap">
              <div className="company-info">
                <h1 className="company-name">
                  {canvasDetails && canvasDetails.introduction.name}
                </h1>
                <div className="company-image-wrap mobile-image">
                  {mediaInfo.companyPhoto && mediaInfo.companyPhoto.image ? (
                    // <div
                    //   className="company-img"
                    //   style={{
                    //     backgroundImage: `url(${mediaInfo.companyPhoto.image})`,
                    //   }}
                    // />
                    <img alt="" className="company-img" src={getOptimizedPath(mediaInfo.companyPhoto.image, 640)} style={{
                      objectFit: mediaInfo.companyPhoto.keepSize? 'contain' : 'cover',
                      ...(mediaInfo.companyPhoto.reposition? {
                        objectPosition: `${mediaInfo.companyPhoto.reposition.left} ${mediaInfo.companyPhoto.reposition.top}`,
                        objectFit: 'none'
                        // ,width: `${mediaInfo.companyPhoto.reposition.width}`,height: `${mediaInfo.companyPhoto.reposition.height}`
                      } : {})
                    }} />
                  ) : (
                      <div className="company-empty">
                        <img alt="" src={IconFeatureEmpty} />
                      </div>
                    )}
                </div>
                <pre className="company-desc">
                  <div
                    dangerouslySetInnerHTML={{ __html: about || canvasDetails?.introduction?.description }}
                  />
                </pre>
                <div className="additional-info-wrap">
                  <div className="tagline">{this.renderAdditionalInfo()}</div>
                </div>
              </div>
            </div>
            <div className="right-wrap">
              <div className="company-image-wrap pc-image">
                {mediaInfo.companyPhoto && mediaInfo.companyPhoto.image ? (
                  // <div
                  //   className="company-img"
                  //   style={{
                  //     backgroundImage: `url(${mediaInfo.companyPhoto.image})`,
                  //   }}
                  // />
                  <img alt="" className="company-img" src={getOptimizedPath(mediaInfo.companyPhoto.image, 640)} style={{
                    objectFit: mediaInfo.companyPhoto.keepSize? 'contain' : 'cover',
                    ...(mediaInfo.companyPhoto.reposition? {
                      objectPosition: `${mediaInfo.companyPhoto.reposition.left} ${mediaInfo.companyPhoto.reposition.top}`,
                      objectFit: 'none'
                      // ,width: `${mediaInfo.companyPhoto.reposition.width}`,height: `${mediaInfo.companyPhoto.reposition.height}`
                    } : {})
                  }} />
                ) : (
                    <div className="company-empty">
                      <img alt="" src={IconFeatureEmpty} />
                    </div>
                  )}
              </div>
            </div>
          </div>
          {/* {isDetail && !isAdmin && (
            <div className="similars-wrap" hidden={similarData.length === 0}>
              <div className="similiar-title">Related Jobs</div>
              <div className="similiar-list-wrap">
                {similarData.length &&
                  similarData.map((item: any, index: number) => {
                    return this.renderSimiliarCard(item, index);
                  })}
              </div>
            </div>
          )} */}
          {isDetail && !isEmbedded && <CRFooter history={history} isHome={true} />}
        </div>
        {!_.isEmpty(curPlayUrl) && (
          <VideoModal
            visible
            onClose={() => this.onCloseHandler() }
            url={curPlayUrl}
          />
        )}
        <OpportunityApplyModal
          visible={visible}
          title={title}
          desc={desc}
          tips={tips}
          applyBtnText={applyBtnText}
          cancelText={cancelText}
          onApply={(msg: string) => onApply && onApply(msg)}
          onClose={() => onClose && onClose()}
          clickResultBtn={() => this.clickApplyResultBtn()}
          closeResultModal={() => {
            doAction(setApplyResultParamAction, { resultVisible: false });
          }}
          // applyResultParam={applyResultParam} // hide result modal
          applyResultParam={{ resultVisible: false }} // hide result modal
          applyInfoParam={{
            jobTitle:
              (opportunityInfo &&
                opportunityInfo.jobTitle &&
                opportunityInfo.jobTitle.name) ||
              "",
            companyName:
              (canvasDetails &&
                canvasDetails.introduction &&
                canvasDetails.introduction.name) ||
              "",
            userName: `${(this.state.user.profile && this.state.user.profile.firstName) ||
              ""
              } ${(this.state.user.profile && this.state.user.profile.lastName) ||
              ""
              }`,
          }}
        />

        <FieldRequireModal
          onClose={() => doAction(UpdateBaseInfoAction, { emptyFields: [] })}
          visible={!_.isEmpty(emptyFields)}
          data={emptyFields}
        />
        <Modal
          visible={this.state.showApplyRedirect}
          maskClosable={false}
          // title="Go External oppotunity Link"
          width={460}
          onCancel={e => this.setState({ showApplyRedirect: false })}
          centered
          footer={null}
          className="redirect-modal"
        >
          <div className="link-tips">{this.props.opportunityInfo.jobTitle.name} has two parts to the application process. Please complete part two of this application by clicking the button below.</div>
          <div className="link-btns">
            <Button
              className="btn-primary"
              onClick={
                e => {
                  this.setState({ showApplyRedirect: false })
                  let url = this.props.opportunityInfo.applyUrl
                  if (!url.startsWith('http')) {
                    url = 'https://' + url
                  }
                  window.location.href = url
                }
              }
            >
              Take me there
            </Button>
            <Button
              className="btn-secondary"
              onClick={
                e => this.setState({ showApplyRedirect: false })
              }
            >Cancel</Button>
          </div>
        </Modal>
        <Modal
          centered
          visible={this.state.showNeedValidApplyCanvasModal}
          width={460}
          onCancel={e => this.setState({ showNeedValidApplyCanvasModal: false })}
          footer={null}
          className="redirect-modal"
        >
          <div className="link-tips">
            <b>Please create your free Student or Professional portfolio to apply for this opportunity</b> 
            <div className="link-btns">
              <Button
                className="btn-primary"
                onClick={
                  e => {
                    this.setState({ showNeedValidApplyCanvasModal: false })
                    history.push({
                      pathname: router_my_canvases,
                    });
                  }
                }
              >
                Create Portfolio
              </Button>
            </div>
            <br />then return to this page to apply!
            <br /><b>{window.location.href}</b>
          </div>
        </Modal>
        <Modal
          centered
          visible={this.state.showSigninModal}
          width={460}
          onCancel={e => this.setState({ showSigninModal: false })}
          footer={null}
          className="redirect-modal"
        >
          <div className="link-tips">Please create your free account or login to apply for this opportunity.</div>
          <div className="link-btns">
            <Button
              className="btn-primary"
              onClick={
                e => {
                  this.setState({ showSigninModal: false })
                  history.push({
                    pathname: router_sign_in,
                    search: `?from=${window.location.pathname}`,
                  });
                }
              }
            >
              Sign in
            </Button>
            <Button
              className="btn-secondary"
              onClick={
                e =>{
                  this.setState({ showSigninModal: false })
                  redirectToGetStarted()
                } 
              }
            >Sign Up</Button>
          </div>
        </Modal>
      </div>
    );
  }
}
