import { Button, Modal, Spin, notification } from "antd";
import _ from "lodash";
import React from "react";
import {
  getPaymentInfo,
  listInvoice,
  listPayOrder,
  changePrice,
  resumePlan,
  acceptPromotionOffer,
  declinePromotionOffer,
  retryPayment,
} from "../../../api/Pay";
import { getCustomized } from "../../../api/Options";
import View from "../../../assets/images/ic_view.svg";
import Coupon from "../../../assets/images/ic_coupon.svg";
import {
  IBodyItem,
  IHeadItem,
  ListTable,
} from "../../../components/component_list_table";
import PreviewCard from "../../../components/component_preview_card";
import "./membership.less";
import {
  router_membership_invoice_list,
  router_membership_billing_update,
  router_membership_plan_update,
  router_membership_invoice_view,
  ActiveCampaignCancelReason,
} from "../../../utils/enum";
import { previewAddress } from "../../../utils/utils";
import moment from "moment";
import { Link } from "react-router-dom";
import IconDelete from "../../../assets/images/ic_delete.svg";
import IconUpdate from "../../../assets/images/ic_refresh.svg";
import IconPaymentMethod from "../../../assets/images/ic_payment_method.svg";
import {
  LoginWithTokenAction,
  modle_user,
} from "../../../models/model_user";
import { mapProp, doAction } from "module-reaction";
import { TextField, MenuItem } from "@material-ui/core";
import { default as april } from "../../../assets/images/april.png";
import './membership_user.less';

@mapProp(modle_user)
export class MembershipUser extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      orderList: null,
      downgradeList: null,
      promotion: null,
      paymentInfo: null,
      orderHistory: null,
      visibledelete: false,
      deleteId: null,
      isMobile: window.innerWidth <= 1024,
      stateOptions: [],
      cancelReason: false,
      consultation: true,
      cancelNotPicked: false,
      resumeMembership: false,
      resumeMembershipId: null,
      resumeMembershipLoading: false,
      promotionAcceptLoading: false,
      promotionDeclineLoading: false,
    };
    this.handleStateChange = this.handleStateChange.bind(this);
    this.checkForCancelReason = this.checkForCancelReason.bind(this);
    this.onPaymentHistoryClick = this.onPaymentHistoryClick.bind(this);
    this.onBillingUpdateClick = this.onBillingUpdateClick.bind(this);
  }

  handleStateChange(key: string, value: any) {
    if (key === "cancelReason") {
      this.handleStateChange("cancelNotPicked", false);
    }
    this.setState({ [key]: value });
  }

  checkForCancelReason() {
    if (this.state.cancelReason === false) {
      this.handleStateChange("cancelNotPicked", true);
    } else {
      this.handleStateChange("cancelNotPicked", false);
      this.deletePlan();
    }
  }

  public componentDidMount() {
    this.getStateOptions();
    this.getPayOrderList();
    this.getInvoiceList();
    this.getPaymentInformation();
    if (!this.props.curUser.profile) {
      doAction(LoginWithTokenAction);
    }
  }

  private deletePlan() {
    const postParams: any = {
      canvas_id: this.state.deleteId,
      is_cancel: true,
      cancel_reason: this.state.cancelReason,
    };
    changePrice(postParams)
      .then((res) => {
        notification.success({ message: 'Plan cancelled successfully, you can still use service until end of current period!' });
        this.setState({
          visibledelete: false,
          submitLoading: false,
        });
        this.getPayOrderList();
      })
      .catch((err) => {
        notification.error({ message: 'Plan did not cancel. Please contact support with this message: ' + err.message });
        this.setState({ submitLoading: false })
      });
  }

  private membershipDetails() {
    const { orderList, downgradeList, failedPaymentList, isMobile } = this.state;

    let content;

    const tHeadRenderFailedPayment: IHeadItem[] = [
      { name: "Item" },
      { name: "Canvas Name" },
      { name: "No. Opportunities" },
      { name: "Billing Period" },
      { name: "Past due since" },
      { name: "Total Amount" },
      { name: "Actions" },
    ];

    const tBodyRenderFailedPayment: IBodyItem[] = [
      {
        name: "Item",
        component: (item: any) => _.capitalize(item.category.replace("_", " + ").replace("six_month", "job")),
      },
      { name: "Canvas Name", key: "canvasName" },
      { name: "No. Opportunities", key: "maxOpp" },
      { name: "Billing Period", key: "billingPeriod" },
      { name: "Past due since", key: "nextBillingDate", type: "date" },
      { name: "Total Amount", key: "amount", type: "amount" },
      {
        key: "actions",
        type: "dropdown-actions",
        dropdownList: [
          {
            icon: IconUpdate,
            name: "Retry Payment",
            onClick: (item: any) => {
              this.handleRetryPayment(item);
            },
          },
          {
            icon: IconPaymentMethod,
            name: "Update Payment Method",
            onClick: () => {
              this.props.history.push(router_membership_billing_update);
            },
          },
          {
            icon: IconDelete,
            name: "Cancel Subscription",
            onClick: (item: any) => {
              this.setState({ visibledelete: true, deleteId: item.canvasId });
            },
          },
        ],
      },
    ];

    const tHeadRenderDowngrade: IHeadItem[] = [
      { name: "Item" },
      { name: "Canvas Name" },
      { name: "No. Opportunities" },
      { name: "Billing Period" },
      { name: "Plan Ending" },
      { name: "Total Amount" },
      { name: "Actions" },
    ];

    const tBodyRenderDowngrade: IBodyItem[] = [
      {
        name: "Item",
        component: (item: any) => _.capitalize(item.category.replace("_", " + ").replace("six_month", "job")),
      },
      { name: "Canvas Name", key: "canvasName" },
      { name: "No. Opportunities", key: "maxOpp" },
      { name: "Billing Period", key: "billingPeriod" },
      { name: "Plan Ending", key: "nextBillingDate", type: "date" },
      { name: "Total Amount", key: "amount", type: "amount" },
      {
        key: "actions",
        type: "dropdown-actions",
        dropdownList: [
          {
            icon: IconUpdate,
            name: "Resume plan",
            onClick: (item: any) => {
              this.onPlanResumeClick(item);
            },
          },
        ],
      },
    ];

    const tHeadRender: IHeadItem[] = [
      { name: "Item" },
      { name: "Canvas Name" },
      { name: "No. Opportunities" },
      { name: "Billing Period" },
      { name: "Next Billing Date" },
      { name: "Total Amount" },
      { name: "Actions" },
    ];

    const dropdownList = [
      {
        icon: IconDelete,
        name: "Cancel Membership",
        onClick: (item: any) => {
          this.setState({ visibledelete: true, deleteId: item.canvasId });
        },
      },
    ];

    const tBodyRender: IBodyItem[] = [
      {
        name: "Item",
        component: (item: any) => _.capitalize(item.category.replace("_", " ").replace("job", "").replace("six_month", "").replace("one_year", "")),
      },
      { name: "Canvas Name", key: "canvasName" },
      { name: "No. Opportunities", key: "maxOpp" },
      { name: "Billing Period", key: "billingPeriod" },
      { name: "Next Billing Date", key: "nextBillingDate", type: "date" },
      { name: "Total Amount", component: (item: any) => <>${(item.amount).toFixed(2)}{item.coupon && <img className="coupon" alt="coupon" src={Coupon} />}</> },
      {
        key: "actions",
        type: "dropdown-actions",
        dropdownList: dropdownList,
      },
    ];

    content = (
      <>
        {this.renderPromotionSection()}
        <ListTable
          tableClassName="noHeaderSelect"
          dataList={orderList || []}
          tHeadRender={tHeadRender}
          tBodyRender={tBodyRender}
          total={(orderList || []).length}
          size={(orderList || []).length}
          loading={!orderList}
          noDataTips={failedPaymentList && failedPaymentList.length > 0 ? `Please update payment method for the following subscription ${failedPaymentList.length > 1 ? "s" : ""} below` : "You are currently not subscribed to any paid memberships."}
          hasHeader={false}
          hasSelect={false}
          isMobile={isMobile}
        />
        {failedPaymentList && failedPaymentList.length > 0 && (
          <>
            <ListTable
              tableClassName="noHeaderSelect"
              dataList={failedPaymentList || []}
              tHeadRender={tHeadRenderFailedPayment}
              tBodyRender={tBodyRenderFailedPayment}
              total={(failedPaymentList || []).length}
              size={(failedPaymentList || []).length}
              loading={!failedPaymentList}
              noDataTips={"You have no failed payments."}
              hasHeader={false}
              hasSelect={false}
              isMobile={isMobile}
            />
          </>
        )}

        {downgradeList && downgradeList.length > 0 && (
          <>
            <div className="downgrade-title"></div>
            <div className="downgrade-desc">
              You have opted to cancel the following service{downgradeList.length > 1 ? "s" : ""}
            </div>
            <ListTable
              tableClassName="noHeaderSelect"
              dataList={downgradeList || []}
              tHeadRender={tHeadRenderDowngrade}
              tBodyRender={tBodyRenderDowngrade}
              total={(downgradeList || []).length}
              size={(downgradeList || []).length}
              loading={!downgradeList}
              noDataTips={"You are currently not subscribed to any paid memberships."}
              hasHeader={false}
              hasSelect={false}
              isMobile={isMobile}
            />
          </>
        )}
      </>
    );

    return (
      <section>
        <h2 className="common-title">Membership Details</h2>
        {content}
      </section>
    );
  }

  private renderPromotionSection() {
    const { promotion, isMobile } = this.state;

    const promotionList = promotion && Array.isArray(promotion)
      ? promotion.flatMap(item => {
        const { canvasId, canvasName, nextBillingDate, promotions } = item;
        return Object.values(promotions || {}).map(promo => ({
          ...(promo as Record<string, any>),
          canvasId,
          canvasName,
          nextBillingDate,
        }));
      })
      : [];

    if (!promotion || promotionList.length === 0) {
      console.log('no promotion');
      return null;
    }

    const tHeadRenderPromotion: IHeadItem[] = [
      { name: "Promotion" },
      { name: "Canvas Name" },
      { name: "Expiration Date" },
      { name: "Actions" },
    ];

    const tBodyRenderPromotion: IBodyItem[] = [
      {
        name: "Promotion",
        component: (item: any) => (
          <span>{item.text}</span>
        ),
      },
      { name: "Canvas Name", key: "canvasName" },
      {
        name: "Expiration Date",
        key: "nextBillingDate",
        type: "date",
      },
      {
        key: "actions",
        type: "component",
        component: (item: any) => (
          <>
            <Button
              type="primary"
              style={{ fontSize: '1em' }}
              onClick={() => this.handlePromotionAccept(item)}
              loading={this.state.promotionAcceptLoading}
            >
              Accept
            </Button>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
            <Button
              type="primary"
              style={{ fontSize: '1em' }}
              onClick={() => this.handlePromotionDecline(item)}
              loading={this.state.promotionDeclineLoading}
            >
              Decline
            </Button>
          </>
        ),
      },
    ];

    return (
      <div className="promotion-section">
        <div className="promotion-banner">
          <h3>Exclusive Offer Just for You!</h3>
          <p>
            Continue your subscription before it expires and get 1 year with 6 months free!
          </p>
        </div>
        <ListTable
          tableClassName="noHeaderSelect promotion-table"
          dataList={promotionList}
          tHeadRender={tHeadRenderPromotion}
          tBodyRender={tBodyRenderPromotion}
          total={promotionList.length}
          size={promotionList.length}
          loading={!promotion}
          noDataTips={"No promotions available at this time."}
          hasHeader={false}
          hasSelect={false}
          isMobile={isMobile}
        />
      </div>
    );
  }

  private handlePromotionAccept(item: any) {
    console.log('item', item)
    this.setState({ promotionAcceptLoading: true });
    acceptPromotionOffer(item.id)
      .then((res: any) => {
        if (res.success) {
          notification.success({ message: 'Promotion accepted successfully!' });
          this.getPayOrderList();
        } else {
          notification.error({ message: 'Could not accept promotion. Please contact support.' });
        }
      })
      .catch((err) => {
        notification.error({ message: 'Error accepting promotion: ' + err.message });
      })
      .finally(() => {
        this.setState({ promotionAcceptLoading: false });
      });
  }

  private handlePromotionDecline(item: any) {
    this.setState({ promotionDeclineLoading: true });
    declinePromotionOffer(item.id)
      .then((res: any) => {
        if (res.success) {
          notification.success({ message: 'Promotion declined successfully!' });
          this.getPayOrderList();
        } else {
          notification.error({ message: 'Could not decline promotion. Please contact support.' });
        }
      })
      .catch((err) => {
        notification.error({ message: 'Error declining promotion: ' + err.message });
      })
      .finally(() => {
        this.setState({ promotionDeclineLoading: false });
      });
  }

  private billingInfo() {
    const paymentInfo = this.state.paymentInfo;
    const address = _.get(paymentInfo, "billing_details.address") || {};
    const addressString = previewAddress(this.state.stateOptions, address);

    const pmType = _.get(paymentInfo, "type");
    const hasCardData = _.has(paymentInfo, "card");
    const hasBankData = _.has(paymentInfo, "us_bank_account");
    const isCard = pmType === "card" || hasCardData;
    const isBank = pmType === "us_bank_account" || hasBankData;

    const brandOrBank = isBank
      ? _.get(paymentInfo, "us_bank_account.bank_name", "Bank")
      : _.get(paymentInfo, "card.brand", "Card");

    const last4 = isBank
      ? _.get(paymentInfo, "us_bank_account.last4", "")
      : _.get(paymentInfo, "card.last4", "");

    const previewBillInfo: any = paymentInfo && paymentInfo.paymentMethod ? [
      {
        label: "Payment method",
        value: isBank ? "Bank Account" : "Card",
      },
      {
        label: "EMAIL",
        value: _.get(paymentInfo, "email", ""),
      },
      {
        label: `${brandOrBank} ending`,
        value: `...${last4}`,
      },
      {
        label: "Billing Address",
        value: addressString,
      },
      {
        label: "Name",
        value: _.get(paymentInfo, "billing_details.name", ""),
      },
    ] : [];

    const content = (
      <PreviewCard
        previewData={previewBillInfo}
        column={2}
        loading={!paymentInfo}
        noDataTips={"You do not have a payment method on file."}
      />
    );

    return (
      <section>
        <h2 className="common-title">Billing Information</h2>
        {content}
        <Button className="btn-secondary" onClick={this.onBillingUpdateClick}>
          Update Billing Info
        </Button>
      </section>
    );
  }


  private paymentHistory() {
    let content;
    const { orderHistory, isMobile } = this.state;

    const tHeadRender: IHeadItem[] = [
      { name: "Invoice Number" },
      { name: "Item" },
      { name: "No. Opportunities" },
      { name: "Billing Period" },
      { name: "Date Paid" },
      { name: "Total Amount" },
      { name: "Actions" },
    ];

    const tBodyRender: IBodyItem[] = [
      { name: "Invoice Number", key: "number" },
      { name: "Item", key: "canvasName" },
      { name: "Number", key: "maxOpp" },
      { name: "Billing Period", key: "billingPeriod" },
      {
        name: "Date Paid",
        component: (row: any) => <>{moment(row.paidDate).format("l")}</>,
      },
      { name: "Total Amount", key: "amount", type: "amount" },
      {
        key: "action",
        type: "link-actions",
        component: (row: any) => (
          <Link to={router_membership_invoice_view.replace(":id", row.id)}>
            <img src={View} alt="View" />
          </Link>
        ),
      },
    ];

    content = (
      <ListTable
        tableClassName="noHeaderSelect payment"
        dataList={orderHistory || []}
        tHeadRender={tHeadRender}
        tBodyRender={tBodyRender}
        total={(orderHistory || []).length}
        size={(orderHistory || []).length}
        loading={!orderHistory}
        noDataTips={"You are currently not subscribed to any paid memberships."}
        hasHeader={false}
        hasSelect={false}
        isMobile={isMobile}
      />
    );
    return (
      <section>
        <h2 className="common-title">Payment History</h2>
        {content}
        <Button onClick={this.onPaymentHistoryClick} className="btn-secondary">
          View More
        </Button>
      </section>
    );
  }

  private async getStateOptions() {
    const options = await getCustomized(true, ["state"]);
    this.setState({
      stateOptions: options.state,
    });
  }

  private async getPayOrderList() {
    const payOrders: any = await listPayOrder();

    const orderList = _.filter(payOrders, (item: any) => {
      return item.downgradeDate == null && !item.promotion && item.status === "succeeded";
    });

    const downgradeList = _.filter(payOrders, (item: any) => {
      return item.downgradeDate !== null && !item.promotion;
    });

    const promotion = _.filter(payOrders, (item: any) => {
      return item.promotions !== null && item.promotions["0"].id !== null;
    });

    const failedPaymentList = _.filter(payOrders, (item: any) => {
      return item.status !== "succeeded";
    });

    this.setState({
      orderList,
      downgradeList,
      promotion,
      failedPaymentList,
    });
  }

  private async getInvoiceList() {
    const orderHistory = await listInvoice({});
    this.setState({
      orderHistory,
    });
  }

  private async getPaymentInformation() {
    const paymentInfo = await getPaymentInfo();
    this.setState({
      paymentInfo,
    });
  }

  private onPaymentHistoryClick(e: React.MouseEvent) {
    e.preventDefault();
    this.props.history.push(router_membership_invoice_list);
  }

  private onBillingUpdateClick(e: React.MouseEvent) {
    e.preventDefault();
    this.props.history.push(router_membership_billing_update);
  }

  private onPlanUpdateClick(item: any) {
    if (item.canvasId) {
      this.props.history.push(
        router_membership_plan_update
          .replace(":type", item.category)
          .replace(":id", item.canvasId)
      );
    }
  }

  private onPlanResumeClick(item: any) {
    if (item.canvasId) {
      this.setState({
        resumeMembership: true,
        resumeMembershipId: item.canvasId,
      })
    }
  }

  private async handleRetryPayment(item: any) {
    const paymentDetails: any = await retryPayment(item);
    console.log('paymentDetails', paymentDetails)
    if (paymentDetails.success) {
      notification.success({ message: 'Payment successfully processed!' });
      this.getPayOrderList();
    } else {
      notification.error({ message: 'Payment did not retry. Please update your payment method and try again. If the issue persists, please contact support.' });
    }
  }

  private resumePlanAction() {
    this.setState({ resumeMembershipLoading: true });
    const postParams: any = {
      canvas_id: this.state.resumeMembershipId,
    };
    resumePlan(postParams)
      .then((res: any) => {
        if (res.success) {
          notification.success({ message: 'Plan resumed successfully' });
          this.setState({ resumeMembership: false });
        } else {
          notification.error({ message: 'Please contact support with this message: ' + res.message });
        }
        this.getPayOrderList();
      })
      .catch((err) => {
        notification.error({ message: 'Plan did not resume. Please contact support with this message: ' + err.message });
      })
      .finally(() => {
        this.setState({ resumeMembershipLoading: false })
      });
  }

  public render() {
    return (
      <div className="cm-membership-user">
        {this.membershipDetails()}
        {this.billingInfo()}
        {this.paymentHistory()}
        <Modal
          footer={null}
          visible={this.state.visibledelete}
          centered={true}
          onCancel={() => this.setState({ visibledelete: false })}
          className="payment-contact-success-modal"
          width="450px"
        >
          <div className="common-title">Are you sure?</div>
          {this.state.consultation ? (
            <>
              <div className="modal-txt">Hi, we are sorry to see you go!</div>
              <div className="modal-txt">
                Before you proceed, we want you to book a{" "}
                <b>FREE consultation with a BEAUTISTA Pro</b> to help you
                maximize your results!
              </div>
              <img alt="April" className="april-img" src={april} />
              <div className="lets-chat">Let's Chat!</div>
              <a
                href="https://calendly.com/april-canvasme/30mincanvasme"
                target="blank"
              >
                <Button type="primary">Book my FREE consultation!</Button>
              </a>
              <a
                className="txt-btn"
                onClick={() => {
                  this.handleStateChange("consultation", false);
                }}
              >
                Continue to Cancel
              </a>
            </>
          ) : (
            <>
              <div className="intro">
                To help us improve the BEAUTISTA platform, please tell us why
                you want to cancel:
                <br />
                <br />
                <TextField
                  select
                  key="cancel-reason"
                  label="Why do you want to cancel?"
                  variant="filled"
                  value={this.state.cancelReason || ""}
                  onChange={(e) => {
                    this.handleStateChange("cancelReason", e.target.value);
                  }}
                  className="material-input input cancel-dropdown"
                  error={this.state.cancelNotPicked}
                  helperText={
                    this.state.cancelNotPicked
                      ? "Please choose a reason for cancelling."
                      : ""
                  }
                >
                  <MenuItem value={ActiveCampaignCancelReason.DO_NOT_KNOW_HOW}>
                    Don't know how to use this site
                  </MenuItem>
                  <MenuItem value={ActiveCampaignCancelReason.NOT_ENOUGH_APPLICANTS}>
                    Didn't get enough applicants
                  </MenuItem>
                  <MenuItem value={ActiveCampaignCancelReason.NO_TIME_TO_BUILD_PAGE}>
                    Don't have time to build out my page
                  </MenuItem>
                  <MenuItem value={ActiveCampaignCancelReason.COST}>
                    Cost
                  </MenuItem>
                  <MenuItem value="im_not_hiring">
                    I'm not hiring
                  </MenuItem>
                  <MenuItem value="other">
                    Other
                  </MenuItem>
                </TextField>
                <br />
                <br />
              </div>
              <Button
                type="primary"
                onClick={() => this.checkForCancelReason()}
                loading={this.state.submitLoading}
              >
                Cancel Membership
              </Button>
              <a
                className="txt-btn"
                onClick={() => {
                  this.handleStateChange("consultation", true);
                }}
              >
                I want to stay and talk to a Pro for FREE!
              </a>
            </>
          )}
        </Modal>
        <Modal
          footer={null}
          visible={this.state.resumeMembership}
          centered={true}
          onCancel={() => this.setState({ resumeMembership: false })}
          className="payment-contact-success-modal resume-membership-modal"
          width="450px"
        >
          <h2>Resume Membership</h2>
          <p>Click Resume Membership to revert pending cancelation</p>
          <Button
            type="primary"
            onClick={() => this.resumePlanAction()}
            loading={this.state.resumeMembershipLoading}
            disabled={this.state.resumeMembershipLoading}
          >
            Resume Membership {this.state.resumeMembershipLoading && <Spin />}
          </Button>
        </Modal>
      </div>
    );
  }
}
