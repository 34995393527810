import React from "react";
import { Layout, Button, Icon, Checkbox, Modal } from "antd";
import _ from "lodash";
import classnames from "classnames";
import { IAward } from "../../components/component_award_item";
import { ISocialMedia } from "../../components/component_social_media_item";
import ConfirmModal from "../../components/component_confirm_modal";
import { IEmployment } from "../../components/component_employment_item";
import CRTextArea from "../../components/component_cr_textarea";
import CanvasPreview, { PositionTag, PreviewMode } from "./canvas_preview";
import { v4 as uuidv4 } from "uuid";
import AddressTextArea from "../../components/component_address_text_area";
import FieldRequireModal from "../../components/component_field_require_modal";
import AddAwardModal from "../../components/component_add_award_modal";
import EditCategoryModal from "../../components/component_edit_category_modal";
import EditEmploymentModal from "../../components/component_edit_employment_modal";
import EditPhotoModal from "../../components/component_edit_photo_modal";
import MediaItem from "../../components/component_media_item";
import AwardItem from "../../components/component_award_item";
import CategoryItem from "../../components/component_category_item";
import EmploymentItem from "../../components/component_employment_item";
import SocialMediaItem from "../../components/component_social_media_item";
import CRExpansionPanel from "../../components/component_expansion_panel";
import CreateBusinessCanvasHeader from "../../components/component_create_header";
import LoadingModal from "../../components/component_loading_modal";
import { TextField } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import queryString from "query-string";
import { IMediaItem } from "../../components/component_media_item";
import EducationBackground from "../../components/component_education_background";
import { mapProp, doAction, doFunction, getModuleProp } from "module-reaction";
import FileUploader from "../../components/component_file_uploader";
import IconAward from "../../assets/images/ic_award.svg";
import IconEmploy from "../../assets/images/ic_empty_employ.svg";
import IconUpload from "../../assets/images/ic_upload_green.svg";
import IconAwardAdd from "../../assets/images/ic_award_add.svg";
import IconDelete from "../../assets/images/ic_delete.svg";
import {
  module_student_canvas,
  MODULE_STUDENT_CANVAS,
  UpdatePageInfoAction,
  GetSelectionsAction,
  UploadFileAction,
  DeleteMediaAction,
  EditPortfolioPhotoAction,
  EditAwardAction,
  DeleteAwardAction,
  EditEmploymentAction,
  DeleteEmploymentAction,
  SocialMediaInputAction,
  SubmitToPreviewAction,
  GetPageDataAction,
  AutoSaveAction,
  SaveAsDraftAction,
  SubmitToPublishAction,
  GetUserInfoAction,
  educationItem,
  DisconnectInstaAction,
  ReorderPortfolioImages,
  ReorderPortfolioVidoes
} from "../../models/model_student_canvas";
import { searchSchool } from "../../api/Options";
import { MediaType, CanvasType } from "../../utils/enum";
import { RouteComponentProps } from "react-router";
import { global_router } from "../../routers";
import { putInstInfo } from "../../api/Insta";
import { checkOverlay, localStorageGet, localStorageSet, parseYoutubeUrl } from "../../utils/utils";
import { getInviteSchool } from "../../api/School";
import "./student_canvas.less";
import { getMediaInfo } from "../../utils/mediaHelper";
import { EditorComponent } from "../../components/component_editor";
import { CANVAS_AUTOSAVE_INTERVAL_MILLISECONDS } from "../../utils/constants";
import { CanvasLink } from "../../components/component_canvas_link";
import GetStartedOverlay from "../onboarding/get_start_overlay";
import WorkMediaSection from "./work_media_section";
import MenuItem from "@material-ui/core/MenuItem";
import IconEdit from "../../assets/images/ic_edit.svg";
import { EditEducationModal } from "../../components/component_edit_education_modal";
import DeleteCanvasModal from "../../components/component_delete_canvas_modal";
import { DeleteCanvasAction } from "../../models/model_my_canvas";
import { checkBookingLink } from "../../utils/validator";


const { Content } = Layout;
declare var google: any;

interface StudentCanvasProps extends RouteComponentProps {
  history: any;
  match: any;
  type: string;
  //selections
  relocateOptions: any[];
  preferredBrandsOptions: any[];
  specialityOptions: any[];
  softwareOptions: any[];
  paymentOptions: any[];
  programOptions: any[];
  stateIssueList: any[];
  categoryList: any[];
  //pagedata
  id: number;
  slug: string;
  name: string;
  pronouns: string;
  title: string;
  experience: number;
  location: any;
  showDesc: boolean;
  willingToRelocate: boolean;
  opportunityStatus: boolean;
  showOpportunityStatus: boolean;
  relocateList: any[];
  aboutMe: string;
  why: string;
  logo: IMediaItem;
  featuredPhoto: IMediaItem;
  portraitPhoto: IMediaItem;
  featuredVideo: IMediaItem;
  portfolioPhotos: IMediaItem[];
  extraVideo: IMediaItem[];
  awardList: IAward[];
  preferredBrands: any[];
  specialities: any[];
  softwares: any[];
  kpi: any;
  educations: any[];
  categories: any[];
  employment: IEmployment[];
  payment: any;
  socialMediaList: ISocialMedia[];
  emptyFields: string[];
  originEdus: any[];
  status: string;
  getStartedOverlay: boolean;
  bookingUrl: string;
  showDeleteCanvasLink: boolean;
}

@mapProp(module_student_canvas)
export default class StudentCanvas extends React.Component<StudentCanvasProps, {}> {
  state = {
    id: this.props.match.params.id,
    autoSaveId: 0,
    showSaved: false,
    showEditAward: false,
    showEditCate: false,
    showEditPhoto: false,
    curEditMedia: {} as IMediaItem,
    showEditReposition: false,
    curEditPortIndex: 0,
    curEditAward: {},
    curEditCate: {},
    curEditCateIdx: -1,
    curEditEmployment: {},
    showEditEmployment: false,
    // Add new states for handling the Education modal
    showEditEducation: false,
    curEditEducation: {} as any,
    curEditEduIdx: -1,

    isMobile: window.innerWidth < 1024,
    curLoadingMedia: 0,
    showLoading: false,
    isVideoLoading: false,
    schoolOptions: [],
    relocateOptions: [] as any[],
    showDelConfirm: false,
    curConfirmAction: 0,
    curDelEduSchool: [],
    isShowOpenToOpp: false,
    curPreviewPosition: PositionTag.NONE,
    getStartedOverlay: false,
    bookingUrl: null,
    showDeleteCanvasLink: false,
    bookingLinkError: false,
  };

  autoCompleteService: any;
  placeService: any;

  private updateState = (params: any) => {
    const newState = { ...this.state, ...params };
    this.setState(newState);
  };

  componentDidMount() {
    doAction(GetSelectionsAction);
    if (this.state.id) {
      doAction(GetPageDataAction, this.state.id);
    } else {
      const type =
        window.location.pathname.indexOf("professional-canvas") > -1
          ? CanvasType.PROFESSIONAL
          : CanvasType.STUDENT;
      doAction(GetUserInfoAction, type);
    }

    doFunction(async () => {
      if (localStorageGet("invitationCode")) {
        const inviteSchool = await getInviteSchool(
          localStorageGet("invitationCode")
        );
        localStorageSet("invitationCode", null);
        if (inviteSchool && inviteSchool.school) {
          const newEdu = {
            ...educationItem,
            schoolId: inviteSchool.school.optionId,
            schoolName: inviteSchool.school.optionName,
          };
          if (this.state.id) {
            const newList = [...this.props.educations, newEdu];
            doAction(UpdatePageInfoAction, { educations: newList });
          } else {
            doAction(UpdatePageInfoAction, { educations: [newEdu] });
          }
        }
      }
    });

    const instaInfo = queryString.parse(window.location.search);
    const { username } = instaInfo;
    if (username) {
      doFunction(async () =>
        putInstInfo({
          ...instaInfo,
          canvas_id: getModuleProp(MODULE_STUDENT_CANVAS, "id"),
        })
      );
      doAction(SocialMediaInputAction, {
        type: 0,
        label: "instagram",
        value: username,
      });
    }
    if (google) {
      this.autoCompleteService = new google.maps.places.AutocompleteService();
      const map = new google.maps.Map(document.createElement("div"));
      this.placeService = new google.maps.places.PlacesService(map);
    }

    if (checkOverlay()) {
      this.setState({ getStartedOverlay: true });
    }
  }

  private _autoSaveInterval: any = null;
  private initAutoSave(): void {
    if (this._autoSaveInterval) {
      return;
    }
    this._autoSaveInterval = setInterval(() => {
      doAction(AutoSaveAction, null);
    }, CANVAS_AUTOSAVE_INTERVAL_MILLISECONDS);
  }

  componentWillUnmount() {
    doAction(MODULE_STUDENT_CANVAS, {
      ...module_student_canvas,
      educations: [
        {
          uuid: "",
          program: {},
          school: {},
          graduationDate: null,
          gpa: "",
          attendance: "",
          workPermit: "",
        },
      ],
    });
    clearInterval(this._autoSaveInterval);
    this._autoSaveInterval = null;
  }

  private onAddressSelected = (address: any) => {
    this.placeService.getDetails(
      { placeId: address.place_id },
      (place: any, status: any) => {
        if (status === "OK") {
          let country = "";
          let countryShort = "";
          let state = "";
          let stateShort = "";
          let city = "";
          let coordinates = [] as number[];
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          if (lat && lng) {
            coordinates = [lng, lat];
          }
          _.forEach(place.address_components, (item) => {
            if (item.types.indexOf("country") > -1) {
              country = item.long_name;
              countryShort = item.short_name;
            } else if (item.types.indexOf("administrative_area_level_1") > -1) {
              state = item.long_name;
              stateShort = item.short_name;
            } else if (item.types.indexOf("locality") > -1) {
              city = item.long_name;
            }
          });
          doAction(UpdatePageInfoAction, {
            location: {
              ...this.props.location,
              uuid: uuidv4(),
              country: country,
              countryShort: countryShort,
              state: state,
              stateShort: stateShort,
              city: city,
              coordinates: coordinates,
            },
          });
        }
      }
    );
  };

  private searchSchoolByName = async (keywords: string) => {
    if (!keywords) {
      this.updateState({ schoolOptions: [] });
      return;
    }
    const result = await searchSchool(keywords, [2, 20]);
    if (result && !_.isEmpty(result.schools)) {
      this.updateState({ schoolOptions: result.schools });
    } else {
      this.updateState({ schoolOptions: [] });
    }
  };

  private searchCity = async (keywords: string) => {
    if (!keywords) {
      this.updateState({ relocateOptions: [] });
      return;
    }
    this.autoCompleteService.getPlacePredictions(
      {
        input: keywords,
        types: ["(cities)"],
        componentRestrictions: { country: "us" },
      },
      (results: any, status: any) => {
        if (results && !_.isEmpty(results)) {
          const mapped = _.map(results, (item) => {
            let newItem = { place_id: item.place_id } as any;
            if (!_.isEmpty(item.terms)) {
              if (item.terms[0] && item.terms[0].value) {
                newItem = { ...newItem, city: item.terms[0].value };
              }
              if (item.terms[1] && item.terms[1].value) {
                newItem = { ...newItem, state: item.terms[1].value };
              }
            }
            return newItem;
          });
          this.updateState({ relocateOptions: mapped });
        }
      }
    );
  };

  private onRelocateSelected = (address: any) => {
    this.placeService.getDetails(
      { placeId: address.place_id },
      (place: any, status: any) => {
        if (status === "OK") {
          let country = "";
          let countryShort = "";
          let state = "";
          let stateShort = "";
          let city = "";
          let coordinates = [] as number[];
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          if (lat && lng) {
            coordinates = [lng, lat];
          }
          _.forEach(place.address_components, (item) => {
            if (item.types.indexOf("country") > -1) {
              country = item.long_name;
              countryShort = item.short_name;
            } else if (item.types.indexOf("administrative_area_level_1") > -1) {
              state = item.long_name;
              stateShort = item.short_name;
            } else if (item.types.indexOf("locality") > -1) {
              city = item.long_name;
            }
          });
          const loc = {
            uuid: uuidv4(),
            country: country,
            countryShort: countryShort,
            state: state,
            stateShort: stateShort,
            city: city || address.city,
            coordinates: coordinates,
          };
          const oldList = this.props.relocateList;
          const find = _.find(oldList, { city: loc.city, state: loc.state });
          if (!find) {
            doAction(UpdatePageInfoAction, { relocateList: [...oldList, loc] });
          }
        }
      }
    );
  };

  private checkSchoolChange = (type: any) => {
    //0:preview , 1:publish ,2: save
    const origin = this.props.originEdus;
    const deleted = [] as any[];
    _.forEach(origin, (item) => {
      const name = item.schoolName;
      const find = _.find(this.props.educations, { schoolName: name });
      if (!find) {
        deleted.push(name);
      }
    });
    // Just proceed
    if (type === 0) {
      doAction(SubmitToPreviewAction, this.props.history);
    } else if (type === 1) {
      doAction(SubmitToPublishAction, this.props.history);
    } else {
      doAction(SaveAsDraftAction, this.props.history);
    }
  };

  private getDelEduConfirmText = () => {
    const name = this.state.curDelEduSchool.join(" and ");
    let name1 = "";
    _.forEach(this.state.curDelEduSchool, (item, index) => {
      name1 = name1 + item + "'s";
      if (index < this.state.curDelEduSchool.length - 1) {
        name1 = name1 + " and ";
      }
    });
    return `You are removing yourself as a student from ${name}. This action also means that you will be removed from the ${name1} list of students. `;
  };

  private scrollToPosition = (pos: PositionTag) => {
    this.setState(
      {
        curPreviewPosition: pos,
      },
      () => {
        this.setState({
          curPreviewPosition: PositionTag.NONE,
        });
      }
    );
  };

  render() {
    const { getStartedOverlay } = this.state;
    return (
      <Layout>
        <CreateBusinessCanvasHeader
          showAutosaveHint={false}
          type={this.props.type}
          step={0}
          onPreviewClick={() => this.checkSchoolChange(0)}
          onPublishClick={() => this.checkSchoolChange(1)}
          onSaveDraftClick={() => this.checkSchoolChange(2)}
        />
        <Content>
          <div className="student-canvas-root">
            <div className="left-sec">
              <div className="title-sec">
                <span className="lb-bold title">
                  {this.props.type === CanvasType.PROFESSIONAL
                    ? "My Professional Portfolio"
                    : "My Student Portfolio"}
                </span>
              </div>
              <div className="subheader">
                Start building your Portfolio by <b>expanding the sections below and adding your information</b>. You can preview
                your portfolio at anytime by clicking the preview icon above. You can edit your portfolio as many times as you like,
                so don't feel like you have to complete all sections at one time.&nbsp;
                <a
                  href={
                    this.props.type === CanvasType.PROFESSIONAL
                      ? "https://vimeo.com/816723454/a323d250cf"
                      : "https://vimeo.com/764150986"
                  }
                  target="blank"
                  className="overview-link"
                >
                  Watch the Overview Video
                </a>
              </div>
              <CRExpansionPanel className="panel" title={"About Me"}>
                <div className="intro-detail">
                  <TextField
                    select
                    key="pronouns"
                    label="Pronouns"
                    variant="filled"
                    value={this.props.pronouns || ""}
                    onChange={(e) => {
                      doAction(UpdatePageInfoAction, {
                        pronouns: e.target.value,
                      });
                    }}
                    className="material-input input years-input"
                  >
                    <MenuItem key={0} value="">
                      Prefer not to say
                    </MenuItem>
                    <MenuItem key={1} value="She/Her">
                      She/Her
                    </MenuItem>
                    <MenuItem key={2} value="He/Him">
                      He/Him
                    </MenuItem>
                    <MenuItem key={3} value="They/Them">
                      They/Them
                    </MenuItem>
                  </TextField>
                  <CRTextArea
                    type="text"
                    className="years-input"
                    key="name"
                    label="Full Name"
                    placeholder="Full Name"
                    value={this.props.name}
                    onInput={(e: string) => {
                      doAction(UpdatePageInfoAction, {
                        name: e,
                      });
                    }}
                    onClick={() => {
                      this.scrollToPosition(PositionTag.TITLE);
                    }}
                  />
                  <CRTextArea
                    type="text"
                    className="years-input"
                    key="display_title"
                    label="Personal Mantra"
                    placeholder="Personal Mantra"
                    value={this.props.title}
                    onInput={(e: string) => {
                      doAction(UpdatePageInfoAction, {
                        title: e,
                      });
                    }}
                    onClick={() => {
                      this.scrollToPosition(PositionTag.TITLE);
                    }}
                  />
                  <CRTextArea
                    className="years-input"
                    key="display_title"
                    label="Years of experience"
                    placeholder="Years of experience"
                    type="number"
                    value={
                      this.props?.experience === -1
                        ? ""
                        : this.props.experience.toString()
                    }
                    onInput={(e: number) => {
                      const years = e || -1;
                      doAction(UpdatePageInfoAction, {
                        experience: Number(years),
                      });
                    }}
                    onKeyDown={(e: any) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 187 ||
                        e.keyCode === 189 ||
                        e.keyCode === 190) &&
                      e.preventDefault()
                    }
                    onClick={() => {
                      this.scrollToPosition(PositionTag.EDUCATION);
                    }}
                  />
                  <div className="locate-switch">
                    <span className="locate-label">Open to opportunity</span>
                    <div className="select-wrap">
                      <span
                        className={classnames({
                          active: this.props.opportunityStatus,
                        })}
                        onClick={() => {
                          if (this.props.opportunityStatus) return;
                          doAction(UpdatePageInfoAction, {
                            opportunityStatus: true,
                          });
                          this.scrollToPosition(PositionTag.TITLE);
                        }}
                      >
                        Yes
                      </span>
                      <span
                        className={classnames({
                          active: !this.props.opportunityStatus,
                        })}
                        onClick={() => {
                          if (!this.props.opportunityStatus) return;
                          doAction(UpdatePageInfoAction, {
                            opportunityStatus: false,
                          });
                          this.scrollToPosition(PositionTag.TITLE);
                        }}
                      >
                        No
                      </span>
                    </div>
                  </div>

                  <div className="checkbox-wrap">
                    <Checkbox
                      className="check-box"
                      checked={this.props.showOpportunityStatus}
                      onChange={(e) => {
                        doAction(UpdatePageInfoAction, {
                          showOpportunityStatus: e.target.checked,
                        });
                      }}
                    />
                    <span>
                      Show <span className="bold">Open to opportunity</span>{" "}
                      status on my canvas
                    </span>
                  </div>
                  <AddressTextArea
                    className="text-area-location"
                    label={"Location"}
                    value={this.props.location.location || ""}
                    onInput={(value: string) => {
                      doAction(UpdatePageInfoAction, {
                        location: {
                          ...this.props.location,
                          location: value,
                        },
                      });
                    }}
                    onAddressSelect={(address: any) => {
                      doAction(UpdatePageInfoAction, {
                        location: {
                          ...this.props.location,
                          location: address.description,
                        },
                      });
                      doFunction(async () => {
                        this.onAddressSelected(address);
                      });
                    }}
                    autoCompleteService={this.autoCompleteService}
                    onClick={() => {
                      this.scrollToPosition(PositionTag.TITLE);
                    }}
                  />
                  <div className="locate-switch">
                    <span className="locate-label">Willing to relocate</span>
                    <div className="select-wrap">
                      <span
                        className={classnames({
                          active: this.props.willingToRelocate,
                        })}
                        onClick={() => {
                          if (this.props.willingToRelocate) return;
                          doAction(UpdatePageInfoAction, {
                            willingToRelocate: true,
                          });
                          this.scrollToPosition(PositionTag.ABOUT_ME);
                        }}
                      >
                        Yes
                      </span>
                      <span
                        className={classnames({
                          active: !this.props.willingToRelocate,
                        })}
                        onClick={() => {
                          if (!this.props.willingToRelocate) return;
                          doAction(UpdatePageInfoAction, {
                            willingToRelocate: false,
                            relocateList: [],
                          });
                          this.scrollToPosition(PositionTag.ABOUT_ME);
                        }}
                      >
                        No
                      </span>
                    </div>
                  </div>
                  {this.props.willingToRelocate && (
                    <Autocomplete
                      multiple
                      className="tag-input relocate-input"
                      options={this.state.relocateOptions}
                      value={this.props.relocateList}
                      getOptionLabel={(option) => {
                        const text = `${option.city || ""},${option.state || ""}`;
                        return text ? text.replace(/^[,]|[,]$/g, "") : "";
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const text = `${option.city || ""},${option.state || ""
                            }`;
                          return (
                            <Chip
                              label={text ? text.replace(/^[,]|[,]$/g, "") : ""}
                              {...getTagProps({ index })}
                            />
                          );
                        })
                      }
                      onChange={(event: object, value: any, reason: string) => {
                        const last = _.last(value) as any;
                        if (last && last.place_id) {
                          this.onRelocateSelected(last);
                        } else {
                          doAction(UpdatePageInfoAction, {
                            relocateList: value,
                          });
                        }
                      }}
                      onInputChange={(
                        event: object,
                        value: string,
                        reason: string
                      ) => {
                        this.searchCity(value);
                      }}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Willing to relocate to"
                          variant="filled"
                          className="material-input"
                          onClick={() => {
                            this.scrollToPosition(PositionTag.ABOUT_ME);
                          }}
                        />
                      )}
                    />
                  )}
                  <div
                    className="about-me"
                    onClick={() => this.scrollToPosition(PositionTag.ABOUT_ME)}
                  >
                    <div className="cm-standalone-label">About Me</div>
                    {this.props.showDesc && (
                      <EditorComponent
                        placeholder="About Me"
                        content={this.props.aboutMe}
                        hideTools={[
                          "image",
                          "colorPicker",
                          "fontFamily",
                          "fontSize",
                        ]}
                        getEditorConent={(aboutMe: any) =>
                          doAction(UpdatePageInfoAction, { aboutMe })
                        }
                      />
                    )}
                  </div>
                  <div
                    className="why"
                    onClick={() => this.scrollToPosition(PositionTag.WHY)}
                  >
                    <div className="cm-standalone-label">Why I'm Here</div>
                    {this.props.showDesc && (
                      <EditorComponent
                        placeholder="Why I'm Here"
                        content={this.props.why}
                        hideTools={[
                          "image",
                          "colorPicker",
                          "fontFamily",
                          "fontSize",
                        ]}
                        getEditorConent={(why: any) =>
                          doAction(UpdatePageInfoAction, { why })
                        }
                      />
                    )}
                  </div>
                </div>
              </CRExpansionPanel>
              <CRExpansionPanel
                className="panel"
                title={
                  this.props.type === CanvasType.PROFESSIONAL
                    ? "Education and License info"
                    : "Education info"
                }
                onExpanded={(event: any, expanded: boolean) => {
                  if (expanded) {
                    this.scrollToPosition(PositionTag.EDUCATION);
                  }
                }}
              >
                <div className="education-info">
                  <div className="education-title">School</div>
                  {_.isEmpty(this.props.educations) ? (
                    <></>
                  ) : (
                    _.map(this.props.educations, (item, index) => {
                      if (_.isEmpty(item.program) && item.graduationDate === null && _.isEmpty(item.school)) {
                        return null;
                      }
                      const schoolText = item.schoolName || "No School Entered";
                      const gradDateText = item.graduationDate
                        ? item.graduationDate
                        : "No Graduation Date";
                      const programText = item.program.name || "No Program Entered";
                      const displayText = `${programText} - ${schoolText} - ${gradDateText}`;

                      return (
                        <div key={index} className="category-item">
                          <img alt="" src={IconAward} className="icon-award" />
                          <div className="issue">{displayText}</div>

                          <img
                            alt=""
                            className="icon-edit"
                            src={IconEdit}
                            onClick={() => {
                              this.updateState({
                                showEditEducation: true,
                                curEditEducation: { ...item },
                                curEditEduIdx: index,
                              });
                            }}
                          />
                          <img
                            alt=""
                            className="icon-delete"
                            src={IconDelete}
                            onClick={() => {
                              const newList = [...this.props.educations];
                              newList.splice(index, 1);
                              doAction(UpdatePageInfoAction, {
                                educations: newList,
                              });
                            }}
                          />
                        </div>
                      );
                    })
                  )}

                  <Button
                    className="btn-secondary btn-add"
                    onClick={() => {
                      this.updateState({
                        showEditEducation: true,
                        curEditEducation: {
                          uuid: "",
                          program: {},
                          school: {},
                          graduationDate: null,
                          gpa: "",
                          attendance: "",
                          workPermit: "",
                          schoolName: "",
                        },
                        curEditEduIdx: -1,
                      });
                    }}
                  >
                    <img src={IconAwardAdd} alt="" />
                    Add education
                  </Button>

                  {this.props.type === CanvasType.PROFESSIONAL && (
                    <>
                      <div className="divider" />
                      <div className="professional-background">
                        <div className="professional-title">Licenses</div>
                        {!_.isEmpty(this.props.categories) &&
                          _.map(this.props.categories, (item, index) => {
                            return (
                              <CategoryItem
                                key={index}
                                data={item}
                                onEdit={() => {
                                  this.updateState({
                                    showEditCate: true,
                                    curEditCate: item,
                                    curEditCateIdx: index,
                                  });
                                }}
                                onDelete={() => {
                                  const newListData = [...this.props.categories];
                                  newListData.splice(index, 1);
                                  doAction(UpdatePageInfoAction, {
                                    categories: newListData,
                                  });
                                }}
                              />
                            );
                          })}
                        <Button
                          className="btn-secondary btn-add"
                          onClick={() => {
                            this.updateState({
                              showEditCate: true,
                              curEditCate: {},
                              curEditCateIdx: -1,
                            });
                          }}
                        >
                          <img src={IconAwardAdd} alt="" />
                          Add License
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </CRExpansionPanel>

              <CRExpansionPanel className="panel" title={"Featured Photos & Videos"}>
                <div className="media-detail">
                  <MediaItem
                    data={this.props.logo}
                    className="item-media"
                    onUpload={(mediaInfo: any) => {
                      this.updateState({
                        curLoadingMedia: 1,
                        showLoading: true,
                      });
                      doAction(UploadFileAction, {
                        mediaInfo: mediaInfo,
                        type: MediaType.LOGO,
                      });
                      doFunction(async () => {
                        this.updateState({
                          curLoadingMedia: 0,
                          showLoading: false,
                        });
                      });
                      this.scrollToPosition(PositionTag.TITLE);
                    }}
                    onDelete={() => {
                      doAction(DeleteMediaAction, { type: MediaType.LOGO });
                      this.scrollToPosition(PositionTag.TITLE);
                    }}
                    onEdit={(media: IMediaItem) => {
                      this.updateState({
                        curEditMedia: media,
                        showEditPhoto: true,
                        showEditReposition: false,
                      });
                      this.scrollToPosition(PositionTag.TITLE);
                    }}
                    showLoading={this.state.curLoadingMedia === 1}
                    isShowUploadBtn={true}
                  />
                  <hr className="dotted-line-hr" />
                  <MediaItem
                    data={this.props.featuredPhoto}
                    className="item-media"
                    onUpload={(mediaInfo: any) => {
                      this.updateState({
                        curLoadingMedia: 2,
                        showLoading: true,
                      });
                      doAction(UploadFileAction, {
                        mediaInfo: mediaInfo,
                        type: MediaType.FEATURED_PHOTO,
                      });
                      doFunction(async () => {
                        this.updateState({
                          curLoadingMedia: 0,
                          showLoading: false,
                        });
                      });
                      this.scrollToPosition(PositionTag.TITLE);
                    }}
                    onDelete={() => {
                      doAction(DeleteMediaAction, {
                        type: MediaType.FEATURED_PHOTO,
                      });
                      this.scrollToPosition(PositionTag.TITLE);
                    }}
                    onEdit={(media: IMediaItem) => {
                      this.updateState({
                        curEditMedia: media,
                        showEditPhoto: true,
                        showEditReposition: true,
                      });
                      this.scrollToPosition(PositionTag.TITLE);
                    }}
                    showLoading={this.state.curLoadingMedia === 2}
                    isShowUploadBtn={true}
                  />
                  <hr className="dotted-line-hr" />
                  <MediaItem
                    data={this.props.portraitPhoto}
                    className="item-media"
                    onUpload={(mediaInfo: any) => {
                      this.updateState({
                        curLoadingMedia: 3,
                        showLoading: true,
                      });
                      doAction(UploadFileAction, {
                        mediaInfo: mediaInfo,
                        type: MediaType.PORTRAIT_PHOTO,
                      });
                      doFunction(async () => {
                        this.updateState({
                          curLoadingMedia: 0,
                          showLoading: false,
                        });
                      });
                      this.scrollToPosition(PositionTag.ABOUT_ME);
                    }}
                    onDelete={() => {
                      doAction(DeleteMediaAction, {
                        type: MediaType.PORTRAIT_PHOTO,
                      });
                      this.scrollToPosition(PositionTag.ABOUT_ME);
                    }}
                    onEdit={(media: IMediaItem) => {
                      this.updateState({
                        curEditMedia: media,
                        showEditPhoto: true,
                        showEditReposition: false,
                      });
                      this.scrollToPosition(PositionTag.ABOUT_ME);
                    }}
                    showLoading={this.state.curLoadingMedia === 3}
                    isShowUploadBtn={true}
                  />
                  <hr className="dotted-line-hr" />
                  <MediaItem
                    data={this.props.featuredVideo}
                    className="item-media"
                    onUpload={(file: any) => {
                      this.updateState({
                        curLoadingMedia: 4,
                        showLoading: true,
                        isVideoLoading: true,
                      });
                      getMediaInfo(file).then((mediaInfo) => {
                        doAction(UploadFileAction, {
                          mediaInfo: mediaInfo,
                          type: MediaType.FEATURED_VIDEO,
                        });
                        doFunction(async () => {
                          this.updateState({
                            curLoadingMedia: 0,
                            showLoading: false,
                            isVideoLoading: false,
                          });
                        });
                        this.scrollToPosition(PositionTag.MY_WORK);
                      });
                    }}


                    onUrlInput={(value: string) => {
                      const { videoUrl, image } = parseYoutubeUrl(value);
                      doAction(UpdatePageInfoAction, {
                        featuredVideo: {
                          ...this.props.featuredVideo,
                          url: videoUrl,
                          image: image,
                        },
                      });
                      this.scrollToPosition(PositionTag.MY_WORK);
                    }}
                    onDelete={() => {
                      doAction(DeleteMediaAction, {
                        type: MediaType.FEATURED_VIDEO,
                      });
                      this.scrollToPosition(PositionTag.MY_WORK);
                    }}
                    showLoading={this.state.curLoadingMedia === 4}
                    char={!_.isEmpty(this.props.name) ? this.props.name[0] : ""}
                    isShowUploadBtn={true}
                  />
                </div>
              </CRExpansionPanel>
              <CRExpansionPanel className="panel" title={"Online Booking"}>
                <div className="intro-detail">
                  <p>
                    Leverage your BEAUTISTA portfolio as your website! When you
                    add your booking link the "Book Now" button will appear on
                    your portfolio; allowing customers to BOOK a service with
                    you right from your portfolio. You can enter a full url, phone number, email address or instagram handle.
                  </p>
                  <CRTextArea
                    type="text"
                    key="booking_url"
                    label="Online Booking Link"
                    placeholder="Online Booking Link"
                    className={`years-input ${this.state.bookingLinkError ? 'error-outline' : ''}`}
                    value={this.props.bookingUrl}
                    onInput={(inputValue: string) => {
                      const isValid = checkBookingLink(inputValue) !== null;
                      this.setState({ bookingLinkError: !isValid });
                      doAction(UpdatePageInfoAction, { bookingUrl: inputValue });
                    }}
                    onClick={() => {
                      this.scrollToPosition(PositionTag.TITLE);
                    }}
                  />
                  {this.state.bookingLinkError && (
                    <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                      Invalid link. Please enter a valid URL, phone number, email, or Instagram handle.
                    </p>
                  )}
                </div>
              </CRExpansionPanel>
              <WorkMediaSection
                photos={this.props.portfolioPhotos}
                onVideosReordered={(reorderedItems: IMediaItem[]) => {
                  doAction(ReorderPortfolioVidoes, {
                    type: MediaType.FEATURED_VIDEO,
                    reorderedItems,
                  });
                }}
                onPhotosReordered={(reorderedItems: IMediaItem[]) => {
                  doAction(ReorderPortfolioImages, {
                    type: MediaType.PORTFOLIO_PHOTO,
                    reorderedItems,
                  });
                }}
                onPhotoDelete={(index: any) => {
                  doAction(DeleteMediaAction, {
                    type: MediaType.PORTFOLIO_PHOTO,
                    index: index,
                  });
                  this.scrollToPosition(PositionTag.MY_WORK);
                }}
                onPhotoEdit={(media: IMediaItem, index: any) => {
                  this.updateState({
                    curEditMedia: media,
                    showEditPhoto: true,
                    curEditPortIndex: index,
                    showEditReposition: false,
                  });
                  this.scrollToPosition(PositionTag.MY_WORK);
                }}
                onPhotoPost={this.onPhotoPost}
                curLoadingMedia={this.state.curLoadingMedia}
                videos={this.props.extraVideo}
                onVideoPost={this.onVideoPost}
                onVideoDelete={(index: any) => {
                  doAction(DeleteMediaAction, {
                    type: MediaType.ADDITIONAL,
                    index: index,
                  });
                  this.scrollToPosition(PositionTag.MY_WORK);
                }}
              ></WorkMediaSection>
              <CRExpansionPanel
                className="panel"
                title={"KPIs"}
                onExpanded={(event: any, expanded: boolean) => {
                  if (expanded) {
                    this.scrollToPosition(PositionTag.KPI);
                  }
                }}
              >
                <div className="kpi-detail">
                  <CRTextArea
                    label="New guests per month"
                    placeholder="New guests per month"
                    type="number"
                    className="txt-input"
                    key="new_guests_per_month"
                    value={this.props.kpi.newGuestsPerMonth || ""}
                    onInput={(e: number) => {
                      const value = e;
                      if (Number(value) < 0) return;
                      const newKpi = {
                        ...this.props.kpi,
                        newGuestsPerMonth: Number(value),
                      };
                      doAction(UpdatePageInfoAction, { kpi: newKpi });
                    }}
                    onKeyDown={(e: any) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 187 ||
                        e.keyCode === 189) &&
                      e.preventDefault()
                    }
                  />
                  <CRTextArea
                    label="New client retention (%)"
                    placeholder="New client retention (%)"
                    type="number"
                    className="txt-input"
                    key="new_client_retention"
                    value={this.props.kpi.newClientRetention || ""}
                    onInput={(e: number) => {
                      const value = e;
                      if (Number(value) < 0) return;
                      const newKpi = {
                        ...this.props.kpi,
                        newClientRetention: Number(value),
                      };
                      doAction(UpdatePageInfoAction, { kpi: newKpi });
                    }}
                    onKeyDown={(e: any) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 187 ||
                        e.keyCode === 189) &&
                      e.preventDefault()
                    }
                  />
                  <CRTextArea
                    label="Repeat Client retention (%)"
                    placeholder="Repeat Client retention (%)"
                    type="number"
                    className="txt-input"
                    key="retention"
                    value={this.props.kpi.repeatClientRetention || ""}
                    max={100}
                    onInput={(e: number) => {
                      const value = e;
                      if (Number(value) < 0) return;
                      const newKpi = {
                        ...this.props.kpi,
                        repeatClientRetention: Number(value),
                      };
                      doAction(UpdatePageInfoAction, { kpi: newKpi });
                    }}
                    onKeyDown={(e: any) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 187 ||
                        e.keyCode === 189) &&
                      e.preventDefault()
                    }
                  />
                  <CRTextArea
                    label="Frequency of Visit"
                    placeholder="Frequency of Visit"
                    type="number"
                    className="txt-input"
                    key="frequency"
                    value={this.props.kpi.annualFrequencyOfVisit || ""}
                    onInput={(e: number) => {
                      const value = e;
                      if (Number(value) < 0) return;
                      const newKpi = {
                        ...this.props.kpi,
                        annualFrequencyOfVisit: Number(value),
                      };
                      doAction(UpdatePageInfoAction, { kpi: newKpi });
                    }}
                    onKeyDown={(e: any) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 187 ||
                        e.keyCode === 189) &&
                      e.preventDefault()
                    }
                  />
                  <CRTextArea
                    label="Average Ticket ($)"
                    placeholder="Average Ticket ($)"
                    type="number"
                    className="txt-input"
                    key="average_ticket"
                    value={this.props.kpi.combinedAverageTicket || ""}
                    onInput={(e: number) => {
                      const value = e;
                      if (Number(value) < 0) return;
                      const newKpi = {
                        ...this.props.kpi,
                        combinedAverageTicket: Number(value),
                      };
                      doAction(UpdatePageInfoAction, { kpi: newKpi });
                    }}
                    onKeyDown={(e: any) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 187 ||
                        e.keyCode === 189) &&
                      e.preventDefault()
                    }
                  />
                  <CRTextArea
                    label="Productivity (%) "
                    placeholder="Productivity (%)"
                    type="number"
                    className="txt-input"
                    key="productivity"
                    max={100}
                    value={this.props.kpi.productivity || ""}
                    onInput={(e: number) => {
                      const value = e;
                      if (Number(value) < 0) return;
                      const newKpi = {
                        ...this.props.kpi,
                        productivity: Number(value),
                      };
                      doAction(UpdatePageInfoAction, { kpi: newKpi });
                    }}
                    onKeyDown={(e: any) =>
                      (e.keyCode === 69 ||
                        e.keyCode === 187 ||
                        e.keyCode === 189) &&
                      e.preventDefault()
                    }
                  />
                </div>
              </CRExpansionPanel>
              <CRExpansionPanel
                className="panel"
                title={"Additional Information"}
                onExpanded={(event: any, expanded: boolean) => {
                  if (expanded) {
                    this.scrollToPosition(PositionTag.ADDITIONAL);
                  }
                }}
              >
                <div className="additional-detail">
                  <Autocomplete
                    multiple
                    className="tag-input auto-complete"
                    options={this.props.preferredBrandsOptions}
                    freeSolo
                    value={this.props.preferredBrands}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                      ))
                    }
                    onChange={(event: object, value: any, reason: string) => {
                      const newItems = _.map(value, (item) => {
                        if (typeof item === "string") {
                          if (value.find((_: any) => _.name === item)) {
                            return undefined;
                          }
                          return (
                            this.props.preferredBrandsOptions.find(
                              (_: any) =>
                                _.name.toLowerCase() === item.toLowerCase()
                            ) || { name: item }
                          );
                        }
                        return item;
                      }).filter(Boolean);
                      doAction(UpdatePageInfoAction, {
                        preferredBrands: _.uniqBy(newItems, "name"),
                      });
                    }}
                    getOptionSelected={(option: any, value: any) => {
                      return option.name === value.name;
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Preferred Brands"
                        variant="filled"
                        className="material-input input"
                      />
                    )}
                  />

                  <Autocomplete
                    multiple
                    className="tag-input auto-complete"
                    options={this.props.specialityOptions}
                    value={this.props.specialities}
                    freeSolo
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                      ))
                    }
                    onChange={(event: object, value: any, reason: string) => {
                      const newItems = _.map(value, (item) => {
                        if (typeof item === "string") {
                          if (value.find((_: any) => _.name === item)) {
                            return undefined;
                          }
                          return (
                            this.props.specialityOptions.find(
                              (_: any) =>
                                _.name.toLowerCase() === item.toLowerCase()
                            ) || { name: item }
                          );
                        }
                        return item;
                      }).filter(Boolean);
                      doAction(UpdatePageInfoAction, {
                        specialities: _.uniqBy(newItems, "name"),
                      });
                    }}
                    getOptionSelected={(option: any, value: any) => {
                      return option.name === value.name;
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Specialties"
                        variant="filled"
                        className="material-input input"
                      />
                    )}
                  />
                  <Autocomplete
                    multiple
                    freeSolo
                    className="tag-input auto-complete"
                    options={this.props.softwareOptions}
                    value={this.props.softwares}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                      ))
                    }
                    onChange={(event: object, value: any, reason: string) => {
                      const newItems = _.map(value, (item) => {
                        if (typeof item === "string") {
                          if (value.find((_: any) => _.name === item)) {
                            return undefined;
                          }
                          return (
                            this.props.softwareOptions.find(
                              (_: any) =>
                                _.name.toLowerCase() === item.toLowerCase()
                            ) || { name: item }
                          );
                        }
                        return item;
                      }).filter(Boolean);
                      doAction(UpdatePageInfoAction, {
                        softwares: _.uniqBy(newItems, "name"),
                      });
                    }}
                    getOptionSelected={(option: any, value: any) => {
                      return option.name === value.name;
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Software"
                        variant="filled"
                        className="material-input input"
                      />
                    )}
                  />
                  <Autocomplete
                    className="tag-input auto-complete"
                    options={this.props.paymentOptions}
                    value={this.props.payment}
                    getOptionLabel={(option) => option.name}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                      ))
                    }
                    onChange={(event: object, value: any, reason: string) => {
                      const payment = value;
                      doAction(UpdatePageInfoAction, {
                        payment: payment || {},
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payment preference"
                        variant="filled"
                        className="material-input txt-input"
                        placeholder="Payment preference"
                      />
                    )}
                  />
                </div>
              </CRExpansionPanel>
              <CRExpansionPanel
                className="panel"
                title={"Employment Information"}
                onExpanded={(event: any, expanded: boolean) => {
                  if (expanded) {
                    this.scrollToPosition(PositionTag.EMPLOYMENT);
                  }
                }}
              >
                <div className="employ-detail">
                  {_.isEmpty(this.props.employment) ? (
                    <div className="employ-empty">
                      <img
                        alt=""
                        src={IconEmploy}
                        className="icon-employ-empty"
                      />
                      <span className="empty-title">
                        No Employment Information available
                      </span>
                      <Button
                        className="btn-secondary btn-add-employ"
                        onClick={() =>
                          this.updateState({ showEditEmployment: true })
                        }
                      >
                        <img alt="" src={IconAwardAdd} />
                        Add employment information
                      </Button>
                    </div>
                  ) : (
                    <div className="employ-list">
                      {_.map(this.props.employment, (item, index) => {
                        return (
                          <EmploymentItem
                            key={index}
                            data={item}
                            onDelete={(employment: IEmployment) => {
                              doAction(DeleteEmploymentAction, employment);
                            }}
                            onEdit={(employment: IEmployment) => {
                              this.updateState({
                                showEditEmployment: true,
                                curEditEmployment: employment,
                              });
                            }}
                          />
                        );
                      })}
                      <Button
                        className="btn-secondary btn-add-employ"
                        onClick={() =>
                          this.updateState({
                            showEditEmployment: true,
                            curEditEmployment: {},
                          })
                        }
                      >
                        <img alt="" src={IconAwardAdd} />
                        Add more
                      </Button>
                    </div>
                  )}
                </div>
              </CRExpansionPanel>
              <CRExpansionPanel
                className="panel"
                title={"Celebrations, Certifications & Awards"}
                onExpanded={(event: any, expanded: boolean) => {
                  if (expanded) {
                    this.scrollToPosition(PositionTag.AWARD);
                  }
                }}
              >
                <div className="award-detail">
                  {_.isEmpty(this.props.awardList) ? (
                    <div className="award-empty">
                      <img
                        alt=""
                        src={IconAward}
                        className="icon-award-empty"
                      />
                      <Button
                        className="btn-secondary btn-add-award"
                        onClick={() => this.updateState({ showEditAward: true })}
                      >
                        <img alt="" src={IconAwardAdd} />
                        Add award
                      </Button>
                    </div>
                  ) : (
                    <div>
                      {_.map(this.props.awardList, (item, index) => {
                        return (
                          <AwardItem
                            data={item}
                            key={index}
                            className="item-award"
                            onEdit={(award: any) => {
                              this.updateState({
                                showEditAward: true,
                                curEditAward: award,
                              });
                            }}
                            onDelete={(award: any) => {
                              doAction(DeleteAwardAction, award);
                            }}
                          />
                        );
                      })}
                      <Button
                        className="btn-secondary btn-add-award"
                        onClick={() =>
                          this.updateState({
                            showEditAward: true,
                            curEditAward: {},
                          })
                        }
                      >
                        <img alt="" src={IconAwardAdd} />
                        Add award
                      </Button>
                    </div>
                  )}
                </div>
              </CRExpansionPanel>
              <CRExpansionPanel
                className="panel"
                title={"Social Media"}
                onExpanded={(event: any, expanded: boolean) => {
                  if (expanded) {
                    this.setState(
                      {
                        curPreviewPosition: PositionTag.SOCIAL,
                      },
                      () => {
                        this.setState({
                          curPreviewPosition: PositionTag.NONE,
                        });
                      }
                    );
                  }
                }}
              >
                <div className="social-detail">
                  {_.map(this.props.socialMediaList, (item, index) => {
                    return (
                      <SocialMediaItem
                        data={item}
                        key={index}
                        portfolioId={getModuleProp(MODULE_STUDENT_CANVAS, "id")}
                        className="item-social-media"
                        isBusiness={false}
                        onClickInstagram={() => {
                          doAction(AutoSaveAction, global_router.history);
                        }}
                        onDisconnectInstagram={() => {
                          doAction(DisconnectInstaAction);
                        }}
                        onInput={(value: string) => {
                          const newItem = {
                            ...(item as any),
                            value: value,
                          };
                          doAction(SocialMediaInputAction, newItem);
                        }}
                      />
                    );
                  })}
                </div>
              </CRExpansionPanel>

              <CanvasLink slug={this.props.slug}></CanvasLink>

              <div className="delete-canvas-link">
                <span
                  onClick={() => this.updateState({ showDeleteCanvasLink: true })}
                  style={ { textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold', margin: '10px' } }
                >
                  Delete This Portfolio
                </span>
              </div>

            </div>
            <div className="right-sec">
              <div className="center-sec">
                <CanvasPreview
                  type={this.props.type}
                  mode={PreviewMode.CREATE}
                  pageData={this.props}
                  isMobile={this.state.isMobile}
                  curPosition={this.state.curPreviewPosition}
                />
              </div>
            </div>
          </div>

          {this.state.showEditAward && (
            <AddAwardModal
              visible
              onClose={() => this.updateState({ showEditAward: false })}
              data={this.state.curEditAward}
              onAdd={(award: any) => {
                doAction(EditAwardAction, award);
                doFunction(async () =>
                  this.updateState({ showEditAward: false, curEditAward: {} })
                );
              }}
            />
          )}
          {this.state.showEditCate && (
            <EditCategoryModal
              visible
              onClose={() => this.updateState({ showEditCate: false })}
              data={this.state.curEditCate}
              onAdd={(category: any) => {
                if (this.state.curEditCateIdx === -1) {
                  doAction(UpdatePageInfoAction, {
                    categories: [...this.props.categories, category],
                  });
                } else {
                  const newCates = [...this.props.categories];
                  newCates[this.state.curEditCateIdx] = category;
                  doAction(UpdatePageInfoAction, {
                    categories: newCates,
                  });
                }
                doFunction(async () =>
                  this.updateState({
                    showEditCate: false,
                    curEditCate: {},
                    curEditCateIdx: -1,
                  })
                );
              }}
              categoryList={this.props.categoryList}
              stateIssueList={this.props.stateIssueList}
            />
          )}
          {this.state.showEditEmployment && (
            <EditEmploymentModal
              visible
              onClose={() => this.updateState({ showEditEmployment: false })}
              data={this.state.curEditEmployment}
              onAdd={(employment: any) => {
                doAction(EditEmploymentAction, employment);
                doFunction(async () =>
                  this.updateState({
                    showEditEmployment: false,
                    curEditEmployment: {},
                  })
                );
              }}
            />
          )}
          {this.state.showEditPhoto && (
            <EditPhotoModal
              visible
              showReposition={this.state.showEditReposition}
              data={this.state.curEditMedia}
              onClose={() => this.updateState({ showEditPhoto: false })}
              onApply={(mediaInfo: any) => {
                this.updateState({ showEditPhoto: false, showLoading: true });
                if (this.state.curEditMedia.type !== MediaType.PORTFOLIO_PHOTO) {
                  doAction(UploadFileAction, {
                    mediaInfo,
                    type: this.state.curEditMedia.type,
                  });
                } else {
                  doAction(EditPortfolioPhotoAction, {
                    mediaInfo,
                    index: this.state.curEditPortIndex,
                  });
                }
                doFunction(async () => {
                  this.updateState({ showLoading: false });
                });
              }}
            />
          )}

          <EditEducationModal
            visible={this.state.showEditEducation}
            schoolOptions={this.state.schoolOptions}
            programList={this.props.programOptions}
            onClose={() => this.updateState({ showEditEducation: false })}
            data={this.state.curEditEducation}
            isEdit={this.state.curEditEduIdx !== -1}
            onSearchSchool={(keywords: string) => this.searchSchoolByName(keywords)}
            onSave={(newEdu: any) => {
              const isAdd = this.state.curEditEduIdx === -1;
              const newList = [...this.props.educations];
              if (isAdd) {
                newList.push(newEdu);
              } else {
                newList[this.state.curEditEduIdx] = newEdu;
              }
              doAction(UpdatePageInfoAction, { educations: newList });
              this.updateState({
                showEditEducation: false,
                curEditEducation: {},
                curEditEduIdx: -1,
              });
            }}
          />

          <FieldRequireModal
            onClose={() => doAction(UpdatePageInfoAction, { emptyFields: [] })}
            visible={!_.isEmpty(this.props.emptyFields)}
            data={this.props.emptyFields}
          />
          {this.state.showDelConfirm && (
            <ConfirmModal
              visible
              title="Comfirmation"
              confirmText="Remove"
              description={this.getDelEduConfirmText()}
              onClose={() =>
                this.updateState({
                  showDelConfirm: false,
                  curConfirmAction: 0,
                  curDelEduSchool: [],
                })
              }
              onConfirm={() => {
                if (this.state.curConfirmAction === 0) {
                  doAction(SubmitToPreviewAction, this.props.history);
                } else if (this.state.curConfirmAction === 1) {
                  doAction(SubmitToPublishAction, this.props.history);
                } else {
                  doAction(SaveAsDraftAction, this.props.history);
                }
                this.updateState({
                  showDelConfirm: false,
                  curConfirmAction: 0,
                  curDelEduSchool: [],
                });
              }}
            />
          )}
          <LoadingModal visible={this.state.showLoading} />
        </Content>

        <Modal
          visible={getStartedOverlay}
          closable={true}
          onCancel={() => this.setState({ getStartedOverlay: false })}
          maskClosable={true}
          footer={null}
          centered={true}
        >
          <GetStartedOverlay
            getStartedOverlay={getStartedOverlay}
            type={"student"}
            close={() => this.setState({ getStartedOverlay: false })}
          ></GetStartedOverlay>
        </Modal>

        <DeleteCanvasModal
          visible={this.state.showDeleteCanvasLink}
          onClose={() => this.setState({ ...this.state, showDeleteCanvasLink: false })}
          onDelete={() => {
            this.setState({ ...this.state, showDeleteCanvasLink: false });
            console.log('this.props.id', this.props.id);
            doAction(DeleteCanvasAction, this.props.id);
            this.props.history.push('/my-portfolio');
          }}
        />
      </Layout>
    );
  }

  private onPhotoPost = (file: any) => {
    return new Promise<void>((resolve, reject) => {
      this.updateState({
        curLoadingMedia: 5,
        showLoading: true,
      });
      getMediaInfo(file).then((mediaInfo) => {
        doAction(UploadFileAction, {
          mediaInfo,
          type: MediaType.PORTFOLIO_PHOTO,
        });
        doFunction(async () => {
          this.updateState({
            curLoadingMedia: 0,
            showLoading: false,
          });
          resolve();
        });
      });
    });
  };

  private onVideoPost = (file: any) => {
    return new Promise<void>((resolve, reject) => {
      this.updateState({
        showLoading: true,
      });
      getMediaInfo(file).then((mediaInfo) => {
        doAction(UploadFileAction, {
          mediaInfo,
          type: MediaType.ADDITIONAL,
        });
        doFunction(async () => {
          this.updateState({
            showLoading: false,
          });
          resolve();
        });
      });
    });
  };
}
